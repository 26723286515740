@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;600&display=swap');
.store-dash{background: #f9fbfc; min-height: 1080px; font-family: 'Poppins', sans-serif; color: #000;}
.store-dash .usernamelilac {color: #62A82A;}
.store-dash .h1, .store-dash .h2, .store-dash .h3, .store-dash .h4, .store-dash .h5, .store-dash .h6, .store-dash h1, .store-dash h2, .store-dash h3, .store-dash h4, .store-dash h5, .store-dash h6{ color: #000; margin: 0px;}
.store-dash .small{ font-size: 11px;}
.store-dash .picpro{ border-color: #62a82a; overflow: visible; border-width: 5px;}
.store-dash .btn-primary { background-color: #62a82a; border-color: #62a82a !important; color: #fff !important; }
.store-dash .btn-primary:hover, .store-dash .btn-primary:focus, .store-dash .btn-primary:active, .store-dash .btn-primary:not(:disabled):not(.disabled).active, .store-dash .btn-primary:not(:disabled):not(.disabled):active, .store-dash  .show > .btn-primary.dropdown-toggle { background-color: transparent; box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 15px !important; color: #62a82a !important;}
.store-dash .form-control{ background: #fff; color: #000; border: 1px solid #ddd}
.store-dash .btn-outline-dark {color: #62a82a !important; border-color: #62a82a !important; background: transparent;}
.store-dash .btn-outline-dark:hover, .store-dash .btn-outline-dark:focus, .store-dash .btn-outline-dark:active, .store-dash .btn-outline-dark:not(:disabled):not(.disabled).active, .store-dash .btn-outline-dark:not(:disabled):not(.disabled):active, .store-dash .show > .btn-outline-dark.dropdown-toggle { color: #fff !important; background-color: #62a82a; border-color: #62a82a !important;}
.store-dash .category-btn{color: #686868; border-color: #b9b9b9;} 
.store-dash .dollorsec .input-group-text { border: 1px solid #ddd; color: #000; background: #eee !important;}
.store-dash .custable { border: none; }
.store-dash .custable th, .store-dash .custable td{background: #fff; border-bottom: 1px solid #ddd;    border-top: 1px solid #ddd; color: #62A82A;}
.store-dash .custable td{ color: #000;}
.store-dash .react-tabs__tab--selected{ border-color: #eee !important;}
.store-dash .detasingimg{background: #f2f2f2;}
.store-dash  .detasingimg::before{background: linear-gradient(0deg,rgb(215, 215, 215) 0%,rgba(255, 255, 255, 0) 100%);}
.store-dash .mtartmirror::before{ background: linear-gradient(to bottom, rgba(242, 242, 242, 0.82), #f2f2f2);}
.store-dash .mtplaybtn{border: 2px solid #62a82a; color: #62a82a;}
.store-dash .mtplaybtn:hover{ background: #62a82a; color: #fff;}
.store-dash .custagbadge .cushashtag{background: #62a82a; border-color: #62a82a !important;}
.store-dash .custagbadge .cushashtag:hover{ color:#62a82a !important; background: transparent;}
.store-dash .solarloader, .store-dash + .loader-hide, .store-dash + .loader{ filter: invert(1);}
.store-dash .ownerDec:hover {color: #62a82a;}
.store-dash .show-success{background: rgba(0, 0, 0, 0.4);}
.store-dash .success-pop {background-color: #f0f0f0; border: 2px solid #fff; }
.store-dash .checkmark__circle{stroke: #62A82A;}
.store-dash .success-pop .btn-primary{ padding: 0px 15px !important;}

.store-dash .badgeview:hover, .store-dash .purchasebox:hover {box-shadow: 0px 0px 10px rgb(157, 201, 122);}
.store-dash .btn-primary-filled { background-color: #62a82a; border-color: #62a82a !important; color: #fff !important; }
.store-dash .btn-primary-filled:hover, .store-dash .btn-primary-filled:focus, .store-dash .btn-primary-filled:active, .store-dash .btn-primary-filled:not(:disabled):not(.disabled).active, .store-dash .btn-primary-filled:not(:disabled):not(.disabled):active, .store-dash  .show > .btn-primary-filled.dropdown-toggle { background-color: transparent; box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 15px !important; color: #62a82a !important;}

.store-dash  .sizecls{ margin-top: 0px;}
.store-dash  img{ max-width: 100%;}
.store-dash  .ds-sidebar{ width: 250px; height: 100%; position: fixed; background: #fff; border-right: 1px solid #e6e8ec; z-index: 3; transition: all ease 0.75s;}
.store-dash  .ds-logo {padding: 20px 15px;}
.store-dash  .ds-logo img {max-width: 80%;}
.store-dash  .ds-menu ul li a{ display: inline-flex; padding: 0px 15px; width: 100%; justify-items: center; align-items: center; color: #999; position: relative; font-size: 15px; min-height: 48px;  font-family: 'Poppins', sans-serif;}
.store-dash .ds-menu ul li a i{ margin-right: 10px; color: #777; font-size: 18px; width: 25px;}
.store-dash .ds-menu ul li a:hover, .store-dash .ds-menu ul li.active a, .store-dash .ds-menu ul li a:hover i, .store-dash .ds-menu ul li.active a i{ color: #62a82a;}
.store-dash .ds-menu ul li a::before  {content: '';position: absolute;width: 0px;height: 100%;top: 0px;left: 0px;background: #62a82a;border-radius: 0px 10px 10px 0px; transition: all ease 0.75s;}
.store-dash .ds-menu ul li a:hover::before, .store-dash .ds-menu ul li.active a::before{ width: 5px;}

.store-dash .ds-sidebar h6 {color: #444;font-size: 13px;padding: 0px 15px;text-transform: uppercase;font-weight: 600;letter-spacing: 1px;margin-top: 30px;}
.store-dash .ds-content{ padding: 20px 15px 0px 280px; min-height: calc(100vh - 125px);}
.store-dash .ds-weltxt{ display: flex; flex-direction: column; color: #000;}
.store-dash .ds-weltxt h1 {color: #000;font-weight: 700;font-size: 22px;margin-bottom: 5px;}
.store-dash .store-dash .ds-weltxt h2 {color: #fff;font-weight: 700;font-size: 20px; margin-bottom: 15px;}
.store-dash .ds-weltxt p {font-size: 16px; color: #979797; margin-bottom: 0px;}
.store-dash .ds-coverimg{ width: 100%; height: 350px; overflow: hidden; position: relative; border-radius: 30px; margin-bottom: 50px; box-shadow: 0px 10px 30px rgba(19, 36, 5, 0.5);}
.store-dash .ds-coverimg img{ width: 100%; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);}
.store-dash .ds-coverimg::before { content: ''; position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; z-index: 1;  background: rgb(0,0,0);    background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1); } 
.store-dash .ds-userdetail{ position: absolute; bottom: 0px; z-index: 1; padding: 30px; width: 100%;}

.ds-proimg{ position: relative; display: flex;}

.store-dash .imgabsol{ position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); max-width: 100%;}
.store-dash .ds-userdeta{ text-align:right; display: flex; align-items: center; justify-content: flex-end; position: relative;}
.store-dash .ds-sectit{ margin-bottom: 30px;}
.store-dash .ds-sectit h3{ color: #456e22; font-weight: 600;  display: flex;}
.store-dash .ds-sectit h3 .btn{ margin-left: 15px;}
.store-dash .ds-sectit a{color: #9D9D9D; font-size: 13px;}
.store-dash .ds-sectit a:hover{color: #62a82a;}
.store-dash .ds-sectit a i {margin-left: 5px;}

.store-dash .ds-proimg:hover .dropdown-menu{ display: block !important; left: auto; right: 0px; top: 48px; padding: 0px;} 
.store-dash .ds-proimg .dropdown-toggle, .store-dash .ds-plimg{width: 48px; height: 48px; overflow: hidden; position: relative; border-radius:15px; display: inline-block; background: #e6e8ec url('assets/images/user.png') no-repeat; background-position: center;  background-size: 65%; cursor: pointer;}
.store-dash .ds-proimg .dropdown-toggle::before, .store-dash .ds-plimg::before{ border: 3px solid #62a82a; content: ''; position: absolute; left: 0px; top: 0px; border-radius: 15px; width: 100%; height: 100%; opacity: 0; transition: all ease 0.75s;}
.store-dash .ds-proimg .dropdown-toggle:hover::before, .store-dash .ds-plimg:hover::before{opacity: 1;}
.store-dash .ds-proimg:hover .dropdown-menu hr{ border-color: #eee; margin: 0px;} 
.store-dash .dropdown-menu h5 {padding: 10px 15px;}
.store-dash .dropdown-menu .dropdown-item{ font-size: 13px;}

.store-dash .ds-list{display: block; position: relative; height: 450px; width: 100%; border-radius: 15px;   overflow: hidden; cursor: pointer; margin-bottom: 30px; background: #E0FACA;}
.store-dash .ds-list::before{ content: ''; position: absolute; background: #62a82a; border-radius: 50%; width: 100%; height: 100%; top: -100%; right: -100%; transition: all ease 1s; z-index: 1; opacity: 0;}
.store-dash .ds-list.hidden-arts::before {top: 0px;opacity: 0.8; left: 0; border-radius: 0px; z-index: 2; background: #E0FACA;}
.store-dash .ds-list img{position: relative;  left: 50%;  top: 50%;  -webkit-transform: translate(-50%,-50%);  transform: translate(-50%,-50%); object-fit: cover;  margin: 0 auto; height: 550px; width: 100%;}
.store-dash .ds-list-details{ position: absolute; border-radius: 15px; padding: 15px; background: rgba(98, 168, 42, 0.9); bottom: 15px; left: 50%; transform: translateX(-50%); width: 90%; transition: all ease 0.75s; min-height: 0px; z-index: 1; color: #120b2b;}
.store-dash .ds-list-details h4 {font-size: 18px; margin-bottom: 0px; font-weight: 600; text-align: center; white-space: nowrap; max-width: 100%; text-overflow: ellipsis; overflow: hidden;}
.store-dash .ds-list-details p {font-size: 13px; line-height: 20px; color: #fff; text-align: center; margin-bottom: 0px; margin-top: 10px; letter-spacing: 1px;}
.store-dash .ds-list-details .btn{ font-size: 14px !important; text-transform: none; position: absolute; bottom: -100px; opacity: 0; transition: all ease 0.75s; background: transparent; border-color: #fff; }
.store-dash .ds-list:hover::before{width: 150%; height: 150%; opacity: 0.95; top: -25%; right: -25%;}
.store-dash .ds-list:hover .ds-list-details{ min-height: 90%; background: transparent; }
.store-dash .ds-list:hover .ds-list-details h4{ color: #fff; white-space: normal;}
.store-dash .ds-list:hover .ds-list-details .btn{bottom:30px; transition: all ease 0.75s; opacity: 1; margin-top: 30px;}

.store-dash .ds-mystatsbg{height: 350px; width: 100%; position: relative; border-radius: 30px; background: #c1f497; margin-bottom: 50px; }
.store-dash .ds-msimg{ height: 350px; position: absolute; right: -30px; top: -15px;}
.store-dash .ds-mystats {padding:30px; min-height: 350px;}
.store-dash .ds-mystats h3 {color: #463f23;font-weight: 600;margin-bottom: 30px;}
.store-dash .ds-mystats p{ margin-bottom: 0px; font-weight: 600;  color: #3e431a; }
.store-dash .ds-mystats p:not(:last-child){ font-weight: normal; margin-bottom: 5px;}
.store-dash .gototxt{ position: absolute; bottom: 50px; left: 50px; }
.store-dash .gototxt a {color: #3e431a;font-size: 12px;display: flex;align-items: center;justify-content: center;}
.store-dash .gototxt a i{ margin-left: 10px;}
.store-dash .gototxt a:hover{color: #62a82a;}
.store-dash .ds-aclist{display: flex; flex-direction: row; margin-bottom: 30px;}
.store-dash .ds-acimg{width: 40px;}
.store-dash .ds-acimg .ds-proimg{ width: 40px; height: 40px; border-radius: 10px;}
.store-dash .ds-actext{ flex: 1; flex-direction: column; margin: 0px 15px;}
.store-dash .ds-actext h5{ color: #463f23; font-size: 15px;  margin-bottom: 5px;}
.store-dash .ds-actext p{ color: #888; font-size: 12px;  margin-bottom: 0px;}
.store-dash .ds-achours{ width: 100px; color: #888; font-size: 11px; text-align: right; letter-spacing: 1px ; display: flex; justify-content: flex-end; align-items: center;}

.store-dash .ds-inputs{ position: relative;}
.store-dash .ds-inputs .form-control{  font-weight: 500; box-shadow: 0px 2px 1px rgba(0,0,0,0.1); transition: all ease 0.75s; padding: 1.9em .6em .7em; font-size: .9em; font-weight: 600; height: auto; background: #fff;  border: 1px solid #eee; color: #000;}
.store-dash .ds-inputs .input-floating-label{font-size: 11px; position: absolute; left: 1px;z-index: 1;opacity: 1;-webkit-transition: .2s ease-out;transition: .2s ease-out; color: #bababb; background: transparent; pointer-events: none; max-width: 100%; letter-spacing: 1px; top: 1px; -webkit-transform: translateX(0); transform: translateX(0);font-size: .7em; padding-left: 0.9em; padding-top: 0.9em; color: #888; background: #fff; width: 98%; border-radius: 10px 10px 0px 0px;}
/* .ds-inputs .form-control:focus + .input-floating-label, .ds-inputs .form-control:valid + .input-floating-label{top: 0; -webkit-transform: translateX(0); transform: translateX(0);font-size: .7em; padding-left: 0.9em; padding-top: 0.9em; color: #888;}    */

.store-dash .error-box{border-top: none;    border-right: 1px solid #d0011b;    border-bottom: 1px solid #d0011b;    border-left: 1px solid #d0011b;    border: 1px solid #d0011b;    border-radius: 0 0 5px 5px;    font-size: .9rem;    background: #fceff1;    color: #d0011b;    padding: 10px; display: -webkit-box; display: -ms-flexbox; display: flex;  -webkit-box-align: center;  -ms-flex-align: center;  align-items: center;}
.store-dash .ds-inputs .form-control:focus {border-color: #62a82a;}
.store-dash .ds-inputs .css-b16iv1-control {background: #fff; border: 1px solid #eee; padding: 10px 0px; box-shadow: 0px 2px 1px rgba(0,0,0,0.1); }
.store-dash .ds-inputs .css-hlu0h4-singleValue{color: #000;  font-weight: 600; font-size: .9em; margin-top: 15px;}
.store-dash .lightline{ border-color: #eee; margin: 30px 0px;}

.ft {width: 100%;background: #fff;padding: 0px 30px 41px 280px;position: relative;margin-top: 100px; border-top: 1px solid #e2eed8;}
.ft .ft_blk {width: 100%;display: flex;align-items: center;justify-content: space-between;}
.ft .ft_blk .icon ul {padding: 0;margin: 0;}
.ft .ft_blk .icon ul li {list-style: none;display: inline-block;}
.cnt p {margin: 0;font-size: 12px;line-height: 24px; color: #000;   }
.cnt p a {text-decoration: none;color: #62a82a;transition: 0.5s all;}
.cnt p a:hover {color: #000;}
.ft .ft_blk .icon ul li:not(:first-child) {margin-left: 25px;}
.ft .ft_blk .icon ul li a i {font-size: 35px;color: #bfbfbf;transition: 0.5s all;}
.ft .ft_blk .icon ul li:hover a i {color: #62a82a;transform: rotateY(360deg);}
.flow {background:rgba(255, 255, 255, 0.83); border:1px solid #e2eed8; border-radius: 50px; padding: 13px 50px; display: flex; justify-content: center; position: relative; top: -38px;}
.flow p {text-transform: uppercase;margin: 0;}
.flow p span {padding-left: 10px;text-transform: lowercase;}
.flow .blk .txt h4 {    color: #000;margin: 0px 30px 0px 0px;}
.flow .blk {display: flex;align-items: center;justify-content: flex-start;}
.flow .blk .logos ul {margin: 0;display: flex;align-items: center;}
.flow .blk .logos ul li {list-style: none;display: inline-block;margin-bottom: 0px;}
.flow .blk .logos ul li:not(:last-child) {margin-right: 10px;}
.flow .blk .logos ul li a {display: flex;align-items: center;justify-content: center;   color: #888;text-decoration: none;background: rgb(240, 240, 240);border-radius: 30px;padding: 5px 35px;min-height: 45px;}
.flow .blk .logos ul li a .txt {  padding-left: 5px;    line-height: normal;  position: relative;    white-space: nowrap;  color: #000; display: flex; flex-direction: column;}
.flow .blk .logos ul li a img { transition: 0.5s all;  width: 25px; filter: none !important; top: 0px;}
.flow .blk .logos ul li a:hover img {    transform: rotateY(360deg);}
.flow .blk .logos ul li a .txt .small {font-size: 9px; letter-spacing: 1px; }

.store-dash .ds-tpro{margin-bottom: 30px;}

.store-dash .ds-mobloto{ align-items: center; display: none; margin-bottom: 30px;}
.store-dash .ds-toggle{ margin-left: 15px; width: 48px; height: 48px; border-radius: 6px; border: 1px solid #ccdbc0; flex-direction: column; justify-content: center; padding: 7px;  cursor: pointer; display: none;}
.store-dash .ds-toggle span{ border-radius: 30px; height: 3px; width: 30px; background: #64ac2b; transition: all linear 0.4s;}
.store-dash .ds-toggle span:not(:last-child){ margin-bottom: 5px;}
.store-dash .ds-toggle span:nth-child(2){ width: 20px;}
.store-dash .ds-mobloto .ds-logo{ padding: 0px;}
.store-dash .ds-toggle:hover, .ds-toggle.open{background: #dceccf;}
.store-dash .ds-toggle:hover span, .ds-toggle.open span{ width: 20px;}
.store-dash .ds-toggle:hover span:nth-child(2), .ds-toggle.open span:nth-child(2){ width: 30px;}
.store-dash .mobtxt{ display: none; margin-bottom: 15px;}
.store-dash .dropdown-toggle::after{ display: none;}

.store-dash .mscname{ padding:15px; background: #eaffd8;}
.store-dash .ds-intit{ margin-bottom: 30px;}

.store-dash .ds-content .propagecont{ background: #fff; padding: 30px; border-radius: 30px; box-shadow: 0 2px 1px rgba(0,0,0,0.1);}
.store-dash .purchasebox { background: #fff;}
.store-dash .purchasebox .card{ border-color: #afd594;}

.store-dash .no-suggestions, .store-dash .suggestions{ background: #fff; border-radius: 10px; border-color:#dedede; color: #333; box-shadow: 0px 3px 2px rgba(0,0,0,0.1);}
.store-dash .suggestions li{ color: #333; background: #fff; transition: all ease 0.75s;}
.store-dash .suggestions li:hover{ background: #f7f7f9; color: #62a82a;}
.store-dash .suggestion-active, .store-dash .suggestion-active a, .store-dash .suggestions a:hover {color: #456E22 !important; background: #f9fbfc;}
.store-dash .suggestions li:not(:last-of-type) { border-color:#e8e8e8; }
.store-dash .articonstatic {min-height: 100% !important;}
.store-dash .detasingimg {border-radius: 30px; overflow: hidden;}
.store-dash .badgeview{ background: #fff; top: 0px; transition: all ease 0.75s;}

.store-dash .btn-icon {padding: 5px 10px;height: auto !important;line-height: normal !important; background: transparent; color: #62a82a !important}
.store-dash .input-group{box-shadow: 0px 2px 1px rgba(0,0,0,0.1);border-radius: 10px; display: inline-flex; width: auto;}
.store-dash .input-group.ds-inputs .form-control{ padding-top: 1.3em; padding-bottom: 1.3em; margin-bottom: 0px !important;}
.store-dash .input-group .input-group-append:not(:last-child) .btn{ border-radius: 0px !important;}
.store-dash .input-group .input-group-append:last-child .btn{ border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important; }
.store-dash .input-group .input-group-append .btn i, .store-dash .input-group .input-group-append .btn:hover .facustom{color: #62A82A !important;}
.store-dash .input-group .input-group-append .btn .facustom{ color: #fff !important;}

.store-dash .input-group .input-group-append .btn {padding: 0rem 10px !important; line-height: normal !important;height: 100%  !important; border-width: 1px !important; }
.store-dash .betaLink { color: #62A82A;}
.store-dash .table {color: #000; white-space: nowrap;}
.store-dash .table thead th {border-bottom: 1px solid #e2eed8; }
.store-dash .table-striped-cusom thead {background-color: #fff;color: #777;}
.store-dash .table-bordered td, .store-dash .table-bordered th { border: 1px solid #e2eed8;}
.store-dash .table tbody tr:hover{ background: #f4ffeb;}
.store-dash .btn-icon { width: 40px; height: 40px !important; line-height: normal !important; font-size: 12px !important; padding: 0px; display: inline-flex; align-items: center;justify-content: center;}
.store-dash .editpro{overflow: hidden; border-radius: 50%; bottom: -25px;}
.store-dash .choosefile .btn-primary { background:  #fff; border: none !important; color: #62a82a !important;}
.store-dash .choosefile .btn:hover{ background:#62a82a; color: #fff !important;}
.store-dash .editpro.close {left: auto; right: -6px; bottom: auto; top: -15px; margin: 0px;opacity: 1; background: transparent;}
.store-dash .editpro.close .btn{ padding: 0px;  width: 28px;  height: 28px !important; line-height: normal !important; border-radius: 50% !important; font-size: 12px;}
.store-dash .editpro.close .btn:hover{ background: #fff;}

.store-dash .draggable-item small { color: #62a82a;}

.store-dash .success-pop{word-break: break-word; max-width:40rem}
.store-dash .move-progress{width: 100%; background-color: #c1f497; border: 1px solid #456e22;}
.store-dash .move-progress .progress-bar{background-color: #62a82a;}

.dssscroll {height: calc(100vh - 100px);overflow: hidden;overflow-y: scroll;}

.store-dash .pagenotfound .card {background: #fff;}
.store-dash .path.circle, .store-dash .path.line{ stroke: #f03232;}
.store-dash .pagenotfound .btn-fill{ background-color: #777; border-color: #777 !important;}
.store-dash .pagenotfound .btn-fill:hover{color: #777 !important; background: transparent;}

.store-dash .dynamicsec .ds-inputs .css-hlu0h4-singleValue{ margin-top: 0px;}
.store-dash .dynamicsec .coverimg {height: auto;}

.dynamicsec .picpro, .dynamicsec .no-proimg, .dynamicsec .no-proimg img { border-radius: 0px;}
.dynamicsec .no-proimg img{background: #f9fbfc;}

.store-dash input[type="checkbox"] + ::before, .store-dash input[type="radio"] + ::before{border-color: #ccc;}
.store-dash input[type="checkbox"]:checked + ::before, .store-dash input[type="radio"]:checked + ::before {   background: #62a82a; border-color: #62a82a; color: #fff; }

.store-dash .react-toggle-thumb {border: 1px solid #62A82A !important;background-color: #62A82A !important;}
.store-dash .react-toggle-track, .store-dash .react-toggle--checked .react-toggle-track {background-color: rgb(98, 98, 98) !important;}
.store-dash .ds-inputs .flowtop{ background: transparent; border: none;}
.store-dash .flowtop .copycon{max-width: 160px; min-width: 160px; width: auto; white-space: normal; z-index: 10 !important;}
.store-dash .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .store-dash .input-group > .custom-select:focus, .store-dash .input-group > .form-control:focus {    z-index: 1; }

.fastarbanner {box-shadow: 0px 2px 5px rgba(0,0,0,0.5); position: absolute;top: 0px;z-index: 9;left: 0px; width: 30px; height: 30px;background: rgb(98, 168, 42);    display: flex;    align-items: center;    justify-content: center;    border-radius: 0px 0% 50% 0px; transition: all ease 0.75s;}
.fastarbanner:hover{ background: rgba(0, 0, 0, 0.8);}
.fastarbanner .toottip {position: absolute; padding: 10px;top: -200px;font-size: 11px;color: #fff;border-radius: 0px 6px 6px;background: rgba(0, 0, 0, 1);min-width: 190px;text-align: center;box-shadow: 0px 2px 5px rgba(0,0,0,0.8);left: 40px;transform: translateY(-50%);transition: all ease 0.75s;}
.fastarbanner .toottip::before {content: '';position: absolute;left: -20px; top: 0px;border: 10px solid transparent;   border-right: 10px solid rgba(0, 0, 0, 1);}
.fastarbanner:hover .toottip{ top: 100%;}
.fastarbanner .toottip .fa{transition: all ease 0.75s;}

.store-dash .rdtOpen .rdtPicker { background: #fff; border-color: #e2eed8; font-size: 13px; border-radius: 10px; }
.store-dash td{transition: all ease 0.75s;  border-radius: 10px;}
.store-dash td.rdtMonth:hover, .store-dash td.rdtYear:hover, .rdtPicker thead tr:first-of-type th:hover {background: #eaeaea !important; color: #000 !important;}
.store-dash .rdtPicker td.rdtActive, .store-dash .rdtPicker td.rdtActive:hover {background-color: #62a82a;color: #fff; text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);}

.store-dash .ds-inputs textarea { min-height: 165px; }

@media(max-width:1600px){
    .store-dash .ds-msimg {top: auto;bottom: -15px;width: 200px;   height: auto; right: 0px;}
    .store-dash .ds-mystatsbg .col-md-8 {flex: 0 0 100%; max-width: 100%;}
}

@media(max-width:1440px){
    .store-dash .ds-list{height: 300px;}
    .store-dash .ds-msimg{width: 175px;}
}

@media(max-width:1366px){
    .store-dash .ds-infiniscroll .col-lg-3{flex: 0 0 33.333%; max-width: 33.333%;}
    .flow .blk .txt h4{font-size: 15px;}
}

@media(max-width:1199px){
    .store-dash .ds-sidebar {left: -250px;}
    .store-dash .ds-mobside{ left: 0px; box-shadow: 5px 0px 10px rgba(0,0,0,0.3);}
    .store-dash .ds-content {padding:0px}
.ft{ padding-left: 30px;}
.store-dash .ds-mobloto{ display: flex; margin-bottom: 0px;}
.store-dash .ds-sidebar .ds-logo{ text-align: center;}
.store-dash .ds-actext h5{font-size: 14px;}   
.store-dash .ds-toggle{ display: flex;}
.store-dash .ds-tpro { padding: 15px 0px; background: #fff; width: 100%; box-shadow: 0px 0px 5px rgba(0,0,0,0.1); }
.store-dash .mobtxt{ display: block; text-align: center;}
.store-dash .deskbtxt, .ds-tpro .sdwallet{ display: none} 
.store-dash .sdwallet li { margin: 15px 0px 0px 0px;}
}
@media(max-width:1024px){
    .store-dash .ds-infiniscroll .col-md-3{ flex: 33.333%; max-width: 33.333%;}
}
@media(max-width:991px){
    .store-dash .ds-mylist .col-md-8,.ds-mylist .col-md-4{flex: 0 0 100%;  max-width: 100%;}
.flow .blk {flex-direction: column;}
.flow .blk > .txt {margin-bottom: 15px;}
.flow .blk .logos ul {flex-wrap: wrap; justify-content: center; }
.flow .blk .logos ul li:not(:last-child) {margin-bottom: 10px;}
.flow .blk .txt h4{ margin-right: 0px;}
.store-dash .ds-intit h1 { font-size: 30px;}
.store-dash .gototxt {position: static; margin-top: 15px;}
.store-dash .ds-msimg { width: 150px;}
.store-dash .ds-content .propagecont{padding: 10px;}
.store-dash .dynamicsec .picpro {width: 125px;height: 125px;}
}


@media(max-width:767px){
    .store-dash .dmwsec { text-align: center;}
    .store-dash .dmwsec .purchasebox { justify-content: center;    align-items: center;    display: flex;}
    .store-dash .ds-toggle {margin-left: 5px;}  
    .store-dash .ds-infiniscroll .col-lg-3 {flex: 0 0 50%; max-width: 50%;}
    .store-dash .ds-intit .form-group, .store-dash .ds-intit h3 {text-align: center !important; margin-bottom: 15px !important;}
    
}

@media(max-width:575px){
    .store-dash .ds-coverimg{height: 250px;}  
    .store-dash .ds-logo img { max-width: 90%;}
    .store-dash .ds-sidebar .ds-logo img {max-width: 75%;}
.store-dash .form-group, .store-dash .form-group .auto-suggest, .store-dash .form-group .auto-suggest .form-control{ width: 100% !important;}
}
@media(max-width:530px){
.flow .blk .logos ul{flex-direction: column;} 
.flow .blk .logos ul li:not(:last-child){ margin-right: 0px;}
.ft .ft_blk .icon ul li a i{font-size: 20px;}
.ft_blk{ flex-direction: column;}
.ft_blk .cnt {order: 2; margin-top: 15px;}
.store-dash .ds-listing .row {flex-wrap: nowrap; overflow-x: auto;flex-direction: row;}
.store-dash .ds-listing .col-sm-4 {max-width: 90%; flex: 0 0 90%;}
.store-dash .ds-infiniscroll .row {flex-wrap: wrap; flex-direction: column;}
.store-dash .ds-infiniscroll .col-lg-3{max-width: 100%; flex: 0 0 100%;}
.store-dash .ds-sectit h3{font-size: 20px;}
.store-dash .success-pop{ padding: 30px 15px;}

}
@media(max-width:414px){
    .store-dash .ds-coverimg {height: 175px;}
}
