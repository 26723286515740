.da-landing{ font-family: 'Poppins' , Verdana, sans-serif; background-color: #000;}
.da-banner, .da-concret, .da-activate{ width: 100%; min-height: 100vh; display: flex; position: relative; }
.da-concret .container, .da-banner .container, .da-activate .container{z-index: 4;}
.da-contact .container{z-index: 5;}
.da-bannerimg, .da-concretbg, .da-abg{ position: absolute; left: 0px; top: 0px; width: 100%; height: 100%; overflow: hidden;}
.da-bannerimg::before,  .da-concretbg::before, .da-abg::before{ content: ''; width: 100%; height: 100%; position: absolute; left: 0px; bottom: 0px; background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0,0.5) 75%, rgb(0, 0, 0) 100%); z-index: 1; animation: fadeDown 1s ease-in both;}   
.da-bannerimg img, .da-concretbg img{  width: 100%;}
.da-navbar{position: fixed; top: 0px; left: 0px; width: 100%; padding:0px; z-index: 5; transition: all ease 0.75s;}
.da-navbar .navbar-brand {display: none;}
.da-navbar .navbar-expand-lg .navbar-collapse {justify-content: flex-end;}
.da-navbar .disnowmenu{background:transparent; border: none;}
.da-navbar ul {display: flex;align-items: center;justify-content: flex-end; margin: 0px; padding: 0px; box-shadow: none;}
.da-navbar .desknone.navbar-nav.menulink {display: none;}
.da-navbar .disnowmenu .navbar-nav .nav-link {padding: 10px 30px !important; font-family: 'Poppins' , Verdana, sans-serif;}
.da-navbar .navbar .container{ justify-content: flex-end;}
.da-navbar .navbar-light .navbar-toggler {top: 10px;right: 10px;}
.da-bannercon{ position: absolute; left: 0px; top: 0px; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;}
.da-bannercon img{ max-width: 85%; margin: 0px auto; display: block; animation: fadeIn 1s ease-in both;}
.da-bannercon h1{ font-size: 24px; text-align: center; animation: fadeIn 1s ease-in both;  margin-top: 20px; max-width: 65rem; margin: 0px auto;}
.dal-venusrising{font-family: 'Venus Rising Rg'; font-size: 48px;}
.da-navbar ul li a { color: #fff; font-size: 25px;}
.btn-blue{background: #21CFF1; border-color: #21CFF1 !important;text-transform: none;font-weight: normal;border-radius: 50px !important;padding: 0px 35px;height: auto !important; color: #000 !important; font-weight: bold;}
.btn-blue:hover{ background: #000; border-color: #000 !important; color: #fff !important; }
.da-banner-btns{ padding: 100px 0px 0px; display: flex; align-items: center; justify-content: center;}
.da-banner-btns .btn{ height: 74px !important; border-radius: 50px !important; border-width: 5px !important; font-size: 25px; display: flex; align-items: center; justify-content: center; padding-left: 30px; padding-right: 30px; text-transform: none;}
.da-banner-btns .btn:first-child{ margin-right: 30px;}
.btn-outline-voilet{ border-color: #9144B5 !important; animation: fadeDown 1s ease-in both; }
.btn-outline-voilet:hover{ background: #9144B5;}
.btn-outline-blue{ border-color: #21CFF1 !important; animation: fadeDown 1s ease-in both; } 
.btn-outline-blue:hover{ background: #21CFF1;}

.da-content{ padding: 100px 0px;}
.da-content h2{ font-size: 94px; font-weight: 900; line-height: 100px;}
.da-content h3{ font-size: 50px; font-weight: bold; line-height: 70px;}
.da-content p{ font-size: 34px; font-weight: bold; line-height: 65px;}

.fadeIn{animation: fadeIn 1s ease-in both; animation-delay: 1s;}
.fadeDown{animation: fadeDown 1s ease-in both; animation-delay: 1s;}
.fadeLeft{animation: fadeLeft 1s ease-in both; animation-delay: 1s;}
.fadeRight{animation: fadeRight 1s ease-in both; animation-delay: 1s;}

@keyframes fadeIn {
from {opacity: 0;transform: translate3d(0, -10%, 0);}
to {opacity: 1;transform: translate3d(0, 0, 0);}
}
@keyframes fadeDown {
from {opacity: 0;transform: translate3d(0, 10%, 0);}
to {opacity: 1;transform: translate3d(0, 0, 0);}
}
@keyframes fadeLeft {
from {opacity: 0;transform: translate3d(-10%, 0, 0);}
to {opacity: 1;transform: translate3d(0, 0, 0);}
}
@keyframes fadeRight {
from {opacity: 0;transform: translate3d(10%, 0, 0);}
to {opacity: 1;transform: translate3d(0, 0, 0);}
}

.da-partners{ margin-top: 75px;}
.da-partners-list{ display: flex; flex-wrap: wrap;}
.da-partners-list span{ margin-left: 70px; width: 200px; display: flex; align-items: center; margin-bottom: 15px;}
.da-partners-list span img {max-width: 100%;max-height: 100%;}
.da-concretbg::before{background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);}
.da-navbar.da-fixed{background: rgba(0,0,0,0.8);backdrop-filter: blur(10px);box-shadow: 0px 0px 15px rgb(5, 34, 36);}
.da-concret h3{ margin-top: 100px;}
.da-concret h4{ margin-bottom: 35px; line-height: 35px;}
.da-concret h5{font-size: 28px; font-weight: 300;}
.da-concret h5 span {font-weight: 900;}
.da-concret p{ font-size: 16px;}
.da-dsascw{ padding-left: 150px; text-align: right;}
.da-dsascw img{ max-width: 100%;}
.da-dsascw img:first-child{ max-width: 80%;  position: relative;}
.da-concret{ padding: 100px 0px;}
.da-concret .da-banner-btns, .da-activate .da-banner-btns {padding: 30px 0px 0px;justify-content: flex-start;}
.da-concret .da-banner-btns .btn, .da-activate .da-banner-btns .btn{ margin-right: 0px; font-size: 20px; height: 60px !important;}
.da-concretbg{ max-height: 1080px;}
.da-concret::after{ content: ''; position: absolute; left: 0px; height: 100%; bottom: 0px; width: 100%; background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%, rgba(0,0,0,1) 100%);}
.da-artlisting{ position: relative; height: 100%; display: flex; flex-direction: column; justify-content: center;}
/* .da-artlisting ul{ display: flex; flex-direction: row; flex-wrap: nowrap; width: 2650px; position: relative; z-index: 1;} */
/* .da-artlisting ul li{ width: 250px; margin: 0px 15px 15px 0px; display: flex;} */
.da-artlisting ul li{list-style: none;}
.da-artlisting .alice-carousel__prev-btn, .da-artlisting .alice-carousel__next-btn{position: absolute; z-index: 9; top: 50%; width: auto; transform: translateY(-50%); color: #9144B5;}
.da-artlisting .alice-carousel__prev-btn-item, .da-artlisting .alice-carousel__next-btn-item{color: #9144B5;}
.da-artlisting .alice-carousel__prev-btn-item:hover, .da-artlisting .alice-carousel__next-btn-item:hover{color: #fff !important; }
.da-artlisting .alice-carousel__next-btn {right: 20px;}
.da-artlisting .alice-carousel__prev-btn {left: -20px;}
.da-artlisting .alice-carousel__prev-btn-item, .da-artlisting .alice-carousel__next-btn-item{ font-size: 40px;}
.daalist{ position: relative; width: 250px; height: 300px; border-radius: 50px; border:5px solid #9144B5; overflow: hidden; display: flex;  cursor: pointer; transition: all ease 0.75s;}
.daalist:hover {box-shadow: 0px 10px 10px rgb(153, 103, 172);border-color: #000;}
.daalist .bg{ position: absolute; left: 0%; height: 100%; width: 100%; top: 0%; z-index: 1; }
.daalist .bg img{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 300px;}
.da-listcont{ position: relative; z-index: 2; display: flex; flex-direction: column; width: 100%;}
.da-lccover{ position: relative; height: 150px; width: 100%; display: flex;}
.da-lccover img, .da-lcprof img{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 160px;}
.da-lcproandcont{ display: flex; align-items: center; flex-direction: column; width: 100%; position: relative; top: -20px;}
.da-lcprof{ width: 120px; height: 120px; display: flex; overflow: hidden; position: relative; border-radius: 50%; border: 3px solid #9144B5;}
.da-lcprof img{ height: 120px;}
.da-lcproandcont p {font-weight: bold;font-size: 20px;text-align: center;margin: 0px; padding: 0px 10px; white-space: nowrap; text-overflow: ellipsis; width: 100%; overflow: hidden;}
.da-lcproandcont .usernamelilac{ font-size: 12px; font-weight: normal; margin: 10px 0px;}
.da-createrht{  padding-left: 40px;}
.da-artlisting::before, .da-artlisting::after {content: '';position: absolute;height: 100%;width: 100px;top: 0px;z-index: 2;}
.da-artlisting::before{left: 0px; background: linear-gradient(to right, rgba(0,0,0,0.64) 0%,  rgba(0,0,0,0) 100%);}
.da-artlisting::after{right: 0px; background: linear-gradient(to left,  rgba(0,0,0,1) 0%,  rgba(0,0,0,0.8) 25%,  rgba(0,0,0,0) 100%);}

@keyframes move-right-to-left { from {transform: translateX(0);} to {transform: translateX(-50%);}}

.da-activate{ position: relative; padding: 100px 0px; min-height: auto;}
.da-activate .da-abg {max-width: 100%;display: flex;justify-content: flex-end;}
.da-mid{ max-width: 100%; margin: 50px 0px 30px;}
.da-mid img{ max-width: 100%;}
.da-activate .da-dsascw{ display: flex;align-items: flex-end;justify-content: center;flex-direction: column;height: 100%; font-weight: bold;}
.da-activate .da-dsascw img{ max-width: 75%;  margin-bottom: 15px;  transition: all ease 0.75s; -webkit-animation: pulse 2s infinite;-moz-animation: pulse 2s infinite;-o-animation: pulse 2s infinite; animation: pulse 2s infinite; border-radius: 50%; }
.da-wvplay{display: flex; align-items: center; justify-content: center; flex-direction: column; cursor: pointer; text-align: center;}

@keyframes pulse {
0%, 25%, 100% {transform: scale(0.88, 0.88); -moz-box-shadow: 1px 0px 19px 4px #fff; box-shadow: 1px 0px 19px 4px #fff;}
50% {transform: scale(1.1, 1.1); -moz-box-shadow: 0px 0px 0px 0px rgba(255, 245, 3, 0);box-shadow: 0px 0px 0px 0px rgba(255, 245, 3, 0);}
}

.overlay {position: fixed;top: 0;bottom: 0;left: 0;right: 0;background: rgba(0, 0, 0, 0.7);transition: opacity 500ms; visibility: hidden;opacity: 0; display: none; align-items: center; justify-content: center;}
.overlay.true {visibility: visible;opacity: 1; z-index: 99999; display: flex;}
.popup {padding: 30px; width: 100%; height: 100%; position: relative; transition: all 5s ease-in-out; background: rgba(0,0,0,0.5); backdrop-filter: blur(10px);}
.popup .close {position: absolute;top: 10px;right: 10px;transition: all 200ms;font-size: 30px;font-weight: bold;text-decoration: none;color: #fff;cursor: pointer;width: 50px;height: 50px;display: flex;align-items: center;justify-content: center;
border: 1px solid #fff;border-radius: 50%;}
.popup .content {height: 100%;overflow: hidden;overflow-y: auto;display: flex;align-items: center;justify-content: center;}
.popup .content iframe {width: 65rem;min-height: 75%;}

.da-contact h3{ font-size: 100px; font-weight: 900; margin: 0px;}
.da-contact h4{ font-size: 50px;}
.da-contact p {font-size: 25px; margin-bottom: 0px;}
.da-contact::after{ display: none;}
.da-contact .da-concretbg::before{background: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.64) 100%);}
.da-activate p { font-size: 17px;}
.da-cform {border: 5px solid #9144B5;padding: 20px; border-radius: 50px;background: #000; margin-left: 150px; min-height: 500px;}
.da-cform .form-control{border-color: #9144B5; border-radius: 50px; padding: 10px 15px; height: auto;}
.da-cform textarea.form-control{border-radius: 30px; min-height: 200px;}
.da-cform .btn-blue{width: 100%; font-size: 28px;}
.da-cform .btn-blue:hover{border-color: #21CFF1 !important;}
.da-mobshow{ display: none}
.da-connectrow h3{ margin-top: 0px;}
.da-cform .my-form {height: 680px;}

.da-imgani .da-dsascw img:last-child{ position: relative; right: 75px; animation: linear rlloop 3s;}

@keyframes lrloop {
0%, 100%{ left: 0px; }
50% {left: -50px;}
}
@keyframes rlloop {
0% {right:0 ; opacity: 0;}
100%{right: 75px; opacity: 1;}
}

@keyframes rlloopmob {
0% {right:-75px ; opacity: 0;}
100%{right: 0px; opacity: 1;}
}

.da-navbar + .alertcommon {position: fixed;width: 100%;z-index: 5;}
.da-connectrow .da-banner-btns .btn {margin: 0px !important;  min-width: 205px;}

@media screen and (max-width:1580px){
.da-content h2, .da-contact h3 {font-size: 85px;}
.da-content h3 {font-size: 58px;}
.da-content p {font-size: 30px;line-height: 50px;}
.da-partners-list span{width: 160px;}
.da-concret h4 {font-size: 30px;}
.da-createrht {padding-left: 0;}
.da-activate .da-dsascw{align-items: center;}
.da-cform{ margin-left: 100px;}
.daalist{ width: 220px;}
.da-lcproandcont p{ font-size: 15px}
.da-lcprof{ width: 100px; height: 100px;}
.da-lcproandcont{ top: -35px;}
}

@media screen and (max-width: 1024px){
.da-content h3 {font-size: 34px; font-weight: 400; line-height: normal;}
.da-content p {font-size: 25px;line-height: 40px;}
.da-concret h4 {font-size: 25px;}
.da-content h2, .da-contact h3 {font-size: 75px;}
.da-cform {margin-left: 30px;}
.da-contact{ margin-top: -1px;}
.da-navbar .disnowmenu .navbar-nav .nav-link{ font-size: 16px;}
.da-dsascw img:first-child {max-width: 60%;}
.da-mid img { max-width: 50%; }
.daalist { width: 200px; }
}

@media screen and (max-width: 991px){
.da-content .col-md-6, .da-content h2, .da-content .col-md-2, .da-content .col-md-10, .da-concret .col-md-4, .da-concret .col-md-8, .da-activate .col-md-5, .da-activate .col-md-7, .da-contact .col-md-6{ flex: 0 0 100%; max-width: 100%; text-align: center !important;}
.da-partners-list{ flex-direction: column; align-items: center;}
.da-partners-list span{ margin-top: 30px; margin-left: 0; width: auto;}
.da-concret .da-banner-btns, .da-activate .da-banner-btns{justify-content: center;}
.da-dsascw {padding-left: 0; text-align: center;     overflow: hidden;}
.da-dsascw img:last-child {right: 0; max-width: 75%;}
.da-connectrow, .da-activate .row{flex-direction: column-reverse;}
.da-artlisting ul{ flex-wrap: nowrap;}
.da-contact .col-md-6, .da-activate .col-md-7, .da-connect .col-md-4{ margin-bottom: 50px;}
.da-banner-btns.justify-content-end {justify-content: center !important; }
.da-cform {margin-left: 0;}
.da-concret h3 {margin-top: 0;}
.da-concret::after{background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 100%);}
.da-mobshow{ display: inline-block}
.da-mobhide{ display: none}
.da-connectrow h3{ margin-top: 100px;}
.da-navbar.da-fixed{ background: transparent; box-shadow: none; backdrop-filter: none;}
.da-navbar .desknone.navbar-nav.menulink {display: flex;}
.da-navbar .navbar-toggler .toggleicon {background: rgba(0,0,0,0.1); backdrop-filter: blur(10px);}
.da-imgani .da-dsascw img:last-child{ right: 0px; animation: rlloopmob 2s linear;}
.da-content h2, .da-contact h3, .da-concret h3, .da-activate h3 {font-size: 45px; margin-bottom: 15px; line-height: normal;}
.da-content h3, .da-concret h4, .da-activate h4, .da-contact p{font-size: 24px; font-weight: 400; line-height: normal;}
.daalist { width: 90%; }
}

@media screen and (max-width: 767px){
.da-navbar ul li a {font-size: 18px;}
.da-navbar ul li a.btn-blue{ padding: 5px 20px; line-height: normal !important; height: auto !important;}
}

@media screen and (max-width: 575px){
.da-banner-btns .btn{font-size: 20px; padding-left: 20px; padding-right: 20px;}
.da-banner-btns .btn:first-child {margin-right: 0px;}
.da-navbar ul li:not(:last-child) {margin-right: 0px;}
.da-wvplay{ width: 115px;}
.da-mid img { max-width: 65%;}
.da-content h2, .da-contact h3, .da-concret h3, .da-activate h3 {font-size: 32px;}
.da-content h3, .da-concret h4, .da-activate h4, .da-contact p, .da-bannercon h1 { font-size: 20px; line-height: normal;}
.da-content, .da-concret {padding: 50px 0px;}
.da-connectrow h3 { margin-top: 50px;}
.da-activate .da-abg::before{background: rgba(0,0,0,0.6);}
.da-banner-btns .btn {height: 60px !important;}
}

@media screen and (max-width: 475px){
.da-concret h5 {font-size: 22px;}
.da-cform{ padding: 0px; border: none;}
.daalist {width: 95%;}
.da-artlisting .alice-carousel__next-btn {right: -10px;}
.da-artlisting{ padding-left: 10px;}
.da-artlisting::before, .da-artlisting::after{ width: 30px;}
}

@media screen and (max-width: 414px){
.da-banner-btns .btn {font-size: 15px;padding-left: 15px;padding-right: 15px;}
.da-dsascw img:last-child, .da-dsascw img:first-child { max-width: 100%;}
.da-banner .da-banner-btns .btn{flex: 1;}
}