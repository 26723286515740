@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{outline: none;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{ color: #fff;}
.App { /* text-align: center; */}
.App-logo {  height: 40vmin;  pointer-events: none;}
@media (prefers-reduced-motion: no-preference) {  .App-logo {    animation: App-logo-spin infinite 20s linear;  }}
.App-header {  background-color: #282c34;  min-height: 100vh;  display: flex;  flex-direction: column;  align-items: center;  justify-content: center;  font-size: calc(10px + 2vmin);  color: white;}
.App-link {  color: #61dafb;}
a{ color: #d9d9d9;}
a:hover{ color: #fff; text-decoration: none;}
.card{ background: #1B1B1B; border-radius: 10px;}
.btn{ transition: all ease 0.75s;}
.alert{ border-radius: 0px;}
.center-screen-ok{  position: absolute;  top:0;  bottom: 0;  left: 0;  right: 0;  margin: auto;  width: 300px;  height: 300px;  background-color: green;  padding: 10px;}
.add-screen{  position: absolute;  top:0;  bottom: 0;  left: 0;  right: 0;  margin-left: 585px;  margin-top: 90px;  width: 200px;  height: 70px;  background-color: rgb(58, 61, 58);  padding:15px;  color: #b288c0;  font-weight: bolder;}
.center-screen{  position: absolute;  top:0;  bottom: 0;  left: 0;  right: 0;  margin: auto;  width: 100px;  height: 100px;  padding: 10px;  text-align: center}
.outer {  display: table;  position: absolute;  top: 0;  left: 0;  height: 100%; width: 100%;  }
.middle {  display: table-cell;  vertical-align: middle;}
.innertext {   width: 400px;  background-color: green;  margin: auto;  text-align: center;  height: 300px;  padding: 90px;  /* Whatever width you want */}
.innertext-danger{  width: 400px;  background-color: red;  margin: auto;  text-align: center;  height: 300px;  padding: 90px;}
.center-screen-danger {  position: absolute;  top:0;  bottom: 0;  left: 0;  right: 0;  margin: auto;  width: 300px;  height: 300px;  background-color: red;  padding: 10px;}
.ownerDec {   font-size: 16px;  cursor: pointer;  margin-bottom: 20px;  display: block; text-overflow: ellipsis; overflow: hidden;}
.ownerDec:hover{  color:#9144B5;}
.followtick{  opacity: 0.3;}
.decbtn {width:"35px"}
.decbtn:hover{background-image:none !important;}
.decbtn:hover span {display:none;}
.decbtn:hover:before {content:"Un follow";}
hr{border-top: 1px solid rgb(68, 68, 68);}
.proceed_btn {padding: 0px 15px !important; height: 42px !important; line-height: 38px !important; background: #9144B5; border-color: #9144B5 !important;  color: #fff !important; text-transform: none;}
.proceed_btn:hover {padding: 0px 15px !important; height: 42px !important; line-height: 38px !important; background: #fffdff; border-color: #9144B5 !important;  color: #9144B5 !important; text-transform: none;}
.userdescrip_adm{ max-height: 50px;overflow: hidden;text-overflow: ellipsis;position: relative;cursor:pointer;white-space: nowrap;}
.userdisableview{ width: 100%; color: #fff; display: flex; justify-content: center; flex-direction: column; align-items: center; min-height: 60vh;}
.form-control.descdan, .form-control.descdan:focus, .form-control.descdan:hover {
  box-shadow: 0px 0px 10px rgba(255, 2, 2, 0.9) !important;
  border-color: red !important;
}
.artDetCreator {
  font-size: 20px;
  align-items: center;
}
.bidclose{
  font-size: 20px;
}
.addCursor{
  cursor: pointer;
}
.warn-mes { 
  color:#9144B5;
}

tr.strikeout td:nth-child(2){text-decoration: line-through;}

.icon-container {
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
}
.userloader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.userloader::after, .userloader::before {
  content: "";
  background: transparent;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: rgb(255, 255, 255) rgb(255, 255, 255) transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.userloader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}
.otp {
  display:inline-block;
  width:50px;
  height:50px;
  text-align:center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
/*-----slider------*/

.owl-carousel .item {
  margin: 3px
}
.purc-hgt {
  max-height: 550px;
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.connect-wallet{
  position: relative;
  z-index: 9
}
.badazz_logo {
  border-radius: 50%;
}
.category-btn {
  display: inline-flex;
  margin: 0;
  padding: 5px 15px;
  border: transparent;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background: #1B1B1B;
}
.disable-reminder {
  pointer-events: none;
  cursor: not-allowed;
  background: #5a5a5a !important;
}
.airdrop-tab-hgt {
  max-height: 600px;
  overflow: auto;
}
.viewmus-image{
  max-height: 400px !important;
}
.spancursor{
  cursor: pointer;
}
.hidden-arts{  position: relative;}
.hidden-arts::before { content: ''; display: flex; align-items: center; justify-content: center; color: #cecece;  background: rgba(57, 57, 57, 0.9);  width: 100%;  height: 100%;  z-index: 2;  position: absolute;  border-radius: 10px; border: 1px solid #535353}
/*=------input------*/
.inp-line {  /* background: transparent; */  border: none;  border-bottom: 1px solid #000000;  opacity: 0.5;}
.inp-box{  color: transparent;    font-size: 0;    display: none;}
.inp-btn{  background-color: rgb(0, 183, 255);  border-radius: 25px;cursor: pointer;  padding: 10px;  color: antiquewhite;  margin: 10px;}
.inp-btn:hover{  border-radius: 25px;cursor: pointer;  padding: 10px;  color: antiquewhite;  margin: 10px;  background-color: rgb(0, 132, 255);  display: inline-block;    position: relative;  animation: bounce 0.5s infinite linear;}
.art-head{text-align: center;}
.mandatory{  width: 100%;  margin-top: .25rem;  font-size: 80%;  color: #dc3545;  }
.mandatory-req{  width: 100%;  margin-top: .25rem;  font-size: 80%;  color: #9144B5;  }
/*------------Admin --------*/
  .header {    overflow: hidden;    background-color: #000;    width:100%; position:fixed;top:0; z-index: 9;}
  .header a {    float: left;    color: rgb(238, 245, 245);    text-align: center;    padding: 10px;    text-decoration: none;    font-size: 18px;     line-height: 25px;    border-radius: 4px;    }
  .header-right {    float: right;  }
  .header a:hover {color: white;  }
  .header .logo{ color:white }
  .sidebar { z-index: 1;   margin: 0;    padding: 0;    width: 200px;    background-color: #000;    position: fixed;    height: calc(100vh - 72px);   overflow: auto;   top: 72px; }
  .removeSidebar{ display: none;}
  .sidebar a {    display: block;    color: #797979;    padding: 10px 15px;    text-decoration: none;  }
  .content-shift{    margin-left: 215px;    margin-top: 90px;  }
  .content-not-shift { margin-left: 215px; margin-top: 90px;}
  .content-not-shift .bg2f2f2f { background-color: #1c1c1c;}
  .highLight,   .sidebar a:hover:not(.active){ background-color: #141414; color: #fff !important;}
  .table{ color: #fff;}
  .table-bordered td, .table-bordered th {border: 1px solid #2d2d2d; vertical-align: middle;}
  .table thead th{ border-bottom: 1px solid #2d2d2d;}
  .table-striped-cusom tbody:nth-child(odd){background-color: #141414;}
  .table-striped-cusom thead{background-color: #000; color: #fff;}
  .new-striped tbody tr:nth-child(even){background-color: #1B1B1B;}
  .new-striped tbody tr:hover{background: #33223e;}
  .table tbody + tbody{ border: none;}
  .btn.designLink{color: #fff !important;    text-decoration: none;    cursor: pointer; text-decoration: none; padding: 5px 15px; font-size: 14px; height: auto !important; line-height: normal !important;  }
  .btn.designLink:hover, .btn.designLink:active {   background-color: #9144B5;  }
  .designLink:hover {    color: #fff;  cursor: pointer; }
  .designLink { color:#9144B5}
  .designLink1 { color:#5913db}
  .designLink1:hover { color: rgb(0, 0, 0);  cursor: pointer; }
  .userIcon {width: 15px;height: 15px;margin-left: 5px;}

.lilacbg1{ background-color: #9144B5;color:#000}
  .betaLink { color:#9144B5;font-size: 14px;}
  .designLink_active { color:#5913db}
  .captcha {    display: inline-block;  cursor: pointer; }
  /*--------------Dashboard--------*/
.card-box {position: relative;color: #fff;padding: 20px 10px 10px;margin: 20px 0px;}
.card-box.bg2f2f2f {min-height: 145px;}
.card-box:hover {text-decoration: none;color: #f1f1f1;}
.card-box:hover .icon i {font-size: 100px;transition: 1s;-webkit-transition: 1s;}
.card-box .inner {padding: 5px 10px 0 10px;}
.card-box h3 {font-size: 27px;margin: 0 0 8px 0;white-space: nowrap;padding: 0;text-align: left;}
.card-box p {font-size: 15px;}
.card-box .icon {position: absolute;top: auto;bottom: 5px;right: 5px;z-index: 0;font-size: 72px;color: rgba(221, 178, 236, 0.15);}
.card-box .card-box-footer {position: absolute;left: 0px;bottom: 0px;text-align: center;padding: 3px 0;color: rgba(255, 255, 255, 0.8);background: rgba(0, 0, 0, 0.1);width: 100%;text-decoration: none;}
.card-box:hover .card-box-footer {background: rgba(0, 0, 0, 0.3);}
  /*--------- Alert -----*/
  .custom-ui { background-color: #1B1B1B; padding: 40px 95px; border-radius: 15px;}
  .custom-btn { margin: 20px;}
  .sticky {position: fixed;top: 0;width: 100%;}
  /*--------------Login-------*/
  .wrapper {    width: 100vw;    min-height: 100vh;        padding: 30px 15px;    display: flex;    align-items: center;    justify-content: center;}
.login {background-color: #141414;  display: flex;  border-radius: 10px;  overflow: hidden;  }
.login .login-form {  flex-grow: 1;  flex-shrink: 0;  flex-basis: auto;  background-color: #141414;  padding:30px;}
.login .login-form .login-title {  font-size: 1.5rem;  margin-bottom: 25px; text-align: center;}
.login .login-form .form-wrapper .input-wrapper .label, .login .login-form .form-wrapper .input-wrapper .input {  display: block;  width: 100%;}
.login .login-form .form-wrapper .input-wrapper .label {  padding-bottom: 3px;  font-size: 0.9rem; }
.login .login-form .form-wrapper .input-wrapper .input {  padding: 10px;  border-radius: 5px;  border: 1.5px solid #ddd;}
.login .login-form .form-wrapper .actions .action {  display: flex;  align-items: center;  justify-content: center;  width: 100%;  padding: 10px 30px;  margin-bottom: 15px;  border-radius: 25px;  color: #fff;  background-color: 000;  text-decoration: none;  border: 1px solid #000  ;  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;}
.login .login-decoration {  flex-grow: 1;  flex-shrink: 0;  flex-basis: auto;  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3ksohk8bSWD3ZxFFbK9SRi1jUKSTS9iYUyg&usqp=CAU);  background-position: center;  background-size: cover;}
.form-check-label {text-transform: none !important;}
/*----------------Footer---------*/
.site-footer{ padding:50px 0px;  font-size:15px;  line-height:24px; margin-top: 0px; color: #797979;}
.site-footer hr{  border-top-color:#5e5e5e;  opacity:0.5}
.site-footer h6{  color:#c4c4c4;  font-size:16px;  text-transform:uppercase;  margin-top:5px;  letter-spacing:2px}
.site-footer a{  color:#797979;}
.site-footer a:hover{  color:#fff;  text-decoration:none;}
.footer-links{  padding-left:0;  list-style:none}
.footer-links li{  display:block; line-height: 30px;}
.footer-links a{  color:#797979; width: 100%; display: inline-block;}
.site-footer .social-icons{  text-align:right;  list-style: none;  }
.site-footer .social-icons a{  width:40px;  height:40px;  line-height:40px;  margin-left:6px;  margin-right:0;  border-radius:100%;  background-color:#33353d;  }
.footer-links.inline li{  display:inline-block}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover{  color:#fff; text-decoration:none;}
.copyright-text{  margin:0}
.disableDv {pointer-events: none;opacity: 0.6;cursor:not-allowed !important; }
/*--------image-------*/
.gallery{  position: relative; }
.img-card{  border: 1px solid rgb(223, 211, 211);  margin-bottom: 25px;}
 .img-card:hover{  border:1px solid rgb(109, 104, 104);}
.gallery img:hover {  opacity: 1;  transition: 1s ease;}
.wish-icon{  right: 28px;  bottom: 55px;  position: absolute;  font-size: 20px;  color:red}

/*---------Alert---------*/
.alert-dan {  padding: 10px 16px;  background-color: #e0b7b7 ;  color: rgb(155, 67, 67);  /* position: relative;  width: 100%;   top:0 */ text-align: center;}
.alert-suc {  padding: 10px 16px;  background-color: #04AA6D;  color: white;  /* position: absolute; */ /* width: 100%; */  text-align: center;  /* top:0 */}
.closebtn {  margin-left: 15px;  color: rgba(23, 23, 23, 0.4);    font-size: 32px;  line-height: 20px;  cursor: pointer;  transition: 0.3s; position: relative;  top: 0px;  right: 10px; height: 100%; display: flex; align-items: center;}
.closebtn:hover {color: #000;}
#alertparent{ padding: 0px; height: 50px; line-height: 50px;} 

.panel {
  width: 200px;
  height: 100px;
  background: gray;
  margin-top: 10px;
}

.example-enter {
  height: 0px;
}

.example-enter.example-enter-active {
  height: 100px;
  -webkit-transition: height .3s ease;
}

.example-leave.example-leave-active {
  height: 0px;
  -webkit-transition: height .3s ease;
}

/*-----------navmenu dropdown-------*/
.dropbtn { color: white; padding: 16px;border: none;cursor: pointer;}
.dropdown {position: relative; display: inline-block;}
.dropdown-content { display: none; position: absolute; right: 0; background-color: #1B1B1B; min-width: 160px; box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); z-index: 9; top: 100%; border: 1px solid #333;}
.dropdown-content a { color: #fff !important; padding: 12px 16px; text-decoration: none; display: block; font-size: 20px;white-space: nowrap;   letter-spacing: 0.5px;}
.dropdown-content a:hover, .dropdown-content a:focus, .dropdown-content a.active {background-color: #9144B5; color: #fff !important;}
.dropdown:hover .dropdown-content {display: block; margin: 0px;  top: 98%;}

/*------loader------*/
.loader{position: fixed;  top: 0;  left: 0;  width: 100%;  height: 100%;  z-index: 999999!important;    background-color: rgba(0,0,0,0.7);}
.loader-hide{display: none;} 
.discovload{position: relative;  width: 100%;  height: 200px;}
 .show-content{display: flex; flex-direction: column;  justify-content: center; position: fixed;  padding: 160px;  width: 100%;  height: 100%;  z-index: 15;  top: 0%;  left: 0%;  margin: 0;  background:rgba(0, 0, 0, 0.9);  text-align: center;  color:white; }
 .show-content-D{display: flex; flex-direction: column;  justify-content: center; position: fixed;  padding: 160px;  width: 100%;  height: 100%;  z-index: 15;  top: 0%;  left: 0%;  margin: 0;  background:rgba(0, 0, 0, 0.9);  text-align: center;  color:white; }

 .child-show{ background-color: #141414;   color:black;   padding: 30px;   border-radius: 10px; width: 500px; margin: 0px auto; }
  .show-cancel {  position: fixed;  padding: 10px;  width: 100%;  height: 100%;  z-index: 15;  top: 0%;  left: 0%;  margin: 0px;  background: rgba(0, 0, 0, 0.9);  text-align: center;  border-radius: 0px;  display: flex;  flex-direction: column;  align-items: center;  align-content: center;  justify-content: center;overflow-y: auto;}
 .show-success {  position: fixed;  padding: 10px;  width: 100%;  height: 100%;  z-index: 15;  top: 0%;  left: 0%;  margin: 0px;  background: rgba(0, 0, 0, 0.9);  text-align: center;  border-radius: 0px;  display: flex;  flex-direction: column;  align-items: center;  align-content: center;  justify-content: center; overflow-y: auto;}
 .show-success h3{ font-size: 32px; font-weight: normal; margin: 25px 0px;}
 .success-pop {background-color: #141414; padding: 50px 30px;  border: 2px solid #232323;  border-radius: 10px;}
 .checkmark__circle {  stroke-dasharray: 166;  stroke-dashoffset: 166;  stroke-width: 50;  stroke-miterlimit: 10;  stroke: #9144B5;  fill: none;  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;}
.checkmark {  width: 84px;  height: 84px;  border-radius: 50%;  display: block;  stroke-width: 5;  stroke: #fff;  stroke-miterlimit: 20;  margin: 0% auto;  box-shadow: inset 0px 0px 0px #9144B5;  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;}
.checkmark__check {  transform-origin: 50% 50%;  stroke-dasharray: 48;  stroke-dashoffset: 48;  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;}
.ReactModal__Overlay{  /* z-index: 999999;position: absolute;  left:50;  right: 10;  height: 200px;  top: 50%;  width:50%;  bottom: 10;  padding: 10px;  background-color: rgba(253, 247, 247, 0.75);  display: flex; */
position: fixed;  padding: 30px;  width: 100%;  height: 100%;  z-index: 15;  top: 0%;  left: 0%;  margin: 0px;  background:rgba(0, 0, 0, 0.63) !important;  text-align: center;  border-radius: 0px;  display: flex;  flex-direction: column;  align-items: center;  align-content: center;  justify-content: center; backdrop-filter: blur(5px);}
.ReactModal__Content, .ReactModal__Content .authbg { background: transparent !important;  border: none !important;}

@keyframes stroke { 
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #9144B5;
  }
}

.crossmark__circle {  stroke-dasharray: 166;  stroke-dashoffset: 166;  stroke-width: 50;  stroke-miterlimit: 10;   stroke: #ff0000;  fill: none; animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;}
.crossmark {  width: 84px;  height: 84px; border-radius: 50%;  display: block;  stroke-width: 5;  stroke: #ff0000;  stroke-miterlimit: 10;  margin: 0% auto;  box-shadow: inset 0px 0px 0px #ff0000;  animation: fillred .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;}
.crossmark__check {  transform-origin: 50% 50%;  stroke-dasharray: 48;  stroke-dashoffset: 48;  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;}
.cross__path {  stroke: #ffffff;  stroke-dasharray: 48;  stroke-dashoffset: 48;  transform-origin: 50% 50% 0;}
.cross__path--right {  animation: 0.3s ease 0.8s normal forwards 1 running stroke;}
.cross__path--left {  animation: 1s ease 0.8s normal forwards 1 running stroke;}
@keyframes fillred {
  100% {
    box-shadow: inset 0px 0px 0px 30px #ff0000;
  }
  
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}


.usernameClick{text-align: center;display: flex;align-items: center;justify-content: center;cursor: pointer;}
.usernamenotClick{text-align: center;display: flex;align-items: center;justify-content: center;}
 /*-----Tabs------*/
 .tab-wrap{ margin:0 0 30px 0; }
.tab-change {  display:flex;  padding: 0;  border-image: linear-gradient(to right, #9144B5, #21CFF1) 1; border-bottom-width: 1px;  border-bottom-style: solid;}
.tab-change li{  list-style-type: none; flex: 1; padding:15px; cursor: pointer; transition: all ease 0.75s;}
.tab-change li h5{font-weight: 600 !important; color: #21CFF1; transition: all ease 0.75s; margin-bottom: 0px; display: flex; justify-content: space-between; align-items: center;}
.tab-select{   display: inline-block;   border-bottom:1px solid #000;   cursor: pointer;   color: #121213; }
.tab-change li .btn { transition: all ease 0.75s;  font-size: 12px;  line-height: 30px !important;  width: 30px !important;  height: 30px !important;  padding: 0px !important;  text-align: center;}
.react-tabs__tab--selected{border-color: transparent !important; background: transparent !important; margin-bottom: -1px; }
.react-tabs__tab--selected h5{color: #fff !important; }
.tab-change li:hover h5{ color:#9144B5 ;}
.tab-change li:hover{border-color: #9144B5 !important;}

 /*------pagination-------*/
 .pagination {display:block; margin:30px 0px;}
 .pagination li { display: inline-block;  margin: 2px;}
 .pagination li a {position: relative; color: #000; background-color: #1B1B1B; border-radius: 50px; transition-duration: 0.3s;font-size: 12px;  padding: 0;  width: 25px;  height: 25px;  display: inline-flex;  justify-content: center;  align-items: center;  line-height: normal;  margin: 0px;}

 .pagination .active a, .pagination li a:hover{ background: #9144B5; color: #fff !important; }
 /*------Detail Wallet-----*/
 .detailWallet { min-height: calc(100vh - 455px);}
/*------- Coming soon image -----*/
 .grain {position: absolute;left: 0%;top: 0;z-index: 1;background-image: url("assets/images/noise.gif");background-position: 50% 50%;background-size: auto;
  opacity: 0.09;width: 100%;height: 100vh;}
 .bgimg{display: flex; justify-content: center; flex-direction: column; align-content: center; align-items: center; height: 100vh; width: 100%; background: url('assets/images/lady-img.png') no-repeat; background-position: top right;
  background-size: 35%; padding: 0px 30px; box-sizing: border-box;}
  .wallet-logo .dapper img { width : 32px; margin-right: 10px;}
  .wallet-logo .dapper {min-width: 200px}

  /* rajkumar */
  .iconcomponent{
    padding: 0 10px 0 0;
    font-size: 20px;
    color:  #9144B5;
  }
  .walltcon >.iconComponentParent > span > i {
      padding: 0;      
  }
  .tab-change li .btn {
      align-items: center;
      justify-content: center;
      line-height: 24px!important;
  }
  .adminLinkBox a{
    display: block; /* Converts the link to a block element */
    overflow: hidden; /* This hides any content that overflows the link */
    white-space: nowrap; /* Prevents the link from wrapping to the next line */
    text-overflow: ellipsis; /* Adds ellipsis (...) to indicate truncated text */
  }
  .iconComponentParent{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  /* .dropdown.favorite .dropdown-toggle i.fa-heart {
    animation: heartAnimation 0.5s ease-in-out;
  }

  .dropdown.favorite .dropdown-toggle i.fa-heart {
    animation: heartAnimation 0.5s ease-in-out;
  }

  @keyframes heartAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(2);
      color: #9144B5;
    }
    100% {
      transform: scale(1);
    }
  } */

  @media(max-width:1360px){
      .bgimg{background-position: top right;   background-size: contain;}
  }
  @media(max-width:991px){
      .bgimg{background-position: bottom center;   background-size: contain;}
      .iconmenu .card.flowtop span.nav-link{ text-transform: none;}
  }
 
/* -------------------toggle-------------
.ToggleSwitch{  background: linear-gradient(#ccc, #eee);  border: 1px solid purple;  height: 2em;  width: 5em;  border-radius: 1em;}  
.knob{    position: relative;    width: 1.9em;    height: 1.9em;    background: #666;    border: 1px solid #ddd;    border-radius: 50%;    left: 0em;    transition: left 0.3s ease-out;            }
.knob:active{           left: 3em;  } */
@keyframes App-logo-spin {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

/* You can add global styles to this file, and also import other style files */
body{padding: 0px; margin: 0px; font-family: 'Poppins', sans-serif; color: #fff; font-size: 15px; background: #000000;}
html, body { height: 100%; }
.h100vh{min-height: 100vh;}
.w350{ width: 350px;}
.br30{border-radius: 30px !important;}
.btn{box-shadow: none !important; border-radius: 50px !important; height: 40px !important; line-height: 37px !important; border: 2px solid #fff !important; color: #fff !important; font-weight: 600; padding-top: 0px; padding-bottom: 0px;  text-transform: capitalize; } 
.btn:hover,btn:focus{box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 15px !important;}
.w-125px{width: 125px !important; text-align: center !important;}
.w-75px{ width: 75px;}
.lgbtn{height: 50px !important;line-height: 50px !important;}
.form-control::-webkit-input-placeholder {color: #fff; font-size: 14px; font-weight: normal;}
.form-control::-moz-placeholder {color: #fff; font-size: 14px; font-weight: normal;}
.form-control:-ms-input-placeholder{color: #fff; font-size: 14px; font-weight: normal;}
.form-control:-moz-placeholder{color: #fff; font-size: 14px; font-weight: normal;}
.wbg{background-color: #fff !important;}
.flex1{flex: 1;}
.ricobtn i{float: right; margin-top: 7px; font-size: 25px;} 
.btn-primary {background-color: #21CFF1; border-color: transparent !important; color: #000000 !important;}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle{background-color: #9144B5; box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 15px !important; color: #fff !important; }
.btn-primary-fill{background-color: #9144B5; border-color: #9144B5 !important; color: #fff !important;}
.btn-primary-fill:hover, .btn-primary-fill:focus, .btn-primary-fill:active, .btn-primary-fill:not(:disabled):not(.disabled).active, .btn-primary-fill:not(:disabled):not(.disabled):active, .show > .btn-primary-fill.dropdown-toggle{background-color: #141414; box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 15px !important;  color: #9144B5 !important; }

.btn-outline-secondary {color: #6c757d !important; border-color: #6c757d !important;}
.btn-outline-secondary:hover,  .btn-primary:focus, .btn-outline-secondary:active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {color: #fff !important; background-color: #6c757d;  border-color: #6c757d !important;}
.btn-outline-dark {color: #9144B5 !important;  border: 2px solid transparent !important; background: #000; background-clip: padding-box; position: relative; border-radius: 10px !important;}

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {color: #fff !important; background-color: #9144B5; border-color: #9144B5 !important;}
.btn-outline-secondary:hover, .btn-primary:focus, .btn-outline-secondary:active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle { color: #000 !important;  background-color: #9144B5;  border-color: #9144B5 !important;}
.btn-primary.disabled, .btn-primary:disabled{background-color: #373737; border-color: #373737 !important; color: #999 !important} 
.darkbrd{border: 4px solid #000; box-sizing: border-box;}

.authbg{background:#f9f9f7; width: 100%;}
.authbg h1{font-size: 1.7rem; padding: 150px 0px 50px 0px; font-weight: 500; margin: 0px; }
.authdnlogo img{width: 250px;}
.iconbutton .btn {margin-bottom: 7px;width: 250px;text-align: left;font-size: 15px;}
.splitor{ display: block; width: 100%; text-align: center; font-size: 30px; text-transform: uppercase; color: #8c8c8c; padding: 30px 0px; font-weight: 600;}

.form-label {font-weight: 600;font-size: 15px;display: block; margin: 15px 0px;}
.form-control{background: #141414; border:1px solid #4f4f4f; border-radius: 10px; color: #fff; transition: all ease 0.75s;}
.form-control:hover, .form-control:focus {border-color:  #9144B5;  box-shadow: none; background-color: #141414; color: #fff;}
.form-group label{text-transform: uppercase;}
.form-control:disabled, .form-control[readonly] {background-color: #333; border-color: #333;}
.text-right{text-align: right !important;}
.navbar-brand{ position: relative; padding: 0;}
.navbar-brand img { height: 40px;}
.navbar-brand .logoicon{ transition: all ease 0.85s; position: absolute; width: 100%; height: 100%; left: 0px; opacity: 0;}
.navbar-brand:hover .logoicon{ opacity: 1;}
.disnowmenu{ padding:0.5em 0px; z-index: 3;  background: #000; border-image: linear-gradient(to right, #9144B5, #21CFF1) 1; border-bottom-width: 3px;  border-bottom-style: solid;}
.disnowmenu .navbar-nav .nav-link {font-weight: 400;padding: 10px 20px !important; color: #fff; border-radius: 10px; position: relative; font-size: 16px;}
.disnowmenu .navbar-nav .nav-link.btn-primary {background: #9144B5;border-color: #9144B5 !important; color: #fff !important; padding: 10px 10px 10px 10px !important; height: auto !important;  border-radius: 6px !important;
  line-height: 1 !important;}
.disnowmenu .navbar-nav .nav-link.btn-primary::before{display: none;}
.disnowmenu .navbar-nav .nav-link::before{position: absolute; content: ''; height: 1px;  width: 90%; left: 5%; bottom: 0px; background: #fff; transition: all ease 0.75s; opacity: 0; }
.disnowmenu.navbar-light .navbar-nav .nav-link.btn-primary:hover, .disnowmenu.navbar-light .navbar-nav .nav-link.btn-primary:focus, .disnowmenu.navbar-light .navbar-nav .nav-link.btn-primary.active  {background: transparent; color: #9144B5 !important;}
.disnowmenu.navbar-light .navbar-nav .nav-link:hover::before, .disnowmenu.navbar-light .navbar-nav .nav-link:focus::before, .disnowmenu.navbar-light .navbar-nav .nav-link.active::before{opacity: 1;}
.disnowmenu .navbar-nav .nav-link i.fa-fire { font-size: 16px;  margin-right: 2px; color: #9144B5;}
.disnowmenu .navbar-nav .nav-link i{ font-size: 25px;}
.disnowmenu .navbar-nav .dropdown-toggle::after{ display: inline-block; position: relative; top: 2px;}
.propic img{width:48px; border-radius: 50%; border: 3px solid #000;}
.disnowmenu.navbar-light .navbar-nav .nav-link:hover, .disnowmenu.navbar-light .navbar-nav .nav-link:focus, .disnowmenu .navbar-nav .nav-link.active { background: transparent; color: #fff !important;}
.nav-link img { float: left; margin-right: 5px; position: relative;  top: 5px; width: 20px;}
.nav-link:hover img, .disnowmenu .navbar-nav .nav-link.active img{ filter:brightness(0) invert(1);}
.iconmenu { align-items: center; margin-left: 0px;}
.iconmenu .card{ margin: 10px 10px 0px 0px;}
.flowtop .copycon {display: none;position: absolute;padding: 2px 10px;cursor: pointer;font-size: 12px;background: #2d2d2d;border-radius: 10px;top: 100%;opacity: 0;z-index: 1;transition: all ease 0.75s;
white-space: nowrap;min-width: 140px;left: 50%;text-align: center;color: #fff;text-transform: capitalize;transform: translateX(-50%);}
.flowtop:hover .copycon{ opacity: 1; z-index: 3; display: block;}
.menulink, .iconmenu  {  box-shadow: 0px 10px 20px rgba(0,0,0,0.05); border-radius: 10px;  height: 64px;  display: flex;  align-items: center;  padding: 0px;  }
.disnowmenu .navbar-nav .nav-link.active i{color: #fff;}
.iconmenu .card.flowtop, .fund_wallet_menu .card.flowtop{ border: none; margin: 0px ; background: transparent; cursor: pointer;}
.iconmenu .card.flowtop span.nav-link {padding: 0px 10px !important; text-align: center; color: #fff;}
.uparrow{ position: absolute; width: 0px; height: 0px; border-bottom:5px solid #2d2d2d; border-left:5px solid transparent; border-right:5px solid transparent; border-top:5px solid transparent; top: -10px;  left: 50%;  margin-left: -5px;}
.nav-item.propic {padding: 0px 6px !important; cursor: pointer;}

.cardlist{ border-radius: 10px; background: #1B1B1B; padding: 10px; box-shadow: 0px 10px 20px rgba(0,0,0,0.2); margin-bottom: 30px; position: relative; transition: all ease  0.75s; top: 0; overflow: hidden; border:1px solid transparent;min-height: 95%;}

/* .cardlist .comimg{ width: 100%; border-radius: 0px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; max-width: 100%; height: auto; object-fit: contain;} */
.creators .cardlist .comimg, .creators .cardlist .articon {height: 100% !important; object-fit: cover; display: block; position: absolute; width: 100%; border-radius: 10px;}

.ccimgbox {display: flex; height: 150px; justify-content: center; align-items: center;  width: 100%;}
.cardlist .ccimgbox img.comimg { position: static;}

.cardlist .cont{padding: 15px; display: block;}
.progallists .cardlist .cont{padding: 0px;min-height: 50px;}

.morelist .cont{padding: 0px; min-height: 115px;}
.cardlist a{color: #000; text-decoration: none; }
.cardlist .cont h3, .cardlist .cont h4{ margin-bottom: 0px; font-weight: 600;  line-height: 28px;}
.cardlist .cont h4{ font-size:18px; margin-bottom: 0px; text-overflow: ellipsis;  overflow: hidden;  white-space: nowrap;}
.cardlist .cont h3{font-size: 24px; white-space: nowrap;  overflow: hidden;  text-overflow: ellipsis;  margin-bottom: 15px; font-weight: 400;}
.cardlist .cont p{ font-size: 14px !important;}
.ccdetailbox .cardlist .cont h3, .ccdetailbox .cardlist .cont h4{font-weight: normal;}
.cardlist:hover{ box-shadow: 0px 0px 10px rgba(145, 68, 181, 0.5);}
.cardlist:hover .comimg{ border-color: #d8dffc;}
.cardlist.whbg{ background: #fff; margin-bottom: 15px;}
.cardlist.whbg:hover{background: #d8dffc;}
.cardlist h4.price{color: #000}
.cardlist .btn {height: 38px !important;line-height: 36px !important; padding: 0px !important;}

.dropseclist .userwithpic.listed_by {white-space: nowrap;margin-top: 5px;}

.artcontent{min-height: calc(100vh - 470px);}
.artlist{ margin-top: 20px; display: flex; flex-direction: column;}
.artlist .btn{ line-height: 40px !important ; padding: 0px !important; font-weight: 400; font-size: 14px; margin-bottom: 15px;}
.artlist .comimg{ width: 100%;}
.artlist textarea.form-control{height: 75px; margin-bottom: 30px;}
.cardlist .comimg.w73px { width: 73px; border-radius: 16px;}

.socialicons a{ font-size: 20px; margin: 0px 5px; border: 1px solid #9144B5; width: 35px; height: 35px; display: inline-block; text-align: center; color: #9144B5; border-radius: 9px; line-height: 35px;}
.socialicons a:hover, .socialicons a:focus{ color: #000; background: #9144B5;}

.pagetitle{height: 48px; margin-bottom: 30px;}
.pagetitle .iconsec{ align-items: center;  display: flex; }
.pagetitle .iconsec a{font-size: 32px; color: #000;}
.propicbig{display: inline-block; border: 8px solid #000; width: auto; border-radius: 10px; overflow: hidden;}
.profilecont .artlist {margin-top: 0px;}
.profilecont .artlist .btn { max-width: 300px;}
.pagetitlesec{ justify-content: center; display: flex; align-items: center; height: 48px;}
.pagetitlesec h1{margin: 0px;}
.profilecont .container-fluid{ margin-bottom: 30px;}

.collectionslist .artlist img {    margin-right: 0px;}
.collectionslist .artlist {margin-bottom: 15px;}

.profilecont .cardlist.px-4{min-height: 1050px;}

.choosefile{ position: relative;}
.choosefile .btn { padding-top: 0px; padding-bottom: 0px; margin: 0px;}
.choosefile .inp-box{ position: absolute; top: 0px; left: 0px; width: 125px; opacity: 0;}
.previewimg {  display: inline-block;  position: relative;   background: #1B1B1B;  border: 1px solid #323232;  box-shadow: none; width: 100%; height: auto;  max-height: 100vh;     overflow: hidden;}
.previewimg .artcovimg { max-height: 550px;}
.previewimg img, .previewimg video,  .previewimg .audio-art, .previewimg .audio-art audio{ width: 100% !important; height: 100% !important; border: 0; }
.previewtxt {position: absolute; right: 0px; top: 0px; padding: 5px 10px; background: #262626; border-radius: 0px 0px 0px 10px; color: #c4c4c4;}
.card-footer .btn {font-size: 16px; padding: 0px 20px;}
.card-footer{ padding: 15px 0px; background: transparent; margin-top: 30px;}
.loginfrm label {font-size: 16px;}

.react-toggle-screenreader-only {position: absolute; top: 0px;}
.loginfrm .react-toggle {top: 5px;}
.gallery .img-card {padding:15px; border-radius: 10px; border: 1px solid #eee; position: relative; top: 0px; transition: all ease 0.2s;}
.gallery img{opacity: 1; border-radius: 0px; width: 100%; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;}
.gallery .img-container{ margin: 15px 0px;}
.gallery .img-container h4{ font-size: 1.2rem;  font-weight: 600;}
.gallery .img-card:hover{box-shadow: 0px 2px 5px rgba(0,0,0,0.2); top: -5px;}

.custom-btns .form-control{ position: relative; top: 5px;}
#uploadArt{ cursor: pointer;}

.coverimg{ width: 100%; position: relative; height: 300px; display: block; align-items: center;  overflow: hidden; margin-top: 0px; box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 15px; }
.coverbrd{border: 8px solid #373737;}
.coverimg img{ width: 100%; height: 100%; object-fit: cover; max-width: 100%; position: relative; }
.propagecont{}
.picpro{ width: 150px; height: 150px; display: block; margin: -75px auto 0px auto; border-radius: 50%; position: relative; overflow: hidden; border: 8px solid #373737; box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 15px;}
.picpro img{width: 100%; border-radius: 50%; position: absolute;}
.probtns { padding: 15px 0px;}
.probtns ul li{ display: inline-block; }
.probtns .btn{height: auto !important; line-height: normal !important; padding: 10px 15px; margin: 0px 5px 5px 5px; text-transform: none;}
.progallists .artcovimg, .morelist .artcovimg{margin-bottom: 15px; background: #141414;}
.artupload {margin-bottom: 30px;}
.modalfollowlist {align-items: center;}
.modalfollowlist p {margin-bottom: 0px;font-size: 14px; color: #666;  }
.modalfollowlist .btn{ width: 100%;}
.modalfollowlist .row{ margin-bottom: 15px; align-items: center;}
.modal-body .modalfollowlist:last-child .row{margin-bottom: 0px;}
.modalfollowlist .upic {float: left; margin: 0px 10px 0px 0px;}
#following .modal-body, #followers .modal-body{ margin-bottom: 0px !important;}
.modalfollowlist h5 {font-size: 16px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; margin: 3px 0px 0px 0px;}

.followimg {  width: 50px;  border-radius: 50%;  border: 2px solid #000;  margin: 0px 15px 0px 0px;  float: left;}
.modal-content{ border-radius: 10px; background: #141414;}
.modal-header{border-bottom: 1px solid #333333;}
.modal-footer{border-top: 1px solid #333333;}
.iconmenu .card span.nav-link {font-size: 13px !important; padding: 0px 1rem !important;  line-height: 20px; cursor: pointer;}
.purchasebox{ overflow: hidden; overflow-y: auto; background-color: #1B1B1B; border-radius: 10px; padding:15px; box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 20px; margin-bottom: 25px;}
.commandsection{ margin-bottom: 50px; width: 100%; display: block;}
.commandsection textarea{ height: 200px; margin-bottom: 30px;}
.purchasebox .card{background: transparent;}
.purchasebox h5{word-break: break-word; font-size: 16px;  }
.purchasebox.activitybox h5{text-align: left;}
.purchasebox h6 {font-size: 12px;}

.reviews {color: #000; font-size: 16px;}
.notes {  color: #999;  font-size: 12px;}
.media .media-object { max-width: 120px; }
.media-body { position: relative; width: 100%; }
.media-date {   position: absolute;   right: 0px;  top: 0px;}
.media-date li { padding: 0; }
.media-date li:first-child:before { content: ''; }
.media-date li:before {   content: '.';   margin-left: -2px;   margin-right: 2px;}
.media-comment { margin-bottom: 10px;  font-size: 12px;}
.media-replied { margin: 0 0 0px 30px; }
.media-replied .media-heading { padding-left: 6px; }
.media-list {padding: 0px;}
.media-list .media-object{border-radius: 50%; width: 65px; height: 65px; margin-right: 15px;}
.media-list  .media{padding: 15px 0px; flex-direction: column;}
.media-body .btn{ margin-right: 5px; padding: 7px 15px; height: auto !important; line-height: normal !important; font-size: 12px;}
.media-body .btn i{ margin-right: 5px;}
.ddmmaaaa {font-size: 12px;}
.media-replied .media-body {flex-direction: row; display: flex;}
.media-list .collapse { width: 100%; margin-top: 15px;}
.login-form{ width: 300px;}
.login-form .authogo{ width: 100px; margin: 0px auto 50px auto; display: block;}
.activecopy .copycon{ background: #2d2d2d; color: #fff; border-color: #2d2d2d;}
.profilesec{ min-height:calc(100vh - 490px ); }
.profilesec .alert-custom{ margin-bottom: 15px;}
.react-toggle-track{background-color:rgb(158 102 191 / 38%) !important}
.react-toggle-thumb{border: 1px solid #9144B5 !important; background-color: #9144B5 !important;}
.react-toggle--checked .react-toggle-track{ background-color:rgb(158 102 191 / 38%) !important}
.react-toggle--focus .react-toggle-thumb{ box-shadow: 0px 0px 2px 3px rgba(0,0,0,0.2) !important;}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {box-shadow: 0px 0px 5px 5px #000 !important;}

.wh-100{ width: 100%; height: 100%;}
.no-images{background-image: url('assets/images/no-images.png'); background-repeat: no-repeat; background-size: auto; background-position: center; background-color: #2d2d2d; }
.no-images-vertical{background-image: url('assets/images/no-images-vertical.jpg'); background-repeat: no-repeat; background-size: auto; background-position: center; background-color: #2d2d2d; }
.no-proimg{background-image: url('assets/images/no-proimg-gif.gif'); background-repeat: no-repeat; background-size: cover; background-position: center; background-color: #2d2d2d; position: absolute; width: 100%; height: 100%; top: 0px; 
  left: 0px; border-radius: 50%; }
.no-proimg-wb{background-image: url('assets/images/no-proimg-wb.jpg');} 
.covernoimg{position: absolute; width: 100%; height: 100%;}
.createartimg{ background-size: auto; background-color: #1B1B1B;}
.no-images-vertical.createartimg {    min-height: 350px !important;  height: auto !important;  max-height: inherit;  min-width: 100%;}
/* .artfrmtop{ margin-top:-105px;} */ 

/* .userwithpic{ margin-top: 30px;} */
.upic{ position: relative; width: 28px; height: 28px; border-radius: 50%; overflow: hidden; margin-right: 10px; z-index: 1; margin-top: 5px;  margin: 5px 7px 5px 0px;}
.upic img{ position: absolute; width: 100%; }
.userwithpic h6{ font-size: 14px; margin-bottom: 7px; color: #c1c0c0;    /* overflow: hidden; white-space: nowrap; text-overflow: ellipsis; */  display: block; align-items: center;}
.userwithpic p.price, .userwithpic p.update{ font-size: 16px; font-weight: 500; margin-bottom: 0px;}
.userwithpic p.update {text-overflow: ellipsis; overflow: hidden;}
.cardlist .cont .userwithpic {margin-top: 0px; width: 100%; display: inline-flex; position: relative;}
.cardlist .cont .userwithpic:hover h6 {color: #9144B5;}
.input-group-text{border-radius: 10px;}
.cardlist .cont .userwithpic .permium{ position: static; float: left;  margin-right: 5px; width: 15px; height: 15px;}
.cardlist .cont .userwithpic .permium img{width: auto; max-width: 100%;}
.userwithpic .upic.artgalemp {display: none;}

.artcovimg{ box-sizing: border-box;  margin: 0;  min-width: 0;  position: relative;  overflow: hidden; display: block;}
.artcov {  box-sizing: border-box;  margin: 0;  min-width: 0;  width: 100%;  height: 0;  padding-bottom: 100%; display: block;}
.artcovimginset{box-sizing: border-box;  margin: 0px;  min-width: 0px;  position: absolute;  inset: 0px;   display: flex;  -moz-box-pack: center;  justify-content: center;  -moz-box-align: center;  align-items: center; text-indent: -999999px;}
.artcovimg img, .artcovimg video, .artcovimg .audio-art, .artcovimg .audio-art audio, .articon{ flex-direction: column; display: flex;  object-fit: contain;  width: 100%; position: absolute; height: 100% !important;  justify-content: center;}

.articonstatic {  position: relative;  margin-bottom: 15px;  display: inline-flex;  justify-content: center;  align-items: center;  background: #141414; border-radius: 10px 10px 0px 0px;
  overflow: hidden; width: 100%;}
.articonstatic img, .articonstatic video{ max-width: 100%; max-height: 100%; position: relative; height: auto; width: auto;}
.hexagon{ background: #141414;}
.articonstatic + .cont .usernamelilac, .articonstatic + .cont .usernamelilac p{ margin-bottom: 0px;}
.detaimg .articonstatic {border-radius: 0px; height: auto !important; background: transparent; width: auto;}

.picpro  .artcovimginset,.upic  .artcovimginset{box-sizing: border-box;  margin: 0px;  min-width: 0px;  position: absolute;  inset: 0px;   display: flex;  -moz-box-pack: center;  justify-content: center;  -moz-box-align: center;  align-items: center; text-indent: -999999px;}
.picpro img, .upic img{background: #151515; height: 100% !important; position: absolute; display: block;  object-fit: cover;  width: 100% !important;   max-width: 100%;}

.walletlist {}
.walletlist .jmCoux {  margin: 0px;  -moz-box-align: stretch; align-items: stretch;  border-width: 0px;  border-style: solid;  border-color: rgb(255, 255, 255);  display: flex;  flex-basis: auto;  flex-direction: column;  flex-shrink: 0;  min-height: 0px;  min-width: 0px;  max-width: 100%;  width: 100%;  height: 325px;  padding: 16px; }
.walletlist .hEVogm{    text-decoration: none;    color: inherit;    font-size: inherit;    line-height: inherit;    font-family: inherit;    font-weight: inherit; margin-bottom: 30px ; display: block; padding: 5px; background: #1b1b1b; border-radius: 20px;}
.walletlist .hvTeii { width: 100%;  z-index: 1;  position: relative;  border-radius: 16px;  overflow: hidden;  transform-origin: center center 0px;  transition: all 0.12s ease-in-out 0s;}
.walletlist .gwoePs {  position: absolute;  inset: 0px;    background-position: center center;  z-index: -1;  background-color: #252525;  background-size: cover;  transition: transform 0.3s ease-in-out 0s;}
.walletlist .eJAQlC {text-decoration: none;  color: white;  font-size: 18px;  line-height: 1.4; font-weight: 600; padding: 8px 15px; background: #000; border-radius: 10px;
  display: inline-block;   width: 100%; margin-bottom: 2px; }
.walletlist .hAlQae {  margin: 4px 0px 0px;  padding: 0px;  -moz-box-align: stretch;  align-items: stretch;  border-width: 0px;  border-style: solid;  border-color: rgb(255, 255, 255);  display: flex;  flex-basis: auto;  flex-direction: column;  flex-shrink: 0;  min-height: 0px;  min-width: 0px;  max-width: 100%;}
.walletlist .bJSyfY {  text-decoration: none;  font-weight: 700; white-space: pre-wrap;  color: rgba(255, 255, 255, 0.5);  line-height: 1.4; font-size: 15px;}
.walletlist .hvTeii:hover .sc-iXeIkk {transform: scale(1.1);}
.walletlist .gwoePs .hexagonloader{margin: 0px auto;  display: block;}
.walletlist .sc-bdnylx .articonstatic {height: 100% !important; margin-bottom: 0px;}

.pagenotfound{min-height: calc(100vh - 460px);}
.pnfimg{ width: 50%; margin: 0px auto;}
.Toastify__toast-body, .Toastify__toast{font-family: 'Poppins', sans-serif;}
.editcov {  position: absolute;  top: 10px;  right: 10px;  background: rgba(255,255,255,0.5);  border-radius: 10px;}
.editpro {  position: absolute;  bottom: 5px;  left: 50%;  background: rgba(255,255,255,0.5);  border-radius: 10px; margin-left: -25px; z-index: 9;}
/* .picpro .artcovimginset::before{ border: 3px solid #000; width: 100%; content: ''; height: 100%;  border-radius: 50%;  z-index: 9;} */
/* .upic .artcovimginset::before, .badgeview .artcovimginset::before{ border: 2px solid #9144B5; width: 100%; content: ''; height: 100%;  border-radius: 50%;  z-index: 9;} */
.dropdown .artcovimginset { z-index: 2;}
.purchasebtn{ width: 75%; margin: 0px auto; display: block;}
.collectdetail .btn{ font-size: 14px; padding: 0px; }
.collectdetail .btn.tab-select{ background: #000; color: #fff !important;}
.gallery.artpage { min-height: calc(100vh - 460px);}

.btn-google{ background: #4285f4; border-color: #4285f4 !important; display: flex;  justify-content: center;  align-items: center;}
.btn-google:hover{ }
.btn-icon img{ width: 26px; padding: 3px; margin-right: 10px; border-radius: 3px; position: relative; top: -2px;}
.btn-icon:hover img{filter: brightness(0) invert(1);}

input[type="checkbox"], input[type="radio"]  {display: none;}
input[type="checkbox"] + *::before, input[type="radio"] + *::before {content: ""; display: flex; justify-content: center;  align-items: center;  vertical-align: middle;  width: 1rem;  height: 1rem;  margin-right: 0.3rem;  border-radius: 10%;  flex-shrink: 0;  margin-top: 0px; transition: all ease 0.75s;}
input[type="checkbox"] + *::before{ background: url('assets/images/checkbox.png') no-repeat; background-size: cover;}
input[type="radio"] + *::before{ background: url('assets/images/radio.png') no-repeat; background-size: cover;}

input[type="checkbox"]:checked + *::before, input[type="radio"]:checked + *::before {  content: "✓";  color: #000;  text-align: center;  background: #21CFF1}
input[type="checkbox"] + *, input[type="radio"] + * {  display: flex; position: relative; font-size: 12px;align-items: center}
input[type="radio"] + *::before, input[type="radio"]:checked + *::before{border-radius: 50%;}

.claimtime{min-height:30px} 
@-webkit-keyframes uploading-arrow {
0% {margin-bottom: -40%;opacity: 0;}
20% {opacity: 1;}
50% {opacity: 1;}
75% {opacity: 0;}
100% {margin-bottom: 10%;}
}
@keyframes uploading-arrow {
0% {margin-bottom: -40%;opacity: 0;}
20% {opacity: 1;}
50% {opacity: 1;}
75% {opacity: 0;}
100% {margin-bottom: 10%;}
}

.upload-btn-container {position: relative;overflow: hidden; width: 50px;height: 50px;display: block; margin: 0px auto 15px auto;}
.upload-btn-container svg {position: absolute;}
.upload-btn-container svg.upload-box {bottom: -7px; clip-rule:evenodd;fill-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41; left: 0px;}
.upload-btn-container svg.upload-arrow {left: 13px;position: absolute;opacity: 0;bottom: 30%;-webkit-animation: uploading-arrow 1.5s ease-out infinite;      animation: uploading-arrow 1.5s ease-out infinite; clip-rule:evenodd;fill-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41}

.bwspinner { margin:0px auto; display: block; width: 10vmin; height: 10vmin; margin-bottom: 50px; position: relative;animation: wobble calc(1.5s * 2) ease-in-out infinite;transform-style: preserve-3d;}
@keyframes wobble {
0%, 100% {  transform: rotateX(15deg) rotateY(60deg);}
50% {  transform: rotateX(60deg) rotateY(-60deg) rotate(180deg);}
}
.bwspinner::before,.bwspinner::after {content: '';position: absolute;box-sizing: border-box;width: inherit;height: inherit;border: 2.5vmin solid #9144B5; border-radius: 50%;animation: spin 1.5s ease-in-out infinite reverse;}
.bwspinner::after {border-color: #ddd;animation-delay: calc(1.5s / 2 * -1);}
@keyframes spin {
0%, 100% {  transform: translateZ(5vmin) rotateX(25deg);}
33% {  transform: translateZ(-5vmin) scale(0.4);}
66% {  transform: translateZ(-5vmin);}
}

.creators .upic{ float: none; margin:-50px 15px 0px; width: 100px;  height: 100px; border: 8px solid #1B1B1B; background:#141414}
.creators .artcovimg{height: 250px;}
.creators .upic .artcovimg{ height: auto;}
.creators .upic .artcovimginset::before{ display: none;}
.creators .cardlist {padding: 5px;}
.creators .cardlist .cont h3{ font-size: 28px;}
.creators .cardlist .cont h6 {text-overflow: ellipsis; overflow: hidden;}
.creators .cardlist .userwithpic {  border-top: 1px solid #3e3e3e;  padding: 15px;}
.creators .cardlist .userwithpic h6{margin-bottom: 0px;}

.badgeview { -webkit-transition: all 0.75s cubic-bezier(0.23,1,0.32,1);  transition: all 0.75s cubic-bezier(0.23,1,0.32,1);  will-change: transform; width: auto;  display: inline-flex;  background:#1B1B1B;  border-radius: 10px;  box-shadow: 0px 10px 20px rgba(0,0,0,0.05);  padding: 11px;  position: relative;  top: -27px;}
.badgeview .picpro {float: left;width: 34px;height: 34px;border: none;margin-top: 0px;margin-right: 10px;}
.badgeview .usernameClick {font-size: 18px;margin: 0px;line-height: 30px;font-weight: 400;color: #21CFF1;}

.badgeview:hover, .purchasebox:hover{box-shadow: 0px 0px 10px rgba(145, 68, 181, 0.5);}
.sharedrop{float: right;}
.sharedrop i{ margin:0px 10px;}
.sharedrop .dropdown-menu{top: auto !important; bottom: auto; left: auto !important; right: 0%; transform: none !important;  background: #141414; padding: 0px;   box-shadow: 0px 0px 10px rgb(158 102 191 / 83%); height: min-content;}
.sharedrop .dropdown-menu.show, .sdmorelinks:hover  .dropdown-menu{ display: block; } 
.sharedrop .dropdown-toggle::after{ display: none;}
.sharedrop .dropdown-item{padding: .5rem; color: #b092ba;}
.sharedrop .dropdown-item.active, .sharedrop .dropdown-item:active, .sharedrop .dropdown-item:hover,  .sharedrop .dropdown-item:focus{ background: #262626}
.termspage{color: #9144B5;}
.termspage h4{color: #fff;}
.restpasswordh{min-height: calc(100vh - 540px);}
#react-confirm-alert .react-confirm-alert-overlay{ background: rgba(2, 2, 2, 0.8);}
#react-confirm-alert .custom-ui{ text-align: center;}
#react-confirm-alert .custom-ui h6{ margin-bottom: 30px;}

.gsCfrG, .zEDqB{border-color: #b193bb !important;}
.kHhGrJ, .kHujWZ, .ctiAfi{background-color: #b193bb !important;}
.input-group-text{background-color: #141414;  border: 1px solid #4f4f4f; border-right-color: transparent;}
.input-group:hover .input-group-text {border-color: #fff;}
.input-group .form-control{border-left:none; }
.input-group .fa{color: #fff;}
.socialshareico {text-align: right;width: 100%;display: block; margin-top: -55px; margin-bottom: 30px;}
.socialshareico a{ padding: 0px 5px; color:#a2a2a2;}
.socialshareico a:hover, .socialshareico a:focus{color:#fff;}
.usernamelilac{ color: #9144B5;}
.usernameblue{ color: #21CFF1 !important;}
.lilacbg{ background-color: #9144B5;}
.bg2f2f2f{background-color: #2f2f2f;}
/* .userdescrip{ min-height: 30px;} */
.userdescrip{ min-height: 40px;max-height: 50px;overflow: hidden;text-overflow: ellipsis;position: relative;cursor:pointer;white-space: nowrap;}
.userdescrip:hover{color: #9144B5;}

.creatorrequ {transform: translate(0) !important; margin: 0px !important; height: 100vh !important;}
.creatorrequ .custom-ui {height: auto; overflow-y: auto; max-height: 100vh; box-shadow: inset 0px 0px 65px 0 rgb(23, 23, 23), 0px 0px 10px rgb(0, 0, 0);}
  .reportpop{position: relative; top: 50%;transform: translatey(-50%);}
.ReactModal__Content .clsbtn {position: fixed;right: 0px;top: 0px;width: 40px;height: 40px !important;padding: 
  0px !important;text-align: center;display: flex;justify-content: center;align-items: center;line-height: normal !important;background: #141414;border-color: #2f2f2f !important;color: #7d7d7d !important;}
  .reportpop .clsbtn{right: 15px;top: 15px;}
.loginpopcls .clsbtn {right: 250px; z-index: 99;  top: 18px;  background: #1B1B1B;}

.dropseclist{ display: flex; flex-direction: column;}
.dropseclist .artcovimglink { width: 100%; border-radius: 10px;  overflow: hidden; margin-top: 0px; margin-bottom: 0px;}
.dropseclist .cont{ padding: 0px; flex: 1;}
.cardlist  a{ color: #fff; text-transform: capitalize; margin-top: 5px; display: inline-block;}
.cardlist a:hover{ color: #b193bb; }
.dropseclist .cont .userwithpic, .artpage .cont .userwithpic{ width: 100%;}
.dropseclist .update{ margin:0px; }
.dropseclist .cbidtxt{ margin: 15px 0px 0px 0px;}
.dropseclist .userdescrip {min-height: auto;}
.endofdate {background: #111; padding: 3px 10px; margin-top: 7px; border-radius: 6px;}

.artimglist .artimg{ border-radius: 10px; background: #141414;} 
.cardlist.artimglist{ display: flex; flex-direction: column; cursor: pointer; height: 94%;}
.cardlist.artimglist .userdescrip {min-height: auto;text-align: center;}
.artimglist .cont{ padding: 0px 0px; flex: 1;}

.dropseclist .artcovimg{ background: #141414;}

.dollorsec span{ background: #b193bb;}
.dollorsec .input-group-text{border-color: #21cff1 !important; color: #000; background: #21cff1 !important;}
.dollorsec .form-control{border-left:1px solid #4f4f4f;}
.modal-header .close { color: #fff !important;}
.show-content .purchasebox {border: 1px solid #3e3d3d;}

.dotsloading {display: flex; justify-content: center;}
.dotsloading div {  width: 1rem;  height: 1rem;  margin: 2rem 0.3rem;  background: #b193bb;  border-radius: 50%;  -webkit-animation: 0.9s bounce infinite alternate;          animation: 0.9s bounce infinite alternate;}
.dotsloading div:nth-child(2) {  -webkit-animation-delay: 0.3s;          animation-delay: 0.3s;}.dotsloading div:nth-child(3) {  -webkit-animation-delay: 0.6s;          animation-delay: 0.6s;}
@-webkit-keyframes bounce {
  to {    opacity: 0.3;    transform: translate3d(0, -1rem, 0);  }
}
@keyframes bounce {
  to {    opacity: 0.3;    transform: translate3d(0, -1rem, 0);  }
}

.success-checkmark {width: 80px;  height: 115px;  margin: 0 auto;}
.success-checkmark .check-icon {  width: 80px;  height: 80px;  position: relative;  border-radius: 50%;  box-sizing: content-box;  border: 4px solid #b193bb;}
.success-checkmark .check-icon::before {  top: 3px;  left: -2px;  width: 30px;  transform-origin: 100% 50%;  border-radius: 100px 0 0 100px;}
.success-checkmark .check-icon::after {  top: 0;  left: 30px;  width: 60px;  transform-origin: 0 50%;  border-radius: 0 100px 100px 0;  animation: rotate-circle 4.25s ease-in;}
.success-checkmark .check-icon::before, .success-checkmark .check-icon::after {  content: "";  height: 100px;  position: absolute;  background: #1B1B1B;  transform: rotate(-45deg);}
.success-checkmark .check-icon .icon-line {  height: 5px;  background-color: #b193bb;  display: block;  border-radius: 2px;  position: absolute;  z-index: 10;}
.success-checkmark .check-icon .icon-line.line-tip {  top: 46px;  left: 14px;  width: 25px;  transform: rotate(45deg);  animation: icon-line-tip 0.75s;}
.success-checkmark .check-icon .icon-line.line-long {  top: 38px;  right: 8px;  width: 47px;  transform: rotate(-45deg);  animation: icon-line-long 0.75s;}
.success-checkmark .check-icon .icon-circle {  top: -4px;  left: -4px;  z-index: 10;  width: 80px;  height: 80px;  border-radius: 50%;  position: absolute;  box-sizing: content-box;  border: 4px solid rgba(177, 147, 187, 0.5)}
.success-checkmark .check-icon .icon-fix {  top: 8px;  width: 5px;  left: 26px;  z-index: 1;  height: 85px;  position: absolute;  transform: rotate(-45deg);  background-color: #1B1B1B;}
@keyframes rotate-circle {
0% {transform: rotate(-45deg);}
5% {transform: rotate(-45deg);}
12% {transform: rotate(-405deg);}
100% {transform: rotate(-405deg);}
}
@keyframes icon-line-tip {
0% {width: 0;left: 1px;top: 19px;}
54% {width: 0;left: 1px;top: 19px;}
70% {width: 50px;left: -8px;top: 37px;}
84% {width: 17px;left: 21px;top: 48px;}
100% {width: 25px;left: 14px;top: 45px;}
}
@keyframes icon-line-long {
0% {width: 0;right: 46px;top: 54px;}
65% {width: 0;right: 46px;top: 54px;}
84% {width: 55px;right: 0px;top: 35px;}
100% {width: 47px;right: 8px;top: 38px;}
}


svg.crosscus {width: 100px; display: block; margin: 40px auto 0;}
.path {stroke-dasharray: 1000;  stroke-dashoffset: 0;}
.path.circle {  -webkit-animation: dash 0.9s ease-in-out;  animation: dash 0.9s ease-in-out; stroke: #b193bb;}
.path.line {  stroke-dashoffset: 1000;  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;  animation: dash 0.9s 0.35s ease-in-out forwards; stroke: #b193bb;}
.path.check {  stroke-dashoffset: -100;  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;  animation: dash-check 0.9s 0.35s ease-in-out forwards;}

@-webkit-keyframes dash {
  0% {    stroke-dashoffset: 1000;  }
  100% {    stroke-dashoffset: 0;  }
}
@keyframes dash {
  0% {    stroke-dashoffset: 1000;  }
  100% {    stroke-dashoffset: 0;  }
}
@-webkit-keyframes dash-check {
  0% {    stroke-dashoffset: -100;  }
  100% {    stroke-dashoffset: 900;  }
}
@keyframes dash-check {
  0% {    stroke-dashoffset: -100;  }
  100% {    stroke-dashoffset: 900;  }
}

.nav-item.propic .dropdown-content{ text-transform: uppercase; } 
.nav-item.propic .dropdown-content a, .dropdown-content a{ font-size: 14px; padding: 10px 15px;}
.nav-item.propic .dropdown-content a.btn-primary, .designLink.btn-primary{ background: #21CFF1; color: #000 !important; padding: 5px 10px; border:1px solid #21CFF1 !important; margin: 5px; display: inline-flex;
  align-items: center; justify-content: center;}
  .nav-item.propic .dropdown-content a.btn-primary:hover, .designLink.btn-primary:hover{ background: transparent; color: #fff !important; border-color: #fff !important;}
.nav-item.propic .dropdown-content a.btn-primary img , .designLink.btn-primary img{  width: 26px;  border: none;  margin-right: 8px; filter: brightness(0) invert(1);}
.nav-item.propic h6 {text-overflow: ellipsis;width: 140px; overflow: hidden; white-space: nowrap; font-size: 14px;  font-weight: 400;}
.nav-item.propic .dropdown-content .socialtopicons{ display: none;}
.nav-item.propic .dropdown-content a.btn-primary:hover img, .designLink.btn-primary:hover img{filter: none}

.alert-custom{position:relative;width: 100%; top:0px; padding: 0px;  line-height: 44px; margin: 0px; height: 0px; overflow: hidden; border: none;  z-index: 1;}
.alertheight{ height: 44px;}
.artmsg{ flex: 1; padding: 0px 15px;}
.alertcommon .alert-custom, .mobilesearch + .alert-custom{top: 0px;}

/* .img-placeholder{ background-image: url(assets/images/img-placeholder.gif); background-repeat: no-repeat; background-position: center; background-color: #e4efeb;} */
.coverimg.imgcovergif::before{ background-image: url(assets/images/cover-detail-loader.gif); background-repeat: no-repeat; background-position: center; background-size: 65px; background-color: black; content:''; position: absolute; top:0; left: 0; width: 100%; height: 100%; filter: grayscale(1); opacity:0.3; }
/* .imgcovergif{ background-image: url(assets/images/cover-detail-loader.gif); background-repeat: no-repeat; background-position: center; background-size: 75px; background-color: black;} */
/* .imgcovergif-creat{ background-image: url(assets/images/ajax-loader.gif); background-repeat: no-repeat; background-position: center; background-color: #141414; background-size: 150px;} */
.imgcovergif-creat-g{ background-image: url(assets/images/ajax-loader.gif); background-repeat: no-repeat; background-position: center; background-color: #141414; background-size: 150px; opacity:0.3 !important; filter: grayscale(1);}

.smallarts .artcovimg::before, .creators .artcovimginset.imgcovergif-creat::before{content:''; position: absolute; top:0; left: 0; width: 100%; height: 100%; background-image: url(assets/images/ajax-loader.gif); background-repeat: no-repeat; background-position: center; background-color: #141414; background-size: 150px; filter: grayscale(1); opacity:0.3}

.creators .artcovimginset.img-placeholder, .progallists .img-placeholder {border-radius: 10px;}
.sizecls { margin-top: -60px; margin-bottom: 25px;}
.walletlist .gwoePs img {width: 100%;  height: 100%;  object-fit: cover;}
.detaimg, .detaimg .audio-art{ width: 100%; display: block;}
.builtwallet li{ margin-bottom: 10px;}
.onflowicon{width: 24px; position: relative; top: -2px; margin:0px 1px; filter: brightness(0) invert(1); opacity: 0.6;}
.builtwallet li:hover .onflowicon{filter: brightness(1) invert(0); opacity: 1;}
.moonpay{ width: 85px;}
.audio-art{position: relative; min-height: 100%;}
.audio-art audio{position: absolute; left: 0; bottom: 20px; background-color: transparent; width: 100%;}

.graybgbox{background-color: #101010;}
.comboxgray{background-color: #171717; border-radius: 10px; padding: 15px; min-height: 56px;}
.comboxgray .badge, .comboxgray1 .badge {font-size: 14px;font-weight: normal; white-space: normal; word-break: break-word;  text-align: left;}
.mobtoggle, .mobview, .sidebar .mobview{ display: none;}
.reglogfrm{ width: 400px; margin: 0px auto; display: block;}


.shareout{ transition: all ease 0.75s;}
.share_overlaybg{ background: rgba(0, 0, 0, 0.8); position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 99; display: flex; justify-content: center; align-items: center;}
.sharebox{ background: #141414; width: 360px;  border-radius: 20px; position: relative; }
#videoplay  .sharebox{ width: 50%;}
.sharebox .close{font-size: 14px; font-family: Verdana, Geneva, Tahoma, sans-serif; cursor: pointer; width: 30px; height: 30px; text-align: center; line-height: 30px; position: absolute; right: 15px; top: 13px; z-index: 9; background: rgba(0, 0, 0, 0.8); color: #fff; }
.sharebox h3{ font-size: 18px; letter-spacing: 0.5px;  padding: 20px 20px 15px; margin-bottom: 0px;}
.shareicons{ padding: 30px 0px; display: block;  padding: 15px; float: left; width: 100%; display: flex;  flex-wrap: wrap;}
.shareicons a, .shareicons button {float: left; text-align: center; width: 80px; height: inherit; color: #fff; display: flex; justify-content: center; flex-direction: column; align-items: center; border-radius: 10px; transition: all ease 0.75s;}
.shareicons p{ text-transform: none; font-size: 12px;}
.shareicons a img, .shareicons button svg, .shareicons button img{ width: 48px; height: auto;     padding-top: 18px;}
.shareicons a:hover, .shareicons button:hover { background: #222 !important}
.imgpop {width: auto;}

.equalheight .col-md-4, .equalheight .col-md-8{ display: flex;}
.equalheight .purchasebox{display: flex;  flex-direction: column;  max-height: inherit; width: 100%;}

.likebtn {padding: 11px 16px;font-size: 20px;margin-right: 5px; cursor: pointer;}
.likebtn i {margin: 0px;}
.followboxcon{ width: 100%; display:inline-flex; flex-direction:row; margin-bottom: 30px; margin-right: 10px; justify-content: flex-end;}
.followboxcon .followbox { justify-content: center; display: flex; flex-direction: column;  align-items: center; cursor: pointer;}
.followboxcon .followbox p, .followboxcon .followbox h4{ margin: 0px;} 
.followboxcon .followbox h4, .likebtn.liked i{  color: #9144B5;}
.mw-200{ max-width: 200px;}

.cookie-banner {-webkit-box-align:center;-ms-flex-align:center;--tw-bg-opacity:1;align-items:center;background-color:rgba(255,255,255,1);border-radius:4px;bottom:20px;-webkit-box-shadow:0 12px 36px rgba(51,51,51,.2);box-shadow:0 12px 36px rgba(51,51,51,.2);left:20px;padding:16px;position:fixed;right:20px;z-index:9; display: -webkit-box; display: -ms-flexbox;   display: flex; }
.cookie-content{font-weight: 500; color: rgba(51,51,51,1); font-weight: 500; font-size: 14px;}
.cookie-content a{ color: rgb(158, 102, 191);}
.cookie-content a:hover{ text-decoration: underline;}
.cookie-btn{display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center;  -ms-flex-align: center;   align-items: center; }
.gotit{background-color: rgb(158, 102, 191);border-color: transparent;border-radius: 4px;border-width: 1px;color: #fff;display: inline-block;font-size: 12px;font-weight: 700;height: 30px;line-height: 1.5;padding-bottom: 5px;padding-left: 16px;padding-right: 16px;padding-top: 5px;text-decoration: none;-webkit-transition: all .25s ease-in-out;-o-transition: all .25s ease-in-out;transition: all .25s ease-in-out;-webkit-user-select: none;-moz-user-select: one;-ms-user-select: none;user-select: none;white-space: nowrap; margin-left: 48px; box-shadow: 0 4px 12px rgba(158,102,191,.4);  text-shadow: 0 1px 1px rgba(63,58,64,.2); font-size: 12px;}
.flex1{-webkit-box-flex: 1; -ms-flex: 1 1 0%; flex: 1 1 0%;}
.btn-icon-only {background-color: transparent; border-width: 0; color: #666; height: auto !important;  line-height: 1.5;   padding: 0; margin-left: 16px !important;}
.btn-icon-only figure {margin: 0px;}

.nav-item.propic .dropdown{display: flex;}
.nav-item.propic .dropdown .upic {width: 40px;height: 40px; border: 2px solid #9144B5;}
/* .dropdown .artcovimg .artcovimginset { border: 2px solid#9144B5;} */
.dropdown .artcovimg .artcovimginset img{border:none;  width: auto !important;height: auto !important;min-height: 100%;}
.toggleicon{ border: 2px solid #fff; border-radius: 6px; width: 40px; height: 40px; font-size: 30px; text-align: center; display: flex; justify-content: center; align-items: center; margin-left: 10px; cursor: pointer; flex-direction: column;}
.toggleicon .bars{ width: 28px; height: 2px; background: #fff; margin-bottom: 6px; border-radius: 30px;}
.toggleicon .bars:last-child{margin: 0px;}
/* .toggleicon:hover{border-color: #9144B5; color: #9144B5;}
.toggleicon:hover .bars{ background: #9144B5;} */
.pagetitabtn{ padding-top: 1rem;}
.detailcontpa .dettittxt h2{word-wrap: anywhere; margin-bottom: 0px;}

.propicdetail {padding: 10px; top: -50px; margin-bottom: 25px; display: inline-flex;}
.ccdetailbox .badgeview.propicdetail{margin-top: -50px; top: 0px;}
.propicdetail .picpro{ width: 75px; height: 75px}
.propicdetail h2{ padding: 0px 15px 0px 5px; }
.flowtop h6 { display: none;}
.infocontent{ justify-content: center;}
.infocontent .counttxt, .infocontent .infotxt{ background: transparent; color: #fff; border: none; padding: 0px; }
.infocontent .counttxt{ margin-right: 10px;}
.infocontent .form-control{ border-radius: 10px !important; border: 1px solid #fff; max-width: 100px;}
.infocontent .infotxt{ position: relative; width: 25px; height: 25px; border: 1px solid #fff; justify-content: center; line-height: 25px; border-radius: 50%;  margin-left: 10px; padding: 0px; margin-top: 5px;}
.infocontent .infotxt .infospantxt { transition: all ease 0.75s; opacity: 0; position: absolute;top: -35px; left: -160px; background: #9144B5; color: #000; font-size: 12px; padding: 5px; border-radius: 6px;  line-height: normal;  font-weight: normal;}
.infocontent .infotxt .infospantxt::after {content: '';position: absolute; right: 13px; bottom: -15px; border-top: 8px solid #9144B5; border-right: 8px solid transparent; border-left: 8px solid transparent;  border-bottom: 8px solid transparent;}
.infocontent .infotxt:hover .infospantxt{ opacity: 1;}

.detasingimg{ width: 100%; padding: 50px 0px 60px ; background: #000; position: relative; transition: all ease 0.75s; overflow: hidden;}
.detasingimg::before {content: "";display: block;position: absolute; pointer-events: none; bottom: 0; left: 0; right: 0; height: 4rem; background: linear-gradient(0deg,rgb(26, 26, 26) 0%,rgba(230,230,230,0) 100%);}
.detabadge{ top: 0px; margin: 15px 0px;}
.detasingimg .articon{ position: relative;   width: auto !important;  max-width: fit-content;  margin: 0px auto;}
/* .detasingimg .articon .articoni{ display: none;} */
.detailMyWallet .articon .articoni{ display: flex;}
 
.nav-item h6.text-center.mb-1 {display: none;}
.nav-item.propic h6.text-center.mb-1 {display: block;color:#9144B5; text-transform: none;}

.hexagonloader {object-fit: contain !important; opacity:0.3 !important; filter: grayscale(1); max-width: 175px !important;}
.threedotsloader {object-fit: contain !important; width: 200px !important;}
.morelist .artcovimginset .hexagonloader {height: auto !important;}

.artimglist .btn.btn-primary{border-color: #3b313e !important;}
.artimglist .btn.btn-primary:hover{border-color: #9144B5 !important}
.small, small{ letter-spacing: 0.5px;}

.articoni {position: absolute; z-index: 1; width: 36px; height: 36px; top: 0px; right: 0px; color: white; text-align: center; display: flex; justify-content: center; align-items: center; text-indent: 0px;}
.articoni.permium {right: auto; left: 10px; top: 10px; width: 20px; height: 20px;}
.articoni.permium img{ width: 100%;}
.becomeacreator{ max-height: calc(100vh - 235px); overflow-y: auto; width: 100%; box-sizing: border-box; padding: 0px 15px;}
.fundWallet{ width: 100%; box-sizing: border-box; background-color: #fff;border-radius: 10px; position: relative; margin-bottom: 30px;}
.fundWallet iframe { border-radius: 10px;}
.fundWallet .clsbtn{ position: absolute; right: -8px; top: -2px; width: 25px;  height: 25px !important; padding: 0px !important; line-height: normal !important;}
/* .ReactModal__Body--open{overflow: hidden;}
.ReactModal__Body--open .ReactModal__Overlay--after-open{ overflow-y: auto;} */
.fundiframe{border: none; min-height:98vh; max-height: 100vh; height: 32rem}

.tooltip-inner {letter-spacing: 1px;}
.max-h-screen {max-height: 100vh;}
.h-128 {height: 40rem;}

.counttimebox .counttime{ width: 60px; float: left;}
.counttime .small{ display: block; width: 100%; font-size: 14px;}
.timerline{ position: relative;}
.timerline::after{ content: ''; width: 1px; height: 100%; position: absolute; right: 30px; background: #444; top: 0px;}

.auto-suggest{background-color:#1B1B1B;position:relative;display:inline-block; margin: 0px 25px;}
.auto-suggest:after{font-family:FontAwesome;content:'\f002';position:absolute; color: #21CFF1; right: 0; top: 0px; width: 50px; height: 100%;  display: inline-flex;  justify-content: center;  align-items: center;z-index: 1; }
.auto-suggest.icon-hidden:after{display:none}.no-suggestions{padding:.5rem}
.suggestions{border-top-width:0;list-style:none;margin-top:0;max-height:34em;overflow-y:auto;padding-left:0}
.no-suggestions,.suggestions{position:absolute;z-index:99;top:100%;left:0;right:0;background-color:#1B1B1B;width:100%;border:1px solid #3b214b; border-radius: 6px; color:#fff;text-align:left; box-shadow: 0px 2px 5px rgba(158, 102, 191, 0.3);}
.suggestions li{display:block!important;word-wrap:break-word}
.suggestions li a{padding:.5rem;}
.sugesstion-active a,.suggestions a:hover{cursor:pointer}
.suggestions li:not(:last-of-type){border-bottom:1px solid #3b214b}
.suggestions li:nth-child(2n) {background: #171717;}
.suggestions a{ display: block; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;} 
.suggestion-active,.suggestion-active a,.suggestions a:hover, .mandatory-req + .input-group .suggestions li:hover, .auto-suggest .suggestions li:hover{color: #FFF; background: #9144B5;}
.auto-suggest #globalSearchInput:focus{ border-color: #9144B5;}

.custom-file{ position: relative;}
.custom-file .custom-file-input{ position: absolute; height: 48px; z-index: -1}
.smallilac a{ font-size: 14px;}

.dropdown-content .navbar-brand, .flowboxmob, .mobilesearch{ display: none;}
.flowboxmob a::before{display: none;}
.dicoverarts{ padding: 50px 0px;}
.bigart{ margin-bottom: 75px;}
.bigart .col-md-5 {padding-left: 50px; display: none;}
.bigart .bigartimg{ display: inline-flex; width: 100%; position: relative; background:#141414; justify-content: center;  align-items: center;}
.bigartimg .articonstatic { height: auto !important; border-radius: 0px; margin: 0px;}
/* .bigartimg .artcovimg {min-height: 350px;} */
.bigartimg .articon {position: relative;}
.bigartimg img{ max-width: 100%; width: 100% !important; margin: 0px auto; display: block;}
.bigartimg:hover{box-shadow:0px 0px 20px rgba(221, 178, 236, 0.5);}
.dicoverarts .badgeview {top: 0; padding: 7px 14px 7px 7px; display: inline-flex;  flex-direction: row;  justify-content: center;  align-items: center;}
.bigart .badgeview .usernameClick{ line-height: normal;}
.bigarttit{ font-size: 35px; margin-bottom: 10px; line-height: 60px;cursor: pointer;}
.bigarttit a{color: #fff;}
.bigarttit a:hover{color: #9144B5;}
.spottit:hover{color: #9144B5;}
.bidown{ position: relative;}
.bidown::after{ position: absolute; content: ''; top: 0px; left: 45%; width: 1px; height: 100%; background: #373737;}
.bidown h3{ font-size: 40px; margin-bottom: 5px;}
.bidown h6{ font-size: 18px;}
.bigart .btn{ padding: 0px 35px;}
.bigartimg .bigarthovertxt{ position: absolute; top: -30px; left: 0px; padding: 15px; opacity: 0; transition: all ease 0.5s; background: rgba(0, 0, 0, 0.8); width: 100%;}
.bigartimg:hover .bigarthovertxt{ opacity: 1; top: 0px;}

.mediumart, .smallarts{ margin-bottom: 30px;}
.mediumart h2, .smallarts h2{ font-size: 40px;}  
.mediumart h2 a, .smallarts h2 a{ font-size: 20px;}
.mediumart hr, .smallarts hr{border-color: #373737; margin-bottom: 30px;}
.mediumart .cardlist, .smallarts .cardlist { padding: 0px;}
.mediumart .cardlist .artcovimglink {display: inline-flex; width: 100%; justify-content: center;  align-items: center; background: #141414;}
.mediumart .cardlist, .mediumart .artcovimg img, .smallarts .cardlist, .smallarts .artcovimg img{ border-radius: 10px;}
.smallarts .artcovimg img, .mediumart .artcovimg img{border-radius: 0px;}
.mediumart h3, .mediumart h3.usernamelilac{ font-size: 25px; font-weight: 400;}
.smallarts h4{ font-size: 20px; font-weight: 400;}
.mediumart .articonstatic {max-height: 500px; min-height: 500px;}

.mediumart .artcovimg{ margin: 20px;}
.smallarts .cardlist  { padding: 15px; align-items: flex-start;}
.smallarts .cardlist .artcovimglink { width: 100%;}
.smallarts .artcovimg{ margin-bottom: 15px; background:#141414; border-radius: 10px;}
.mediumart .picpro .artcovimg, .smallarts .picpro .artcovimg{ margin: 0px;}
.mediumcon {padding: 0px 20px 20px; min-height: 65px;}
.ownedby{ padding:10px 20px; background: #141414;}
.smallarts .cardlist .badgeview{ background: #141414;}
.viewcollection{ position: absolute; top: 0px; left: 0px; opacity: 0; background: rgba(26, 26, 26, 0.9); width: 100%; height: 100%; display: inline-flex !important; justify-content: center; align-items: center; color: #9144B5 !important; font-size: 18px; margin: 0px !important;}
.smallarts .cardlist:hover .viewcollection{ opacity: 1;}
.smallarts .artcovimginset {border-radius: 10px;}

.smallarts .cardlist .ccimgbox {height: 320px; margin-bottom: 15px;}
.smallarts .cardlist .ccimgbox img.comimg{max-height: 100%; max-width: 100%; width: auto;}

.mediumart video{ border-radius: 10px;}

.auto-suggest #globalSearchInput {width: 400px; padding-right: 50px; border: #F3F3F3A3 2px solid;  border-radius: 5px; min-height: 50px; font-size: 15px; background: #000;}
.drag-drop-container{background: #1B1B1B;border: 1px solid #333;padding: 1rem;margin-top: 1rem;}
.dragging{border: 1px solid #9144B5;color: #9144B5;}
.draggable-item.dragging{opacity: 0.5;}
.drag-drop-container .loading img{height: 100px;width: 100px;margin: 0 auto;display: block;}

.auto-suggest #globalSearchInput::-webkit-input-placeholder { /* Edge */  color: #999;}
.auto-suggest #globalSearchInput:-ms-input-placeholder { /* Internet Explorer 10-11 */  color: #999;}
.auto-suggest #globalSearchInput::placeholder {  color: #999;}


.dropseclist .badgeview {top: 0px; margin-bottom: 15px;}
.dropseclist .badgeview h2.usernameClick{ font-size: 15px; max-width: 100px;  overflow: hidden;  text-overflow: ellipsis; justify-content: flex-start; display: inline-block;}
.adminimgprev{ width: 600px;}
.adminimgprev .custom-ui{padding: 40px;}
.adminimgprev .custom-ui img{width: 100%;}

.psocial{padding: 0px;width: 50px;height: 50px;text-align: center;display: inline-flex;justify-content: center;align-items: center;cursor: pointer;margin: 0px 10px 0px 0px;font-size: 20px;position: relative;top: 8px; border-radius: 10px;}
.psocial:hover{color: #000; background: #9144B5;}

.maintenancebg, .maintenancebgcon{ width: 100%; color: #fff; display: flex; justify-content: center; flex-direction: column; align-items: center; min-height: 100vh;}
.discovernav .disnowmenu {  padding: 0px;  border-bottom:1px solid #222 !important;  background: #171717;  box-shadow: 0px 0px 10px rgba(0,0,0,0.3);  position: relative;  z-index: 1;}
.discovernav .disnowmenu .navbar-nav .nav-link {padding-left: 0px !important; padding-right: 30px !important;}
.discovernav .disnowmenu .navbar-nav .nav-link::before{display: none;}
.discovernav .navbar-nav {flex-direction: row; width: 100%; margin: 0px !important;}
.discovernav .disnowmenu .collapse:not(.show){ margin-top: 0px;}
.fund-wallet-container .fwcon .onflowicon{ opacity: 1; filter: none; width: 64px;  margin: 0px 10px;}
.discovernav .disnowmenu .navbar-nav .nav-link:hover, .discovernav .disnowmenu .navbar-nav .nav-link:focus, .discovernav .disnowmenu .navbar-nav .nav-link.active{ color: #9144B5 !important;}

.fund-wallet-container .fwcon {height: auto !important; line-height: 30px !important; padding: 15px; min-height: 310px;}
.fund-wallet-container .fwcon span.text-white {display: block; margin: 0px;}
.fund-wallet-container .fwcon:hover span{color: #000;}
.cbbg{ display: flex; justify-content: center; align-items: center; flex-direction: column;}
.cbbgimg{ flex:1; display: flex; align-items: center;}
.cbbgbtn{ height: 50px;}
.cbbg:hover img{ filter: brightness(0) invert(1);}
.fwconh{ height: 225px; display: flex; flex-direction: column; flex: 1;}
.cusbtns{height: auto !important; line-height: normal !important; padding: 15px; min-height: 165px; display: inline-flex;  align-items: center;  justify-content: center;  flex-direction: column;}
.cusbtns img{max-width: 100%; max-height: 100%; }
.cusbtns:hover img{filter: brightness(0) invert(1);}
.fund-wallet-container .fwcon:hover .text-white{ color: #000 !important;}
.fund-wallet-container .fwcon:hover .btn-primary{ color: rgb(255, 255, 255) !important;border-color: white;}
.fund-wallet-container .fwcon:hover .btn-primary:hover{ color: rgb(255, 255, 255) !important;background-color: rgb(0, 0, 0);border-color: white;}


.lftsdbar{margin-right: 10px; width: 195px;}
.ccdetailbox .propicdetail{width: 195px;}
.ccdetailbox .propicdetail:hover {background: #9144B5;}
.ccdetailbox .propicdetail .picpro {margin: 0px;  width: 175px;  height: 175px;  border-radius: 10px;}
.ccdetailbox .propicdetail .picpro img{border-radius: 0px;}
.protittxt{ flex-direction: column; padding: 15px 20px; width: 100%; top: 0px;}
.protittxt .btn{ height: auto !important; line-height: normal !important; padding: 5px 10px !important; font-size: 16px;text-transform: capitalize;font-weight: 500;}
.badgeview.protittxt h3 img { width: 18px; float: left; margin-right: 10px; margin-top: 10px;}
.useredit{ display: inline-flex;width: 100%;}
.useredit h2{ margin-right: 15px !important;}
.badgeview .useredit .usernameClick:hover{color: #9144B5 !important}
.ccdetailbox .sharedrop{ top: 0px;}
.ccdetailbox .sharedrop .usernameClick{ font-size: 16px;}
.ccdetailbox .followboxcon .followbox {margin: 15px 0px 0px 0px;padding: 5px 15px;background: black;border-radius: 15px;}
.ccdetailbox .followboxcon .followbox:last-child {margin-left: 15px;}

.prosoclinks{ display: flex; flex-direction: row; justify-content: flex-end;}
.prosoclinks .psocial{ top: 0px; width: 38px; height: 38px; background: #000000; color: #21CFF1; margin-right: 0px;}
.prosoclinks a{background: transparent; }
.prosoclinks a:not(:first-child), .prosoclinks .dropdown{ margin-left: 15px;}
.prosoclinks a:hover{color: #9144B5;}
.prosoclinks a:hover .psocial{ color: #000; background: #9144B5;}
.prosoclinks .flowtop .copycon{ top: 40px; bottom: auto;}
.joindedate{ margin:15px 0px 0px; font-size: 14px;}
.joindedate:hover{ color: #9144B5;}
.weblinks a{ width: 100%; display: inline-block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 100%;}
.weblinklists{ position: relative; padding-left: 25px;}
.weblinklists i{ position: absolute; top: 4px; left: 0;}
.weblinklists a:hover{ color: #9144B5;}
.dividerhr{background: #1B1B1B; width: 100%; border: none; height: 1px;}
.biodeta { margin-top: -50px !important;}
.cctitpro .biodeta{ padding-left: 50px;}
.profilesec .purchasebox { height: calc(1.5em + .75rem + 2px);  padding: .375rem .75rem !important;  font-size: 1rem;  font-weight: 400;  line-height: 1.5;  border: 1px solid #1B1B1B;  border-radius: 10px;}
.profilesec  .form-group label {text-transform: none;}
.biocont{ margin-top: 25px; font-size: 18px;}
.biodeta h3 {font-size: 32px;font-weight: 400;}

.showless {width:100%; position:relative; padding-bottom: 20px;}
.showless .content {width:100%; display: inline-grid;}
.showless .content .showlesscontent {position:relative; overflow: hidden; max-height:60px; font-size: 18px; line-height: 26px;}
.showless .content .showlesscontent:after{ content:""; height: 30px; bottom: 0;   left: 0; position: absolute;   width: 100%;background: -moz-linear-gradient(to bottom, rgba(241,241,241,0) 0%,#1b1b1b 100%); background: -webkit-linear-gradient(to bottom, rgba(241,241,241,0) 0%,#1b1b1b 100%); background: linear-gradient(to bottom, rgba(241,241,241,0) 0%,#1b1b1b 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f1f1f1', endColorstr='#f1f1f1',GradientType=0 );}
 .showless input {display: none; opacity:0;}
.showless label{ position:absolute; bottom:0px; right:0px; display:inline-block; color: #9144B5; cursor: pointer; line-height: normal; padding:0px; font-size:14px; transition:all ease 0.75s; z-index:9; margin: 0px;}
.showless label h2{font-size: 15px; line-height: normal;  margin: 0;  padding: 0;}
.showless input:checked + label {background: #333333; color: #fff; }
.showless .showless1{ display: block;}
.showless .showless2{ display: none;}
.showless #showless1:checked ~ .content #showless1{ max-height: 3500px; word-wrap: break-word;}
.showless #showless1:checked ~ .showless2{ display:block;}
.showless #showless1:checked ~ .showless1{ display:none;} 
.showless #showless1:checked ~ .content .showlesscontent:after{ display:none;}
.showless #showless2:checked ~ .content #showless1{max-height: 60px; overflow:hidden; -webkit-transition: max-height 0.5s; -moz-transition: max-height 0.5s; -ms-transition: max-height 0.5s; -o-transition: max-height 0.5s; transition: max-height 0.5s; }
.showless #showless2:checked ~ .showless2{ display:none;}
.showless #showless2:checked ~ .showless1{ display:block;} 
.showless #showless2:checked ~ .content .showlesscontent:after{ display:block;} 
.showless .showless1::before, .showless .showless2::before { display: none;}

.custable{border: 1px solid #1B1B1B;}
.custable th{ background: #1B1B1B; border-bottom: 1px solid #1B1B1B;  border-top: 1px solid #1B1B1B; color: #9144B5; font-size: 14px;  font-weight: normal;}
.custable td{ background: #1c1c1c; border-bottom: 1px solid #1B1B1B;}
.adtable .overtxt{ display: none; color: #9144B5;}

.airmailtxt {overflow: hidden; text-overflow: ellipsis;white-space: nowrap;display: inline-block; max-width: 130px;}

.biodetadesk{ width: 100%;}
.biodetamob{width: 100%; display: none !important;}

.aecls{ margin-top: 15px; padding-bottom: 0px;}
.select2-container{width: 150px !important;  text-align: left;}
.select2-container--default .select2-selection--single{ background: transparent;}
.select2-container--default .select2-selection--single .select2-selection__rendered { color: #fff; line-height: 35px;}
.select2-dropdown{background: #141414;}
.select2-dropdown{border: 1px solid #464646;}
.select2-container--default .select2-search--dropdown .select2-search__field {border: 1px solid #484848; background: #141414; color: #fff;}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable, .select2-container--default .select2-results__option--selected {  background-color: #9144B5;}
.select2-container .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__arrow{ height: 35px;}

/* .purchasebox.activitybox h5{ text-align: left;}  */
.activitysec{ padding: 15px 0px;}
.activitysec .activitybox{ padding: 10px 15px; margin-bottom: 2px; border-radius: 6px;}
.activitysec .activitybox:nth-child(even){background: #1c1b1b;}
.activitysec .activitybox:hover{background: #9144B5;}
.activitysec .activitybox .row { align-items: center;}

.activitysec .nav-sidebar {display: inline-flex; flex-direction: column; min-height: 100%; width: 100%;}
.activitysec .nav-sidebar .nav.tabs { flex: 1; display: flex; flex-direction: column;}
.activitysec .nav-sidebar .tabs li{ margin-bottom: 1px;}
.activitysec .nav-sidebar .tabs li, .activitysec .nav-sidebar .tabs li a{ width: 100%; display: flex; align-items: center; height: 40px; }
.activitysec .nav-sidebar .tabs li a.active, .activitysec .nav-sidebar .tabs li a:hover{background: #9144B5; padding-left: 15px; color: #000;}
.activitysec .activitybox .col-md-6{max-width: 50%; flex: 0 0 50%;}
.activitysec .activitybox .col-md-6.text-right{text-align: right !important;}
.tabActive {background: #9144B5; padding-left: 15px; color: #000;}
.activitybox .usernamelilac.small{ font-size: 16px;}

.content-not-shift .artcovimg {width: 50px;}

.disnowmenu .socialtopicons.navbar-nav .nav-link{ padding: 10px !important; display: inline-flex; justify-content: center;}
.disnowmenu .socialtopicons.navbar-nav .nav-link::before{display: none;}
.disnowmenu .socialtopicons.navbar-nav .nav-link i{font-size: 18px;}
.disnowmenu .socialtopicons.navbar-nav .nav-link:hover i{color: #9144B5;}

.brdnft{border-left: 1px solid #9144B5; padding-left: 30px;}
.changefiletxt{display: none;}
.nftbtns .btn-primary-fill{ font-size: 14px; letter-spacing: 1px; overflow: hidden;}
.progress-btn{position: relative; transition: all 0.4s ease;}
.progress-btn:not(.active) {cursor: pointer;}
.progress-btn .progress {	width: 0%;	z-index: 5;	background: #21cff1;	opacity: 0;	transition: all 0.3s ease;}
.progress-btn.active .progress {opacity: 1;	/*animation: progress-anim 10s ease 0s;*/}
.progress-btn[data-progress-style='indefinite'].active .progress {animation: progress-indefinite-anim 1s infinite linear 0s;}
.progress-btn[data-progress-style='fill-bottom'].active .progress, .progress-btn[data-progress-style='fill-top'].active .progress {	height: 5px;}
.progress-btn[data-progress-style='fill-bottom'] .progress {position: absolute;	left: 0;	right: 0;	bottom: 0;	height: 0;}
@keyframes progress-anim {
	0% {width: 0%;}
	5% {width: 0%;}
	10% {width: 15%;}
	30% {width: 40%;}
	50% {width: 55%;}
	80% {width: 100%;  opacity: 1;}
	95% {width: 100%; opacity: 0.5;}
	100% {width: 100%;  opacity: 0;}
}
.morefromarts .articonstatic{max-height: 250px; height: auto !important;     min-height: 250px;}
.morefromarts .articonstatic img, .morefromarts .articonstatic video {position: absolute;}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span, .alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active { background: #9144B5 !important;}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev{ font-size: 25px; opacity: 0.5;}
.owl-theme .owl-nav [class*="owl-"]:hover{background: transparent; opacity: 1;}
.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item{color: #8e8e8e;}
.alice-carousel__prev-btn-item:hover, .alice-carousel__next-btn-item:hover { color: white;}

.dv2{ background: #fff;}
.d-slider .carousel-item {max-height: 700px; min-height: 700px; }
.ds-img {-webkit-background-size: cover;  background-size: cover; position: absolute;  background-position: 50%;  background-repeat: no-repeat;  min-width: 100%;  min-height: 100%; animation: zoom 30s infinite;}
.ds-overlay::before{content: ''; position: absolute; min-width: 100%;  min-height: 100%; background-color: rgba(0,0,0,0.5); }
.ds-inner-con{padding: 0px 60px; text-align: left; position: absolute; display: flex; align-items: center; height: 100%; width: 100%; justify-content: center; padding-top: 150px;}
.d-slider .carousel-control-next, .d-slider .carousel-control-prev{ width: 35px; height: 35px; background: #fff; border-radius: 50%; opacity: 1; color: #311847;  font-size: 25px; top: 50%; margin-top: -17.5px;}
.d-slider .carousel-control-next{left: auto; right: 10px;}
.d-slider .carousel-control-prev{left: 10px;}
.ds-button .btn{font-weight: 400; padding: 15px 15px 15px 15px; height: auto !important;  line-height: normal !important; font-size: 15px;}
.ds-button .btn:hover{transform: none;  color: #000 !important;}
.ds-button .btn-primary-fill:hover , .ds-button .btn-primary-fill:focus{background: transparent; color: #9144B5 !important;}

.ds-button .btn-primary {background: #311847; border-color: #311847 !important; color: #fff !important;}
.ds-button .btn-primary:hover, .ds-button .btn-primary:focus {background: transparent; color: #311847 !important;}

.dark-purple{ background-color: #311847; padding: 100px 0px; position: relative; z-index: 1;}
.dark-purple h2{ font-size: 2.6666666666667rem;}
.dark-purple h3{ font-size: 32px; font-weight: 600; margin-bottom: 20px;}
.dark-purple p{font-size: 18px; font-weight: 400; margin-bottom: calc(1.6em + 20px); line-height: 1.85714285714286;}
.watdcon{padding: 90px 0px 0px 50px;}
.overflow-visible{overflow: visible;}
.rdtOpen .rdtPicker {background: #141414; border-color: #333;}
.rdtPicker thead tr:first-of-type th:hover {background: #1B1B1B !important;}

.rdtPicker tfoot { border-top: 1px solid #343a40 !important;}
.rdtPicker th {
  border-bottom: 1px solid #343a40 !important;
}
td.rdtMonth:hover, td.rdtYear:hover {
  background: #212529 !important;
}


@keyframes zoom {
  0% { transform:scale(1.5,1.5); }  
  100% {transform:scale(1,1);}
  }

  @-webkit-keyframes zoom {
    0% { transform:scale(1.5,1.5); }  
    100% {transform:scale(1,1);}
    }

  @-webkit-keyframes fadeIn {
    0% {opacity: 0; margin-top: 15px;}
    100% {opacity: 1; margin-top: 0px;}
 }
 
@keyframes fadeInUp {
from {transform: translate3d(0,500px,0)}
to {transform: translate3d(0,0,0);opacity: 1}
}

@-webkit-keyframes fadeInUp {
from {transform: translate3d(0,500px,0)}
to {transform: translate3d(0,0,0);opacity: 1}
}
.animated {animation-duration: 1s;animation-fill-mode: both;-webkit-animation-duration: 1s;-webkit-animation-fill-mode: both}
.animatedFadeInUp {opacity: 0}
.fadeInUp {opacity: 0;animation-name: fadeInUp;-webkit-animation-name: fadeInUp;}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
.fadeInDown{animation: fadeInDown 1s ease-in both;}

.ds-con{text-align: center; position: relative; z-index: 1; }
.ds-heading{ font-size: 65px; font-weight: 600; margin-bottom: 30px;}
.ds-description{margin-bottom: 30px; font-size: 17px;}

.featuredsec{ padding: 100px 0px; position: relative; z-index: 1; }
.falist{ padding:0px 15px; }
.falist-con{overflow: hidden; position: relative; margin-bottom: 20px; border-radius: 10px 10px 10px 10px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);  }
.falist:hover .falist-con { box-shadow: 0px 0px 15px rgb(158, 102, 191);}
.faimg{ height: 425px; min-height: 150px; background-position: 50% 50%; background-size: cover;  background-repeat: no-repeat;  position: relative; }
.faimg::before{ content: ''; position: absolute; width: 100%; height: 100%; background: rgba(0,0,0,.4); opacity: 0; transition: all ease 0.75s; z-index: 1;}
.falist:hover .faimg::before{opacity: 1;} 
.fafeaturetxt{ position: absolute; left:0; display: flex; justify-content: center; align-items: center; top: 0; background-color: #9144B5; padding: 4px .5rem; color: #fff; font-size: .85em; line-height: 1;  text-transform: uppercase; font-weight: 300; letter-spacing: 0.5px; z-index: 2;}
.falistdesc h3{ margin-bottom: 5px;}
.falistdesc h3{ color: #333; font-size: 22px;}
.falistdesc h4{ color: #666; font-weight: 400; font-size: 18px;}
.falistdesc h3:hover{ color: #9144B5;}
.falistdesc h4:hover{ color: #000;}
.featuredsec.featuredcreator {background: rgba(255,255,255,0.9);}


.featuredsec .alice-carousel__prev-btn, .featuredsec .alice-carousel__next-btn{padding: 0px; width: 35px; height: auto; background: transparent; border-radius: 50%; opacity: 1;   font-size: 40px; top: 50%; margin-top: -17.5px; left: -20px; text-align: center; position: absolute;}
.alice-carousel__prev-btn-item:hover, .alice-carousel__next-btn-item:hover{ color: #000 !important;}
.featuredsec .alice-carousel__prev-btn p, .featuredsec .alice-carousel__next-btn p{padding: 0px; width: 100%;  text-align: center; color: #311847;}
.featuredsec .alice-carousel__next-btn{left: auto; right: -20px;}

/* .featureart .faimg{ height: 250px;} */
.featureart .alice-carousel__prev-btn, .featureart  .alice-carousel__next-btn, .featuredcreator .alice-carousel__prev-btn, .featuredcreator  .alice-carousel__next-btn{background: #f5f5f5; height: 35px;}
.featureart .alice-carousel__prev-btn, .featureart .alice-carousel__next-btn, .featuredcreator .alice-carousel__prev-btn, .featuredcreator .alice-carousel__next-btn {font-size: 23px;}
.featureart .ds-title{ color: #fff;}
.featureart .falistdesc h3{ color: #fff; }
.featureart .falistdesc h4{ color: #c9b4db;}
.featureart .falist-con h3, .spotlight .falist-con h3{ position: absolute;  right: -45%;  top: 50%;  transform: rotate(-90deg);  background: rgba(49, 24, 71, 0.92);  padding: 5px 15px;  font-size: 20px;  width: 100%;  text-align: center;  border-radius: 10px 10px 0px 0px; margin-bottom: 0px; z-index: 1; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}

.featureart .falist-con h3.splfatxt, .spotlight .falist-con h3.splfatxt{right: -47%;}

.ds-title{color: #212325; font-size: 42px;  font-weight: 700;  text-transform: uppercase; margin-bottom: 20px;}
.ds-title-sm{ font-size: 30px; font-weight: 600;}

.spotlight .alice-carousel__prev-btn-item.__inactive, .spotlight  .alice-carousel__next-btn-item.__inactive { opacity: 0; pointer-events: none;}
.featuredcreator .falistdesc {color: #FFF;  font-weight: 600;  line-height: 31px;  position: absolute;  bottom: 15px;  font-size: 30px;  left: 10px; z-index: 1;} 
.featureart .falist , .featuredcreator .falist { padding: 0px 10px; position: relative;}


.danlogo {width: 100%; display: flex; height: 400px; position:relative; background-attachment: fixed; background-repeat: no-repeat; background-position: 50% 50%;}
.danlogo #danlogo{ position: absolute; transition: all linear 0.3s; height: 100%; display: flex; justify-content: center; align-content: center; width: 100%;}
.danlogo #danlogo img{max-width: 100%; max-height: 100%;}

.makenfts{ padding:50px 0px 100px; position: relative; z-index: 1;}
.makenfts h3{font-size: 42px; font-weight: 700; text-transform: uppercase; color: #000;     margin-bottom: 20px;}
.makenfts p{ color: #7A7A7A; font-size: 1rem; line-height: 1.857; margin-bottom: 20px;}

.makenfts .watdcon{ padding: 0px 90px 0px 0px;}
.threeimgs{ display: flex; justify-content: center; align-items: center; position: relative;}
.threeimgs img{ width: 57%;}
.threeimgs .timg {  position: absolute;  top: -35px;  left: 0px;  height: 215px;  width: 100%;  padding-left: 16px;}
.threeimgs .bimg{position: absolute; bottom: -30px; right: 0px; height: 215px; text-align: right; width: 100%; padding-right: 38px;}
.threeimgs .timg img, .threeimgs .bimg img {  max-width: 100%;  max-height: 100%;  width: auto;}
.permiumicon{ width: 16px;}
.fa-cus{ font-size: 12px;}

.featuredcreator .fafeaturetxt{text-transform: none;}
.featuredcreator .falistdesc h3{color: #fff; font-size: 30px;  font-weight: 600;}
.featuredcreator .falistdesc h3:hover{ color: #c9b4db;}
.featureart .falistdesc h3:hover{ color: #9144B5;}
.featureart .falistdesc h4:hover{ color: #fff;}

.spotlight .faimg{  position: relative; }
.faimg img  {object-fit: cover; margin: 0px auto; width: 100%; height: 550px;  left: 0;  top: 0; position: relative; transition: all linear 0.75s;}
.spotlight .faimg img, .spotlight .faimg video{animation: MoveUpDown 13s linear infinite; }
.spotlight .faimg .articonstatic {height: 530px !important;}

.spotlight li:nth-child(2) .faimg img, .spotlight li:nth-child(2) .faimg video { animation-delay: 2s;}
.spotlight li:nth-child(3) .faimg img, .spotlight li:nth-child(3) .faimg video { animation-delay: 4s;}
.spotlight li:nth-child(4) .faimg img, .spotlight li:nth-child(4) .faimg video { animation-delay: 6s;}
.spotlight li:nth-child(5) .faimg img, .spotlight li:nth-child(5) .faimg video { animation-delay: 8s;}
.spotlight li:nth-child(6) .faimg img, .spotlight li:nth-child(6) .faimg video { animation-delay: 10s;}
.spotlight li:nth-child(7) .faimg img, .spotlight li:nth-child(7) .faimg video { animation-delay: 12s;}
.spotlight li:nth-child(8) .faimg img, .spotlight li:nth-child(8) .faimg video { animation-delay: 14s;}
.spotlight li:nth-child(9).faimg img, .spotlight li:nth-child(9) .faimg video { animation-delay: 16s;}
.spotlight li:nth-child(10) .faimg img, .spotlight li:nth-child(10) .faimg video { animation-delay: 18s;}


  @keyframes MoveUpDown {
    0% { top: 0px; }
    50%{ top: -100px;} 
    100%{ top: 0px;}   
  }
/* .site-footer hr:first-child { border: none;  height: 1px;} */

.secimgds{opacity: 0; left: -70%; position: relative; }
.secimgdsshow{-webkit-animation: slide 0.5s forwa rds;  animation: slide 0.5s forwards; }

@-webkit-keyframes slide {
  100% { left: 0; opacity: 1;}
}

@keyframes slide {
  100% { left: 0; opacity: 1;}
}

.featuredcreator .faimg .ccimgbox img, .featureart .faimg img{animation: none; position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);}
.falist .ccimgbox{ height: auto;}
.featureart .faimg .articonstatic{ display: inline-flex;  height: 100% !important;}
.featuredsec.featuredcreator .faimg, .featuredsec.featureart .faimg {height: 500px !important; position: relative;}


.sale-progress{min-height: calc(100vh - 495px); padding: 50px 0px; display: flex; justify-content: center; align-items: center; flex-direction: column;}

.cus-progress{width: 100%; background-color: #1B1B1B; border: 1px solid #1B1B1B;}
.cus-progress .progress-bar{background-color: #9144B5; box-shadow: 0px 0px 10px rgb(158, 102, 191);}

.purchasebox.activitybox i.fa + a {  display: inline-block;  text-overflow: ellipsis;  overflow: hidden;  width: 110px;  white-space: nowrap;  position: relative;  top: 5px;}
.purchasebox.activitybox a + span {display: block;}
.purchasebox.activitybox a + span p{ margin-bottom: 0px;}
.purchasebox.activitybox span.acvttext {margin-top: 2px;}
.mw-95 {max-width: 95px;}
.mw-245 {
  max-width: 245px;
}
.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover {
  background: #333 !important;}

  .rdtPicker td.rdtToday:before {       border-bottom: 7px solid #9144B5;  }

  .purchasebox .timerline h2.usernamelilac + h5 span.usernamelilac {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    max-width: 85%;
    text-overflow: ellipsis;
}

.builtwallet .developlink{ line-height: normal; font-size: 12px;}
.builtwallet .developlink img{ float: left; margin: 9px 5px 5px 0px;  transition: all ease 0.75s;}
.builtwallet .developlink .text-danger{ color:#797979 !important;  filter: brightness(1) invert(0); }
.builtwallet .developlink span{font-size: 15px;  font-weight: 600;}
.builtwallet .developlink:hover .onflowicon{ filter: brightness(1) invert(0);}
.builtwallet .developlink:hover .text-danger{color:#e52828 !important }
.btn-outline-light:hover{ color: #212529 !important;}
.btn-outline-light .input-group-text, .btn-outline-light .form-control{ border: none; cursor: pointer;}
.btn-outline-light:hover .input-group-text, .btn-outline-light:hover .form-control{ background: #000 !important;}

.disnowmenu .navbar-nav .flowtop + .nav-link {padding-top: 5px !important; padding-bottom: 0px !important;}


.probtns.activebtnscus .select2-container{  position: relative;top: -3px;}
.probtns.activebtnscus .select2-container .select2-selection--single, .probtns.activebtnscus  .select2-container--default .select2-selection--single .select2-selection__arrow {height: 42px;}
.probtns.activebtnscus .select2-container--default .select2-selection--single .select2-selection__rendered {  color: #fff;  line-height: 36px; font-weight: bold;}
.select2-dropdown{ z-index: 1 !important;}
.probtns.activebtnscus .select2-container--default .select2-selection--single { border: 2px solid #fff; border-radius: 10px;}

.activebtnscus select.form-control option{ z-index: -1; position: relative;}
.activebtnscus select.form-control.result_hover option{ z-index: 1;}

.rainbow {text-align: center;  font-size: 20px; font-family: monospace;margin: 0px;display: flex;height: 100%;justify-content: center;align-items: center;padding: 0px 15px;font-weight: bold;cursor: pointer; width: 300px;line-height: 20px;}
.rainbow_text_animated {  
  /* background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);  
  -webkit-background-clip: text;  
  background-clip: text;   */  
  /* animation: rainbow_animation 6s ease-in-out infinite;  */
  animation: colorRotate .5s linear 0s infinite;
  background-size: 400% 100%;}

.navbar-expand-lg .navbar-collapse{ justify-content: space-between;}

@keyframes colorRotate {
  from {
    color: #6666ff;
  }
  10% {
    color: #0099ff;
  }
  50% {
    color: #00ff00;
  }
  75% {
    color: #ff3399;
  }
  100% {
    color: #6666ff;
  }
}


@keyframes rainbow_animation {
  0%,100% { background-position: 0 0;}
  50% {      background-position: 100% 0;  }
}
.artcontent .sc-kPVwWT.hdItR {
  position: static !important;
  margin-top: 51px !important;
}

.gototop {  position: fixed;  right: 25px;  bottom: 50px; z-index: 9; background: linear-gradient(to bottom, #9144B5 0%, #21CFF1 100%); border: none !important; width: 40px;}
.statcontsec *{ transition: none !important;}
.statcontsec p, .statcontsec p span , .statcontsec .card-body{ color: #fff !important;}


#accordion button.btn{ position: relative;}
#accordion button.btn::after{ position: absolute;  content: ""; right: 15px;  top: 20px;  width: 15px;  height: 2px;  background: #fff; transition: all ease 0.75s;}
#accordion button.btn::before{ position: absolute;  content: ""; right: 21px;  top: 14px;  width: 2px;  height: 15px;  background: #fff; transition: all ease 0.75s;}
#accordion button.btn[aria-expanded="true"]::before{height: 0px; top: 20px; opacity: 0;}

.piccent .upic{ margin:-25px auto 5px; display: block; }
.piccenth6:hover{color: #9144B5;}

.articonstatic.audioart {margin-bottom: 20px;}
.creatordetalist{ margin-bottom: 5px; text-overflow: ellipsis; overflow: hidden; max-width: 100%;}
.activitybox h4 {display: inline-flex;flex-direction: row; align-items: center; justify-content: space-between; width: 100%;}

.acvticotxtbox{display:flex; flex-direction: row;}
.acvticon {width: 26px;display: flex;}

.top0{top: 0;}
.cursor-pointer{ cursor: pointer;}

.topcolldetabox{ display: flex; flex-direction: column;}
.topcolldetabox .propicdetail{width: auto; margin-right: 15px; padding: 0px;}
.topcolldetabox .propicdetail:hover{ background: transparent;}
.topcolldetabox .propicdetail .picpro {width: 100px;  height: 100px; }
.topcolldeta{ display: flex; flex-direction: row;}
.dandcont .topcolldeta{ margin-top: 0px;}
.topcolldetabox h5{ display: flex; flex-direction: row; justify-content: space-between;}
.topcolldetabox .picpro{ border-width: 5px;}
.topcolldetabox:hover .picpro{ border-color: #9144B5; }
.tcdeta {text-align: left;}

.comboxgray .fa.fa-times {font-size: 10px;margin-left: 6px; cursor: pointer;}
.adconfg .purchasebox { min-height: 305px;}
.adconfg #exist-values {min-height: auto; border: none;  padding: 0px 10px;}
.adconfg #exist-values ~ .btn {border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important;}
.adconfg .inputtags{align-items: flex-start; }
.adconfg .inputtags .badge { margin: 0px 0px 5px 5px !important; font-size: 14px; display: inline-flex; flex-direction: row; justify-content: center; align-items: center;}
.adconfg .input-group {background: #141414;border: 1px solid #fff; border-radius: 10px; padding: 5px;}
.adconfg .input-group .fa { color: #4e2466; font-size: 10px; cursor: pointer;  margin-left: 5px;}

.badge-primary{ background-color: #9144B5;}
.badge-primary:hover, .badge-primary:focus, a.badge-primary:focus, a.badge-primary:hover{ background-color: #7F489F;}
.solarloader {  position: relative;  min-height: 100px; width: 100%;}

.bg-primary {background-color: #9144B5 !important;}

.igt-custom{ font-weight: bold; color: #000; border-radius: 6px 0px 0px 6px !important;}
.fc-custom{border:1px solid #4f4f4f !important;}
.fc-custom:hover{border:1px solid #fff !important;}

.otpverifiy{min-height: calc(100vh - 550px); z-index: 9;  position: relative;}

.otpinputs{ display: flex; flex-direction: row; justify-content: space-between; margin: 30px 0px 40px;}
.otpinputs .form-control {width: 50px; border-left: none; border-right: none; border-top: none; height: 55px;  display: flex;  align-items: center;  padding: 0px;  text-align: center;  justify-content: center; border-color: #9144B5; font-size: 25px;}

.cobcross {font-size: 100px;}
.cobmain{ padding: 0px 15px; border:1px solid #373737; background: #000; margin: 30px 100px;}
.cobmain h1 {font-size: 100px;}
.cobmain h1 span { display: block; font-size: 75px;}
.cobmain h2{ font-size: 45px; margin-bottom: 30px;}
.cobmain h5{ font-size: 30px;  margin-bottom: 0px;}
.cob-venue{ border: 1px solid #9144B5; padding: 30px; background: #9144B5;}
.cob-venue h3{ font-size: 65px; margin-bottom: 30px; font-weight: bold; text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);}
.cob-venue h6{font-size: 40px; letter-spacing: 1px; text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);}
.cob-venue .col-md-3:nth-child(3){ border-left: 1px solid #fff; border-right: 1px solid #fff;}
.cobmain .btn-primary{ background: #9144B5 !important; color: #fff !important; font-size: 30px; height: auto !important; line-height: normal !important; padding: 10px 30px !important;}
.cobmain .btn-primary:hover{background: transparent !important; color: #9144B5 !important;}
.cobmain h4{ text-transform: none;}
.cobmain h2{ color: #fff; text-transform: uppercase; font-size: 35px;}
.cobmain h2 span{ color: #000; }
.nyc-city{ margin-top:-150px;}

.nyc-present .barline{ width: 182px; height: 20px; background:#9144B5; display: block; margin: 0px auto; border-radius: 0px 0px 16px 16px;}
.nyc-present h4{ text-transform: uppercase; color: #fff; padding: 10px 0px;}
.nyc-present .btn{ width: 182px; font-size: 50px; padding: 5px 0px !important; position: relative; z-index: 1;}
.nyc-logos{ display: flex; flex-wrap: wrap; justify-content: center; align-items: center;}
.nyc-logos img{ margin: 0px 30px;}

.cushashtag {border: none !important;padding: 0px !important; color: #9144B5 !important;}
.cushashtag i{ margin: 0px;}
.cushashtag:hover, .cushashtag.active{ color: #fff !important;}

.droplisttit{ display: flex; flex-direction: column;}

.btn-dark-cus {background: #1B1B1B;border-color: #1B1B1B !important;}
.btn-dark-cus:hover, .btn-dark-cus:focus, .btn-dark-cus[aria-expanded="true"]{box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 15px !important;}
.prpabout .btn-dark-cus i{ margin-right: 5px;}
.prpabout .card-header .btn{ border-radius: 16px !important;}
.prpabout .card-header .btn .acdarrow{height: auto;}
.prpabout {margin-bottom: 30px; margin-top: 25px;}
.prpabout .card-header{ padding: 0px;}
.prpabout .item--about-image {  float: left;  margin-right: 15px;  margin-top: 3px;  height: 100%;  width: 80px;  border-radius: 10px;}
.prpabout .Image--image {  height: 100%;  object-fit: contain;  transition: opacity 400ms ease 0s;  width: 100%;}
.prpabout .item--about-text{ text-align: justify; line-height: 20px; }
.prpaboutlist{background-color: rgba(158, 102, 191, 0.06);  border-radius: 10px;  border: 1px solid #9144B5;  padding: 10px;  text-align: center; display: block; margin-bottom: 30px; cursor: pointer; width: 100%; transition: all ease-in-out 0.75s;}
.prpabout .accordion > .card{ margin-bottom: 2px;}
.prpaboutlist:hover {background: #9144B5;}
.prpaboutlist .Property--type, .prpaboutlist .Property--value, .prpaboutlist .Property--rarity {  overflow: hidden;  text-overflow: ellipsis;  white-space: nowrap;}
.prpaboutlist .Property--type { color: #9144B5; font-size: 12px; font-weight: 700;  text-transform: uppercase;  letter-spacing: 1px;}
.prpaboutlist .Property--value {  color: #d9d9d9;  font-size: 15px;  font-weight: 500;  line-height: 30px;  overflow: hidden;  text-overflow: ellipsis;  white-space: nowrap;}
.prpaboutlist .Property--rarity {  color: #fff;  font-size: 12px;  line-height: 16px;  min-height: 16px;}
.prpaboutlist:hover .Property--type{ color: #fff;}
.prpabout .item--about-container {overflow: hidden; text-overflow: ellipsis; margin-bottom: 30px;}
.item--social-links{display: flex; margin-bottom: 30px;}
.item--social-links .fresnel-container {margin: 0;padding: 0;}
.item--social-links .jGmHuk {-moz-box-pack: end;  justify-content: flex-end; display: flex;}
.item--social-links .kgqWIT {width: fit-content; display: flex;}
.item--social-links .eHTcPK {display: inline-flex;flex-direction: row;-moz-box-align: center;align-items: center;border-radius: 12px;-moz-box-pack: center;justify-content: center;font-size: 16px;font-weight: 600;line-height: 22px;letter-spacing: 0.01em;padding: 17px;background-color: rgb(27, 27, 27);  border: 1px solid #262626; color: #21cff1;}
.item--social-links .fPnOUC{border-radius: 0px; transition: all ease 0.75s;}
.item--social-links .fPnOUC:first-child {border-top-left-radius: 10px; border-bottom-left-radius: 10px;}
.item--social-links .fPnOUC.fPnOUC:not(:first-child) {margin-left: -2px;}
.item--social-links .fPnOUC:last-child,  .eHTcPK.fPnOUC.dropdown-toggle {border-top-right-radius: 10px;border-bottom-right-radius: 10px;}
.item--social-links .eHTcPK.fPnOUC.dropdown-toggle::after { display: none;}
.fresnel-container .dropdown-menu {padding: 0px;background: #2f2f2f;border-radius: 10px; overflow: hidden;}
.fresnel-container .dropdown-menu .dropdown-item{ padding: 10px 15px; text-align: center; color: #fff;} 
.fresnel-container .dropdown-menu .dropdown-item:focus, .fresnel-container .dropdown-menu .dropdown-item:hover {color: #f7f7f7; text-decoration: none; background-color: #9144B5;}
.item--social-links .fPnOUC:hover {background: #9144B5; color: #000;}

.btn-smc {  height: auto !important;  line-height: normal !important;  font-size: 12px;  padding: 3px 10px;}
.activebtnscus ul li:first-child .btn {  margin-left: 0px;}
.activebtnscus ul li:last-child .btn {  margin-right: 0px;}

.custagbadge .cushashtag{background: #1B1B1B; padding: 5px 15px !important; margin-right: 10px; margin-bottom: 10px; color: #21CFF1 !important;font-size: 12px; border: transparent !important;  transition: all ease 0.75s; border-radius: 10px; font-size: 14px; line-height: 21px; font-weight: 400;}
.custagbadge .cushashtag:hover, .custagbadge .cushashtag.active {background: rgba(158, 102, 191, 0.1); border-color: #9144B5 !important;color: #9144B5 !important;}

.bellcus{ 
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

.mustheart{ margin-top: 30px; position: relative;}
.mustheart .mp3_player{ position:absolute; width: 100%; height: 100%;}
.mustheart .mtartimg{display: flex; justify-content: center; position: relative;}
.mustheart .mtartimg .articonstatic{ width: auto; border-radius: 16px; box-shadow: 0px 10px 10px rgb(45, 45, 45); min-height: 400px;}
.mtpd{ display: flex; flex-direction: row; margin-bottom: 75px; }
.mtplaybtn {width: 85px;height: 85px;border: 2px solid #9144B5;color: #9144B5;border-radius: 6px;cursor: pointer;display: flex;align-items: center;justify-content: center;font-size: 35px; transition: all ease 0.75s;}
.mtplaybtn:hover, .mtplaybtn.active{ background: #9144B5; color: #fff;}
.mtpdetail {display: flex;flex-direction: column;padding-left: 30px;justify-content: space-between;}
.mtpdetail h3{ margin-bottom: 0px; font-weight: 600; font-size: 40px;}
.mtpdetail h5{ font-size: 25px; position: relative; display: flex;  align-items: flex-start;}
.mtpdetail h5 .articoni{ position: static;  margin-right: 10px;} 
.mtpdetail span.spancursor:hover{ color: #9144B5; }

.mtauser{ display: flex; flex-direction: row; align-items: center;}
.mtauser .picpro {width: 65px;height: 65px;margin: 0px; border-width: 0px; margin-right: 10px; padding: 5px;}
.mtauser h2{ font-size: 20px; margin-bottom: 0px;} 

.mustheart .mp3_player canvas{width: 100%;height: 200px;  position:absolute; bottom: 0px; left: 0px;z-index: 1; }
.mustheart .mp3_player #audio_box {width: 100%; position: fixed; bottom: 0px; left: 0px;  z-index: 10;}
.mustheart .mp3_player #audio_box audio{ width: 100%;}

#music,#canvas {	width: 100%;  height: 100%;}
#canvas {	position: absolute;	z-index: 1;	background-color: transparent;	opacity: 1;	transition: all 0.3s ease-in-out; width: 400px !important; height: 400px !important;}

.circart{width: 400px;  height: 400px;  margin: 0px auto;  border-radius: 50%; }
#analyser{ position: absolute; width: 100%; text-align: center; bottom: 0px; z-index: 1;}

.mustheart .mtartimg.circart .articonstatic{min-height: 285px; margin: 50px 0px 0px 0px;}
.mtartimg.circart #canvas {z-index: -1 !important;}

.container1 {	position: relative;	z-index: 2;}
.innerContainer {	display: grid;	grid-template-columns: 1fr;	justify-items: center;	align-items: center;	margin-bottom: 15px;}
#labelSong input[type="file"] {	display: none;}
#labelSong {	text-align: center;	font-family: Arial, Helvetica, sans-serif;	font-size: 0.75rem;	padding: 5px 20px;	border-radius: 25px;	background-color: #667eea;	color: white;	border-bottom: 2px solid #1b4965;	border-right: px solid #1b4965;	box-sizing: content-box;	width: 70px;	cursor: pointer;}
#labelSong:active {	border-bottom: none;	border-right: none;	border-left: 2px solid #1b4965;	border-top: 2px solid #1b4965;}

.mtmirrorbg{height: 150px; }
.mtartmirror{position: absolute;  height: 150px; overflow: hidden; bottom: 0px;  opacity: 0.5; left: 0px; width: 100%; display: flex;  justify-content: center; align-items: flex-start; }
 .mtartminheight{transform: rotateX(180deg);
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  -moz-transform:rotateX(180deg); /* Firefox */} 

.mtartmirror::before{z-index: 1;  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), #000);  content: "";  position: absolute;  display: block;  width: 100%; height: 100%;}

.bgrainbow{
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  background-size: 1800% 1800%;  
  -webkit-animation: rainbowbg 1s ease infinite;
  -z-animation: rainbowbg 1s ease infinite;
  -o-animation: rainbowbg 1s ease infinite;
    animation: rainbowbg 1s ease infinite;}
  
  
  @-webkit-keyframes rainbowbg {
      0%{background-position:0% 82%}
      50%{background-position:100% 19%}
      100%{background-position:0% 82%}
  }
  @-moz-keyframes rainbowbg {
      0%{background-position:0% 82%}
      50%{background-position:100% 19%}
      100%{background-position:0% 82%}
  }
  @-o-keyframes rainbowbg {
      0%{background-position:0% 82%}
      50%{background-position:100% 19%}
      100%{background-position:0% 82%}
  }
  @keyframes rainbowbg { 
      0%{background-position:0% 82%}
      50%{background-position:100% 19%}
      100%{background-position:0% 82%}
  }

  .bg1c1c1c{background-color: #1c1c1c;}
  .draggable-item small { color: #9144B5;}

.hideshowart{ display: inline-flex; flex-direction: row; align-items: center; justify-content: center;}
.hideshowart .copycon {  top: auto;  bottom: 45px; left: auto; right: auto; width: auto; margin: auto; transform: none;}
.hideshowart .copycon .uparrow{bottom: -10px;  top: auto; border-top: 5px solid #2d2d2d; border-bottom: 5px solid transparent;}
.span-cursor {cursor: default;}
.ds-btn-text {text-transform : none}
.iminbtn { padding-left: 130px;}
.mx580{ max-width: 580px;}
.noanimation{ animation: none;}
.totedition{color:white;font-size: 20px;font-weight: normal;}
.maxh110{ max-height: 110px;}
.accordicotxt{ display: flex; flex-direction: row; justify-content: space-between;}
.accordtxt{ flex: 1;}
.acdarrow {  width: 36px;  height: 45px;  display: flex;  justify-content: center;  align-content: center;  flex-direction: column;}
.acdarrow i{margin: 0px !important;  text-align: center;  font-size: 25px;  color: #797979;}
.accordicotxt[aria-expanded="true"] .acdarrow{ transform: rotate(180deg);}
.prpabout .card-body{  border: 1px solid #1B1B1B; border-radius: 16px; background: #000;}
.login-label-text {text-transform: lowercase;}
/* .useroverflow{text-overflow: ellipsis;overflow: hidden;max-width: 170px;white-space:nowrap;display: inline-block;} */
.splitline{ height: 100%; width: 1px; background: #353535; display: block; margin: 0px auto; position: relative;}
.splitline::after{ content: ''; position: absolute; right: -1x; height: 100%; width: 1px; background: rgba(158, 102, 191, 0.14); }
.logsign h1{ font-size: 28px;}
.dlilabg {background: rgb(26, 26, 26);border: 1px solid #1B1B1B;border-radius: 16px;}
.btn-smcus { height: auto !important; padding: 8px 15px; font-size: 12px; line-height: normal !important;}

.featuredcreator .falistdesc h3 span {max-width: 340px;text-overflow: ellipsis;overflow: hidden;width: auto;display: inline-block;}
.switch-field { display: flex;  overflow: hidden;  width: 90px;  border-radius: 50px;  background: #2f2f2f; position: relative;}
.switch-field input {position: absolute !important;clip: rect(0, 0, 0, 0);height: 1px;width: 1px;border: 0;overflow: hidden;}
.switch-field label { margin: 0px; font-size: 14px;  line-height: 1;  text-align: center;  padding: 8px; transition: all 0.1s ease-in-out;  width: 30px;  display: inline-flex;  justify-content: center;  align-items: center;  height: 30px;  border: none !important;  color: #7b7b7b;  background: #2f2f2f;}
.switch-field label i {font-size: 11px; z-index: 1;}
.switch-field label:hover {background: #654477;color: #fff; border-radius: 50%;}
.switch-field label:hover {cursor: pointer;}
.switch-field input:checked + label { color: #fff; border-radius: 50%;}
.switch-field .toggle::before {display: none;}
.switch-field span {height: 30px;width: 30px;border-radius: 50%;background:#fff;display:block;position:absolute;left: 0px;top: 0px;transition:all 0.3s ease-in-out;background-color: #9144B5;}
.switch-field input[value="false"]:checked ~ span{left:0px;}
.switch-field input[value="true"]:checked ~ span{left: 60px;}
.switch-field input[value="-1"]:checked ~ span{left: 30px;}
.switch-field input[value="false"]:checked + label,.switch-field input[value="true"]:checked + label{color:#fff;}
.switch-field input[value="-1"]:checked + label{color:#fff;}

.dscpb{ display: flex; flex-direction: column; margin-bottom: 30px; align-items: flex-end;}
.dscpb span.small{ color: #666;}
.dscpbtxt {color: #000; font-weight: bold; margin-bottom: 15px;}
.dscpbbtn .btn{ background: transparent; transition: all ease 0.75s;}
.dscpbbtn .btn:hover{ background: #9144B5;}

.dark-purple .dscpb span.small{ color: #c9b4db;}
.dark-purple .dscpb .dscpbtxt {color: #fff;}

/* iframe {z-index: -1 !important;} */

.dropcustom .dropdown-menu{background: #9144B5;}
.dropcustom .dropdown-menu a{color: #fff;}
.dropcustom .dropdown-toggle {width: 100%;display: flex;justify-content: space-between;align-items: center;}

input[type="date"]{background-image: url(assets/images/calendar.png); background-position: 98% center; background-repeat: no-repeat;} 

.tatcon{position: relative; height: 64px !important;}
.takeatour {position: absolute;padding: 15px;background: #9144B5;border-radius: 10px;right: 0%;top: 90px;font-size: 12px;text-align: center;  white-space: nowrap;-webkit-animation: mover 1s infinite  alternate;
animation: mover 1s infinite  alternate;z-index: 1;display: flex;align-items: center;justify-content: center;flex-direction: column;font-weight: bold;text-transform: uppercase;}
.takeatour p{ margin-bottom: 0px; margin-right: 10px;}
.tatcls {position: absolute;right: -5px;top: -5px;font-size: 10px;width: 20px;background: #424242;border-radius: 50%;height: 20px;display: flex;justify-content: center;align-items: center;border: 1px solid #555;color: #c4c4c4; cursor: pointer;}


@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

.takeatour::before { position: absolute;content: '';top: -18px;left: 50%;transform: translateX(-50%);border-bottom: 10px solid #9144B5;border-top: 10px solid transparent;border-left: 10px solid transparent;border-right: 10px solid transparent;}
.takeatour .react-toggle-track{background-color: rgb(0, 0, 0) !important;}
.edtoggle {display: flex;flex-direction: row;align-items: center; margin-top: 10px;}
.edtoggle span {font-size: 15px;text-transform: none; padding: 5px;font-weight: bold;color: #000;letter-spacing: 1px;}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 2.5s;
  transition-property: opacity;
  height: 500px;
 }
 
 .carousel-fade  .carousel-item.active,
 .carousel-fade  .carousel-item-next.carousel-item-left,
 .carousel-fade  .carousel-item-prev.carousel-item-right {
   opacity: 1;
 }
 
 .carousel-fade .active.carousel-item-left,
 .carousel-fade  .active.carousel-item-right {
  opacity: 0;
 }
 
 .carousel-fade  .carousel-item-next,
 .carousel-fade .carousel-item-prev,
 .carousel-fade .carousel-item.active,
 .carousel-fade .active.carousel-item-left,
 .carousel-fade  .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
 }
.fixedrigt {animation:none; position: fixed;right: 0 !important;top: 25% !important;transform: translateY(-0%);z-index: 99;border-radius: 10px 0px 0px 10px;height: 110px;padding-top: 0px;padding-bottom: 0px; width: 0px; overflow: hidden; transition: all ease 0.75s; cursor: pointer;}
.fixedrigt p { margin: 0px; transform: rotate(-90deg);position: relative;right: -2px;top: 14px;background: #9144B5;padding: 10px 7px;border-radius: 10px 10px 0px 0px; position: relative; z-index: 1; transition: all ease 0.7s;}
.fixedrigt::before{ display: none;}
.fixedrigt:hover, .fxtmob{ width: 160px; overflow: visible;}
.fixedrigt:hover p, .fxtmob p{right: 90px;}
.fixedrigt .edtoggle {margin-top: 0px;position: relative;top: -18px;}
.propagecont.ccdetailbox {margin-bottom: 50px;}

.btn-primary-fill.btn-icon img{ background-color: transparent; filter: brightness(0) invert(1);}
.btn-primary-fill.btn-icon:hover img{ background-color: transparent;  filter:none;}

.vertical-separator { display: flex; align-items: center;}
.vertical-separator_hr {width: 100%;margin-top: 16px;margin-bottom: 16px;}
.vertical-separator_or {padding-left: 8px;padding-right: 8px;}
.vertical-separator_or p{ margin-bottom: 0px;}
.logsign  .loginhide{ display: none;}
.react-joyride__tooltip h4 {color: #000;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;}


.collapsing {transition: height 0.6s;}
.logacordbtn { height: 100%;}
.logacordbtn .card-header {height: 100%; justify-content: center; align-items: center; display: flex !important;}
.logacordbtn .card-header[aria-expanded="true"]{ display: none !important;}
.logacordbtn .collapse.show .dlilabg { background: transparent !important;}
.grecaptcha-badge { visibility: hidden; }
.logacordbtn .card-header.d-nonecus{ display: none !important;}

.__floater__body .react-joyride__tooltip { padding: 10px !important;}
.__floater__body .react-joyride__tooltip div{ padding: 0px !important;}
.react-joyride__tooltip h4{ margin-bottom: 15px !important;}

.sdmorelinks {padding: 0px;}
.sdmorelinks .dropdown-toggle{padding: 15px;  display: block;  cursor: pointer;}
.sdmorelinks.faheart .dropdown-toggle{padding: 10px;}

.minw-35rem{ min-width: 35rem; max-width: 35rem;}

/*discover v3*/
.bigartimg{border-radius: 30px; height: 500px; position: relative; display: flex; align-items: center; justify-content: center; cursor: pointer;}
.bigartimg::before {position: absolute; color: #fff; transition: all .75s ease; width: 100%; height: 100%;   content: ""; top: 50%; left: 50%; transform: translate(-50%,-50%);  transform-origin: 50%;  background: rgba(0,0,0,.6);  opacity: 1;  backdrop-filter: blur(20px);    -webkit-backdrop-filter: blur(20px);  flex-direction: column; border-radius: 30px;}
.baimgcover{position: relative; display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; overflow: hidden; border-radius: 30px; height: 500px;}

.baimgcover img, .baimgcover video { max-width: 100%; max-height: 100%; width: auto !important;   position: relative;}
.bigartdetails{ padding-left: 50px;}

.dsv3-mtgdlist{ display: flex; flex-direction: row; flex-wrap: wrap; margin: 40px -15px; justify-content: center;}
.mtgdbox{cursor: pointer; margin:0px 15px 20px; width: 75px; min-height: 75px; position: relative;}

.mtgd{ position: relative; width: 75px; min-height: 75px; max-height: 75px; display: flex; align-items: center; justify-content: center; border-radius: 6px; background: #f9f9f7; overflow: hidden; border: 1px solid #fff; margin-bottom: 5px; }
.mtgd::before, .mtgd::after{ content: ''; position: absolute;  width: 0%; height: 0%; background: rgba(158, 102, 191, 0.35); transition: all ease 0.5s;}
.mtgd::before{ left: 0px; top: 0px;}
.mtgd::after{ bottom: 0px; right: 0px;}
.mtgdbox:hover .mtgd::before, .mtgdbox:hover .mtgd::after{width: 100%; height: 100%;}
.mtgd img{ max-width: 100%; max-height: 100%;}
.mtgd:hover{ box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);}
.dsv34item .falist {width: 25%;display: inline-block;}
.mtgdbox p {overflow: hidden;white-space: nowrap;color: #000;text-overflow: ellipsis;margin: 0px;}

.hwdabox{ margin-bottom: 50px; min-height: 450px; border-radius: 30px; border: 1px solid #5c2f84;  position: relative; box-shadow: 0px 5px 3px rgba(10,0,0,0.5);}
.hwdabox::before {position: absolute;color: #fff;transition: all .75s ease;width: 90%;height: 90%;content: "";top: 50%;left: 50%;transform: translate(-50%,-50%);background: rgba(13, 6, 19, 0.6);opacity: 1;
backdrop-filter: blur(5px);-webkit-backdrop-filter: blur(5px);border-radius: 25px;box-shadow: inset 0 0 30px 0 rgb(63, 35, 87), 0px 0px 5px rgba(0,0,0,0.8);}
.hwdaboxcon{ position: relative; display: flex; flex-direction: column; justify-content: space-between; padding: 50px 30px; align-items: center; height: 425px; width: 100%; text-align: center;} 
.hwdabox:hover::before{ width: 100%; height: 100%; transform: translate(0); left: 0px; top: 0px; background: rgba(176, 84, 255, 0.32);}
.hwdaboxcon img {padding: 0px 30px;}

.watdcon3box .dscpbbtn .btn-primary-fill {  color: #9144B5 !important;  font-size: 16px;  text-transform: none;  letter-spacing: 1px;}
.watdcon3box .dscpbbtn .btn-primary-fill:hover {color: #fff !important;}

.dscpbbtn .btn-primary-fill {background: #9144B5;}
.dscpbbtn .btn-primary-fill:hover {background: transparent;}

.mobsidepro, .dropdown-content .drops, .desknone{ display: none;}
.flowboxdes {text-align: center;}
.disnowmenu .navbar-nav .flowboxdes span.nav-link{ padding: 0px !important ;}
.disnowmenu .navbar-nav .flowboxdes .flowtop span.nav-link{ padding: 3px 15px !important; font-size: 12px;}

.fav-color { 
  color:#9144B5;
}
.mandatory-req + .input-group .suggestions li, .auto-suggest .suggestions li{ padding: 5px; cursor: pointer;}  
.pop-size{word-break: break-word;max-width: 40rem;}
.popmesoverflow{text-overflow: ellipsis;overflow: hidden;max-width: 270px;white-space:nowrap;display: inline-block;}

.editcls {  width: 48px; }
.alert-cuslilac {background: #211528;color: #ff0a0a;border-radius: 30px;}
.input-group .brdlftcus{ border-left: 1px solid #4f4f4f;}
.input-group .brdlftcus:focus{ border-color: #fff;}

.dapbloc {display: flex;margin-bottom: 5px;align-items: center;justify-content: center; flex-direction: row; font-size: 14px; text-transform: uppercase;}
.dapbloc img{ float: none; top: 0px;}
.dapbloc .dapper{ top: -2px;}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {color: #fff; background-color: #9144B5;}

.tabpills .nav-pills{ width: 100%; flex-direction: column;}
.tabpills .nav-pills .nav-item, .tabpills .nav-pills .nav-item .nav-link{ width: 100%;}
.tabpills  .nav-pills .nav-link{ color: #fff; padding: 15px;}
.tabpills  .nav-pills .nav-link:hover{background: #1B1B1B;}

/*new changes*/
.purchasebox .walletbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.purchasebox .walletbox img {
  max-width: 35px;
  margin-right: 20px;
}
.purchasebox .walletbox span {
  display: inline-block;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}
.minw-28rem {
  /* min-width: 28rem; */
  max-width: 28rem;
}
.dapbloc {
  position: relative;
}
span.dapbloc > bdi.blk {
  position: relative;
  bottom: -4px;
}
/*new changes*/

/*new changes 2-2-2023*/
.uawallet .uawt{ background: #2f2f2f;}
.uawallet .uawt h4{display: flex;align-items: center; color: #fff;}
.uawallet .uawt h4 img{ width: 30px; margin-right: 5px;}
.mh100sb{ max-height: 130px; overflow: hidden; overflow-y: auto;}
/*new changes 2-2-2023*/

.uareq p {padding:15px 20px 10px 70px;background: #1B1B1B;border-radius: 6px;position: relative; overflow: hidden;}
.uareq p span {position: absolute;top: 0;left: 0px;padding: 0px 10px;color: #9144B5;height: 100%;background: #0d0d0d;display: flex;line-height: 51px;}

.sdwallet{ margin-bottom: 0px;}
.sdwallet li {padding: 10px 15px;display: flex;background: #eaffda;margin-right: 15px;border-radius: 16px;border: 1px solid #c0ff8e; color: #305c0d; font-size: 14px;}
.sdwallet li img{ width: 24px; margin-right: 5px;}
.word-break-word{word-break: break-word;}

.GoogleMap{width: 100% !important; padding: 15px;}
.GoogleMap > div {width: 100% !important;}

.da-sfair{ min-height: calc(100vh - 560px);}

.da-sfairloader{position: relative; width: 200px; height: 200px; display: block; margin:0px auto 50px;}
.pin {animation: pin 1s linear infinite; width: 100px;height: 100px;border-radius: 50% 50% 50% 0;background: #9144B5;position: absolute;transform: rotate(-45deg) translate(-50%, -50%);left: 69%;
top: 50%;margin: -20px 0 0 -20px;}
.pin::after {content: '';width: 50px;height: 50px;margin: 25px 0 0 25px;background: rgb(20, 20, 20);position: absolute;border-radius: 50%;  top: 0px; left: 0px;}
.pulse {background:rgba(158, 102, 191, 0.13);border-radius: 50%;height: 50px;width: 50px;position: absolute;left: 84px;top: 165px;margin: 11px 0px 0px -12px;transform: rotateX(55deg);z-index: -2;}
.pulse::after {content: "";border-radius: 50%;height: 120px;width: 120px;position: absolute;margin: -13px 0 0 -13px;animation: pulsate 1s ease-out;animation-iteration-count: infinite;opacity: 0;box-shadow: 0 0 1px 2px #9144B5;  left: -20px;  top: -20px;}
@keyframes pulsate {
0% {-webkit-transform: scale(0.1, 0.1);transform: scale(0.1, 0.1);opacity: 0;}
50% {opacity: 1;}
100% {-webkit-transform: scale(1.2, 1.2);transform: scale(1.2, 1.2);}
}
@keyframes bounce1 {
0% {-webkit-transform: translateY(-2000px) rotate(-45deg);}
60% {-webkit-transform: translateY(30px) rotate(-45deg);}
80% {-webkit-transform: translateY(-10px) rotate(-45deg);}
100% {-webkit-transform: translateY(0) rotate(-45deg);}
}

@keyframes pin{
  0%, 100% {top: 30%;}
  50% { top: 50%;}
}

.daigroup .btn{ border-radius: 0px 10px 10px 0px !important;}
.daigroup input{ height: 48px; border:1px solid #4f4f4f !important}

.dsairartcol{ flex-direction: row; min-height: 400px; }
.dsairartcolcelebration{min-height: 565px;}
.dsairartconte{flex-direction: row; display: flex; flex: 1;}
.dsairartcol .artcovimglink{width: 800px; margin-bottom: 0px;}
.dsairartcol .cont { flex: auto; margin-left: 30px;   display: flex;flex-direction: column; justify-content: center; position: relative; z-index: 2;}
.dsairartcol .piccent .upic{ margin: 0px auto; width: 60px; height: 60px;}
.dsairartcol .cont .col-6{  flex: 0 0 100%;  max-width: 100%;}
.dsairartcol .userwithpic h6{ max-width: 100% !important; text-align: center; width: 100%;}
.dsairartconte h6, .dsairartconte h6:hover{ color: #fff !important;}
.text-link{ color: #21cff1 !important;}
.text-link:hover{ color: #9144b5 !important;}

.dsairartcol .btn, .dsairartcol .btn::after {border-radius: 50px !important;}

/* .dspcls {cursor: pointer;position: absolute;right: 5px;top: 5px;font-size: 20px;width: 40px;height: 40px;background: rgb(1, 1, 1);display: flex;justify-content: center;border-radius: 0px 45px 0px 16px;padding: 5px 10px 0px 0px;
color: #2d2d2d;font-weight: bold; transition: all ease 0.75s;} */
.dspcls {cursor: pointer;position: absolute;right: 25px;top: 25px;font-size: 20px;width: 25px;height: 25px;display: flex;justify-content: center;color: #fff;transition: all ease 0.75s;border-radius: 6px;
border: 1px solid #fff;align-items: center;font-weight: 200;line-height: normal;text-transform: capitalize;}
.dspcls:hover{color: #fff; background: #1c1b1b;}
.dsp-img{width: 100%;display: block;margin-left: auto;margin-right: auto;border-radius: 50%;background: #1c1c1c;height: 100%; border: 1px solid #2b2a2a;}
.dspinputme{ min-height: 48px; background: #111;}


.radar {  position: relative;width: 40vmin;height: 40vmin; background: repeating-radial-gradient(transparent, transparent 4.5%, rgb(158, 102, 191) 5%, transparent 5.5%) content-box, linear-gradient(transparent 49.7%, rgb(158, 102, 191) 49.9%, rgb(158, 102, 191) 50.1%, transparent 50.3%) content-box, linear-gradient(to right, transparent 49.7%, rgb(158, 102, 191) 49.9%, rgb(158, 102, 191) 50.1%, transparent 50.3%) content-box, radial-gradient(#481c62, #000500) content-box, linear-gradient(to bottom right, #ccc, #666) border-box; border: 0.5vmin solid #b199bf; border-radius: 50%; box-sizing: border-box; overflow: hidden; filter: drop-shadow(1vmin 1vmin 1vmin rgba(0, 0, 0, 0.4)); margin: 30px auto;}
.radar::after {content: "";position: absolute;inset: 0;background-image: conic-gradient(transparent 90%, rgba(158, 102, 191,0.85));border-radius: 50%;box-shadow: inset 0 0 2vmin rgba(0, 0, 0, 0.9);-webkit-animation: spin 2s linear infinite;animation: spin 2s linear infinite;}
.radar__dot {position: absolute;width: 3%;height: 3%;border-radius: 50%;transform: translate(-50%, -50%);-webkit-animation: blink 2s ease-out infinite;        animation: blink 2s ease-out infinite;}
.radar__dot:first-of-type {top: 24%;left: 76%;-webkit-animation-delay: 0.25s;        animation-delay: 0.25s;}
.radar__dot:nth-of-type(2) {top: 80%;left: 20%;-webkit-animation-delay: 1.25s;        animation-delay: 1.25s;}
.radar__dot:last-of-type {top: 36%;left: 36%;-webkit-animation-delay: 1.75s;        animation-delay: 1.75s;}

@-webkit-keyframes spin {
to {transform: rotate(1turn);}
}
@keyframes spin {
to {transform: rotate(1turn);}
}
@-webkit-keyframes blink {
2%, 20% {background-color: rgba(158, 102, 191, 1);box-shadow: 0 0 1vmin rgba(158, 102, 191, 0.6);}
90% {  background-color: transparent;}}
@keyframes blink {
2%, 20% {  background-color: rgba(158, 102, 191,1);  box-shadow: 0 0 1vmin rgba(158, 102, 191, 0.6);}
90% {  background-color: transparent;}
}

.wrappercele {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  left: 0px;
  top: 0px;
}

[class|=confetti] {
  position: absolute;
}

.confetti-0 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 21%;
  opacity: 1.2288062428;
  transform: rotate(265.6068787544deg);
  -webkit-animation: drop-0 4.1262316881s 0.2021491152s infinite;
          animation: drop-0 4.1262316881s 0.2021491152s infinite;
}

@-webkit-keyframes drop-0 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-0 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-1 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 86%;
  opacity: 1.0233852756;
  transform: rotate(247.0341892619deg);
  -webkit-animation: drop-1 4.3656012378s 0.7587292418s infinite;
          animation: drop-1 4.3656012378s 0.7587292418s infinite;
}

@-webkit-keyframes drop-1 {
  100% {
    top: 110%;
    left: 88%;
  }
}

@keyframes drop-1 {
  100% {
    top: 110%;
    left: 88%;
  }
}
.confetti-2 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 26%;
  opacity: 0.5232947945;
  transform: rotate(339.2889680596deg);
  -webkit-animation: drop-2 4.12378036s 0.9197931315s infinite;
          animation: drop-2 4.12378036s 0.9197931315s infinite;
}

@-webkit-keyframes drop-2 {
  100% {
    top: 110%;
    left: 39%;
  }
}

@keyframes drop-2 {
  100% {
    top: 110%;
    left: 39%;
  }
}
.confetti-3 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 45%;
  opacity: 1.2453796676;
  transform: rotate(22.1908785769deg);
  -webkit-animation: drop-3 4.0122068757s 0.4033737684s infinite;
          animation: drop-3 4.0122068757s 0.4033737684s infinite;
}

@-webkit-keyframes drop-3 {
  100% {
    top: 110%;
    left: 60%;
  }
}

@keyframes drop-3 {
  100% {
    top: 110%;
    left: 60%;
  }
}
.confetti-4 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 63%;
  opacity: 1.4828872773;
  transform: rotate(263.1076641717deg);
  -webkit-animation: drop-4 4.7554285611s 0.8677064362s infinite;
          animation: drop-4 4.7554285611s 0.8677064362s infinite;
}

@-webkit-keyframes drop-4 {
  100% {
    top: 110%;
    left: 75%;
  }
}

@keyframes drop-4 {
  100% {
    top: 110%;
    left: 75%;
  }
}
.confetti-5 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 89%;
  opacity: 0.8524477593;
  transform: rotate(3.7911939046deg);
  -webkit-animation: drop-5 4.2404004806s 0.5099705149s infinite;
          animation: drop-5 4.2404004806s 0.5099705149s infinite;
}

@-webkit-keyframes drop-5 {
  100% {
    top: 110%;
    left: 95%;
  }
}

@keyframes drop-5 {
  100% {
    top: 110%;
    left: 95%;
  }
}
.confetti-6 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 80%;
  opacity: 1.4385966758;
  transform: rotate(254.0043022257deg);
  -webkit-animation: drop-6 4.4712252898s 0.4671555439s infinite;
          animation: drop-6 4.4712252898s 0.4671555439s infinite;
}

@-webkit-keyframes drop-6 {
  100% {
    top: 110%;
    left: 84%;
  }
}

@keyframes drop-6 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-7 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 66%;
  opacity: 1.2127865513;
  transform: rotate(113.6772601719deg);
  -webkit-animation: drop-7 4.363104907s 0.7376664509s infinite;
          animation: drop-7 4.363104907s 0.7376664509s infinite;
}

@-webkit-keyframes drop-7 {
  100% {
    top: 110%;
    left: 69%;
  }
}

@keyframes drop-7 {
  100% {
    top: 110%;
    left: 69%;
  }
}
.confetti-8 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 43%;
  opacity: 0.8734259594;
  transform: rotate(102.157568267deg);
  -webkit-animation: drop-8 4.4920850297s 0.541683048s infinite;
          animation: drop-8 4.4920850297s 0.541683048s infinite;
}

@-webkit-keyframes drop-8 {
  100% {
    top: 110%;
    left: 56%;
  }
}

@keyframes drop-8 {
  100% {
    top: 110%;
    left: 56%;
  }
}
.confetti-9 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 59%;
  opacity: 0.8169798865;
  transform: rotate(26.864124355deg);
  -webkit-animation: drop-9 4.9121787156s 0.5271982534s infinite;
          animation: drop-9 4.9121787156s 0.5271982534s infinite;
}

@-webkit-keyframes drop-9 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-9 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.confetti-10 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 54%;
  opacity: 1.4923046612;
  transform: rotate(116.7873257425deg);
  -webkit-animation: drop-10 4.8683258832s 0.6902033379s infinite;
          animation: drop-10 4.8683258832s 0.6902033379s infinite;
}

@-webkit-keyframes drop-10 {
  100% {
    top: 110%;
    left: 59%;
  }
}

@keyframes drop-10 {
  100% {
    top: 110%;
    left: 59%;
  }
}
.confetti-11 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 56%;
  opacity: 0.6558346463;
  transform: rotate(103.2564323317deg);
  -webkit-animation: drop-11 4.6061401319s 0.6321908774s infinite;
          animation: drop-11 4.6061401319s 0.6321908774s infinite;
}

@-webkit-keyframes drop-11 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-11 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-12 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 62%;
  opacity: 0.9197625468;
  transform: rotate(72.7987015236deg);
  -webkit-animation: drop-12 4.8017599089s 0.8196708184s infinite;
          animation: drop-12 4.8017599089s 0.8196708184s infinite;
}

@-webkit-keyframes drop-12 {
  100% {
    top: 110%;
    left: 63%;
  }
}

@keyframes drop-12 {
  100% {
    top: 110%;
    left: 63%;
  }
}
.confetti-13 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 21%;
  opacity: 1.0324012011;
  transform: rotate(13.3703862105deg);
  -webkit-animation: drop-13 4.6338130401s 0.0826512272s infinite;
          animation: drop-13 4.6338130401s 0.0826512272s infinite;
}

@-webkit-keyframes drop-13 {
  100% {
    top: 110%;
    left: 26%;
  }
}

@keyframes drop-13 {
  100% {
    top: 110%;
    left: 26%;
  }
}
.confetti-14 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 24%;
  opacity: 0.6538944092;
  transform: rotate(158.6486625276deg);
  -webkit-animation: drop-14 4.7663247081s 0.6895248911s infinite;
          animation: drop-14 4.7663247081s 0.6895248911s infinite;
}

@-webkit-keyframes drop-14 {
  100% {
    top: 110%;
    left: 31%;
  }
}

@keyframes drop-14 {
  100% {
    top: 110%;
    left: 31%;
  }
}
.confetti-15 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 33%;
  opacity: 0.8104856266;
  transform: rotate(338.6740984854deg);
  -webkit-animation: drop-15 4.3410012614s 0.7868574182s infinite;
          animation: drop-15 4.3410012614s 0.7868574182s infinite;
}

@-webkit-keyframes drop-15 {
  100% {
    top: 110%;
    left: 41%;
  }
}

@keyframes drop-15 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-16 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 54%;
  opacity: 1.2075883873;
  transform: rotate(43.0393974521deg);
  -webkit-animation: drop-16 4.8529732758s 0.6479667878s infinite;
          animation: drop-16 4.8529732758s 0.6479667878s infinite;
}

@-webkit-keyframes drop-16 {
  100% {
    top: 110%;
    left: 55%;
  }
}

@keyframes drop-16 {
  100% {
    top: 110%;
    left: 55%;
  }
}
.confetti-17 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 94%;
  opacity: 0.5690314034;
  transform: rotate(237.714936675deg);
  -webkit-animation: drop-17 4.1925442861s 0.0674420041s infinite;
          animation: drop-17 4.1925442861s 0.0674420041s infinite;
}

@-webkit-keyframes drop-17 {
  100% {
    top: 110%;
    left: 107%;
  }
}

@keyframes drop-17 {
  100% {
    top: 110%;
    left: 107%;
  }
}
.confetti-18 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 54%;
  opacity: 1.1754278922;
  transform: rotate(28.1661818047deg);
  -webkit-animation: drop-18 4.6105054788s 0.6795990643s infinite;
          animation: drop-18 4.6105054788s 0.6795990643s infinite;
}

@-webkit-keyframes drop-18 {
  100% {
    top: 110%;
    left: 68%;
  }
}

@keyframes drop-18 {
  100% {
    top: 110%;
    left: 68%;
  }
}
.confetti-19 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 33%;
  opacity: 1.4050085191;
  transform: rotate(108.5410064157deg);
  -webkit-animation: drop-19 4.2417859544s 0.9782318522s infinite;
          animation: drop-19 4.2417859544s 0.9782318522s infinite;
}

@-webkit-keyframes drop-19 {
  100% {
    top: 110%;
    left: 46%;
  }
}

@keyframes drop-19 {
  100% {
    top: 110%;
    left: 46%;
  }
}
.confetti-20 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 4%;
  opacity: 1.1489504388;
  transform: rotate(286.5025166481deg);
  -webkit-animation: drop-20 4.0413017753s 0.1770946889s infinite;
          animation: drop-20 4.0413017753s 0.1770946889s infinite;
}

@-webkit-keyframes drop-20 {
  100% {
    top: 110%;
    left: 13%;
  }
}

@keyframes drop-20 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.confetti-21 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 83%;
  opacity: 0.7932785467;
  transform: rotate(125.924779288deg);
  -webkit-animation: drop-21 4.2691696093s 0.1341412362s infinite;
          animation: drop-21 4.2691696093s 0.1341412362s infinite;
}

@-webkit-keyframes drop-21 {
  100% {
    top: 110%;
    left: 89%;
  }
}

@keyframes drop-21 {
  100% {
    top: 110%;
    left: 89%;
  }
}
.confetti-22 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 37%;
  opacity: 0.6191662458;
  transform: rotate(273.3704087455deg);
  -webkit-animation: drop-22 4.1352703563s 0.3714395463s infinite;
          animation: drop-22 4.1352703563s 0.3714395463s infinite;
}

@-webkit-keyframes drop-22 {
  100% {
    top: 110%;
    left: 44%;
  }
}

@keyframes drop-22 {
  100% {
    top: 110%;
    left: 44%;
  }
}
.confetti-23 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 13%;
  opacity: 0.9785816781;
  transform: rotate(272.0603099799deg);
  -webkit-animation: drop-23 4.1228833965s 0.6442862129s infinite;
          animation: drop-23 4.1228833965s 0.6442862129s infinite;
}

@-webkit-keyframes drop-23 {
  100% {
    top: 110%;
    left: 18%;
  }
}

@keyframes drop-23 {
  100% {
    top: 110%;
    left: 18%;
  }
}
.confetti-24 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 93%;
  opacity: 0.6505133289;
  transform: rotate(69.5809288674deg);
  -webkit-animation: drop-24 4.4848136212s 0.4094716486s infinite;
          animation: drop-24 4.4848136212s 0.4094716486s infinite;
}

@-webkit-keyframes drop-24 {
  100% {
    top: 110%;
    left: 104%;
  }
}

@keyframes drop-24 {
  100% {
    top: 110%;
    left: 104%;
  }
}
.confetti-25 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 42%;
  opacity: 1.4408719107;
  transform: rotate(346.8508642063deg);
  -webkit-animation: drop-25 4.4093515196s 0.8146778712s infinite;
          animation: drop-25 4.4093515196s 0.8146778712s infinite;
}

@-webkit-keyframes drop-25 {
  100% {
    top: 110%;
    left: 43%;
  }
}

@keyframes drop-25 {
  100% {
    top: 110%;
    left: 43%;
  }
}
.confetti-26 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 94%;
  opacity: 1.2365342691;
  transform: rotate(274.7756040021deg);
  -webkit-animation: drop-26 4.6905628215s 0.7571094867s infinite;
          animation: drop-26 4.6905628215s 0.7571094867s infinite;
}

@-webkit-keyframes drop-26 {
  100% {
    top: 110%;
    left: 104%;
  }
}

@keyframes drop-26 {
  100% {
    top: 110%;
    left: 104%;
  }
}
.confetti-27 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 88%;
  opacity: 0.5299473299;
  transform: rotate(182.7039525212deg);
  -webkit-animation: drop-27 4.5447815718s 0.7431559212s infinite;
          animation: drop-27 4.5447815718s 0.7431559212s infinite;
}

@-webkit-keyframes drop-27 {
  100% {
    top: 110%;
    left: 94%;
  }
}

@keyframes drop-27 {
  100% {
    top: 110%;
    left: 94%;
  }
}
.confetti-28 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 5%;
  opacity: 1.3211942539;
  transform: rotate(13.534881629deg);
  -webkit-animation: drop-28 4.6889746691s 0.5333096019s infinite;
          animation: drop-28 4.6889746691s 0.5333096019s infinite;
}

@-webkit-keyframes drop-28 {
  100% {
    top: 110%;
    left: 11%;
  }
}

@keyframes drop-28 {
  100% {
    top: 110%;
    left: 11%;
  }
}
.confetti-29 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 50%;
  opacity: 0.8508297438;
  transform: rotate(147.7307828015deg);
  -webkit-animation: drop-29 4.8609913498s 0.1669231206s infinite;
          animation: drop-29 4.8609913498s 0.1669231206s infinite;
}

@-webkit-keyframes drop-29 {
  100% {
    top: 110%;
    left: 63%;
  }
}

@keyframes drop-29 {
  100% {
    top: 110%;
    left: 63%;
  }
}
.confetti-30 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 72%;
  opacity: 0.5545157393;
  transform: rotate(224.117870331deg);
  -webkit-animation: drop-30 4.3794671239s 0.5288100807s infinite;
          animation: drop-30 4.3794671239s 0.5288100807s infinite;
}

@-webkit-keyframes drop-30 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-30 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-31 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 51%;
  opacity: 0.9395997211;
  transform: rotate(349.2758107819deg);
  -webkit-animation: drop-31 4.6826435505s 0.8353061095s infinite;
          animation: drop-31 4.6826435505s 0.8353061095s infinite;
}

@-webkit-keyframes drop-31 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-31 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-32 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 66%;
  opacity: 0.6320429162;
  transform: rotate(256.1026941897deg);
  -webkit-animation: drop-32 4.6939516247s 0.9950816599s infinite;
          animation: drop-32 4.6939516247s 0.9950816599s infinite;
}

@-webkit-keyframes drop-32 {
  100% {
    top: 110%;
    left: 78%;
  }
}

@keyframes drop-32 {
  100% {
    top: 110%;
    left: 78%;
  }
}
.confetti-33 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 73%;
  opacity: 0.8833650051;
  transform: rotate(237.0187460536deg);
  -webkit-animation: drop-33 4.7874276653s 0.3034602612s infinite;
          animation: drop-33 4.7874276653s 0.3034602612s infinite;
}

@-webkit-keyframes drop-33 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-33 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-34 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 34%;
  opacity: 0.537390406;
  transform: rotate(89.9383768264deg);
  -webkit-animation: drop-34 4.1068157168s 0.4836829153s infinite;
          animation: drop-34 4.1068157168s 0.4836829153s infinite;
}

@-webkit-keyframes drop-34 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-34 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-35 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 25%;
  opacity: 0.7713856889;
  transform: rotate(159.1669588643deg);
  -webkit-animation: drop-35 4.551939216s 0.7725540668s infinite;
          animation: drop-35 4.551939216s 0.7725540668s infinite;
}

@-webkit-keyframes drop-35 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-35 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.confetti-36 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 8%;
  opacity: 0.7737202389;
  transform: rotate(56.408523402deg);
  -webkit-animation: drop-36 4.9039877076s 0.7325443953s infinite;
          animation: drop-36 4.9039877076s 0.7325443953s infinite;
}

@-webkit-keyframes drop-36 {
  100% {
    top: 110%;
    left: 14%;
  }
}

@keyframes drop-36 {
  100% {
    top: 110%;
    left: 14%;
  }
}
.confetti-37 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 97%;
  opacity: 1.2075281046;
  transform: rotate(296.7811275656deg);
  -webkit-animation: drop-37 4.6129639124s 0.539257857s infinite;
          animation: drop-37 4.6129639124s 0.539257857s infinite;
}

@-webkit-keyframes drop-37 {
  100% {
    top: 110%;
    left: 111%;
  }
}

@keyframes drop-37 {
  100% {
    top: 110%;
    left: 111%;
  }
}
.confetti-38 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 24%;
  opacity: 0.5248387633;
  transform: rotate(87.3080654935deg);
  -webkit-animation: drop-38 4.1649793472s 0.2468180857s infinite;
          animation: drop-38 4.1649793472s 0.2468180857s infinite;
}

@-webkit-keyframes drop-38 {
  100% {
    top: 110%;
    left: 38%;
  }
}

@keyframes drop-38 {
  100% {
    top: 110%;
    left: 38%;
  }
}
.confetti-39 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 38%;
  opacity: 1.3951200904;
  transform: rotate(191.4700499125deg);
  -webkit-animation: drop-39 4.6735136217s 0.3539524669s infinite;
          animation: drop-39 4.6735136217s 0.3539524669s infinite;
}

@-webkit-keyframes drop-39 {
  100% {
    top: 110%;
    left: 40%;
  }
}

@keyframes drop-39 {
  100% {
    top: 110%;
    left: 40%;
  }
}
.confetti-40 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 45%;
  opacity: 0.6939651149;
  transform: rotate(297.219345012deg);
  -webkit-animation: drop-40 4.0891995375s 0.5961379246s infinite;
          animation: drop-40 4.0891995375s 0.5961379246s infinite;
}

@-webkit-keyframes drop-40 {
  100% {
    top: 110%;
    left: 54%;
  }
}

@keyframes drop-40 {
  100% {
    top: 110%;
    left: 54%;
  }
}
.confetti-41 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 22%;
  opacity: 0.5325968958;
  transform: rotate(173.1408887691deg);
  -webkit-animation: drop-41 4.7083396642s 0.9399125869s infinite;
          animation: drop-41 4.7083396642s 0.9399125869s infinite;
}

@-webkit-keyframes drop-41 {
  100% {
    top: 110%;
    left: 36%;
  }
}

@keyframes drop-41 {
  100% {
    top: 110%;
    left: 36%;
  }
}
.confetti-42 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 29%;
  opacity: 1.370522727;
  transform: rotate(10.180094911deg);
  -webkit-animation: drop-42 4.6853008822s 0.0650591519s infinite;
          animation: drop-42 4.6853008822s 0.0650591519s infinite;
}

@-webkit-keyframes drop-42 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-42 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.confetti-43 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 60%;
  opacity: 1.3476757844;
  transform: rotate(317.0171987754deg);
  -webkit-animation: drop-43 4.2891074952s 0.4358625137s infinite;
          animation: drop-43 4.2891074952s 0.4358625137s infinite;
}

@-webkit-keyframes drop-43 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-43 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-44 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 58%;
  opacity: 1.0510216557;
  transform: rotate(41.0505112953deg);
  -webkit-animation: drop-44 4.7282402713s 0.2350922355s infinite;
          animation: drop-44 4.7282402713s 0.2350922355s infinite;
}

@-webkit-keyframes drop-44 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-44 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.confetti-45 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 68%;
  opacity: 0.8656493217;
  transform: rotate(163.9324829318deg);
  -webkit-animation: drop-45 4.8301997173s 0.2618188723s infinite;
          animation: drop-45 4.8301997173s 0.2618188723s infinite;
}

@-webkit-keyframes drop-45 {
  100% {
    top: 110%;
    left: 73%;
  }
}

@keyframes drop-45 {
  100% {
    top: 110%;
    left: 73%;
  }
}
.confetti-46 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 56%;
  opacity: 1.0704520718;
  transform: rotate(252.7726275515deg);
  -webkit-animation: drop-46 4.9031842679s 0.2868242403s infinite;
          animation: drop-46 4.9031842679s 0.2868242403s infinite;
}

@-webkit-keyframes drop-46 {
  100% {
    top: 110%;
    left: 61%;
  }
}

@keyframes drop-46 {
  100% {
    top: 110%;
    left: 61%;
  }
}
.confetti-47 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 68%;
  opacity: 1.0220448568;
  transform: rotate(119.7863278286deg);
  -webkit-animation: drop-47 4.1731420741s 0.5110073069s infinite;
          animation: drop-47 4.1731420741s 0.5110073069s infinite;
}

@-webkit-keyframes drop-47 {
  100% {
    top: 110%;
    left: 71%;
  }
}

@keyframes drop-47 {
  100% {
    top: 110%;
    left: 71%;
  }
}
.confetti-48 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 58%;
  opacity: 1.1860082632;
  transform: rotate(244.2281993991deg);
  -webkit-animation: drop-48 4.3066554427s 0.0416337147s infinite;
          animation: drop-48 4.3066554427s 0.0416337147s infinite;
}

@-webkit-keyframes drop-48 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-48 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-49 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 57%;
  opacity: 0.7778173862;
  transform: rotate(282.2998933978deg);
  -webkit-animation: drop-49 4.030713741s 0.48242637s infinite;
          animation: drop-49 4.030713741s 0.48242637s infinite;
}

@-webkit-keyframes drop-49 {
  100% {
    top: 110%;
    left: 64%;
  }
}

@keyframes drop-49 {
  100% {
    top: 110%;
    left: 64%;
  }
}
.confetti-50 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 90%;
  opacity: 0.799408688;
  transform: rotate(209.8106573136deg);
  -webkit-animation: drop-50 4.501713437s 0.7719201822s infinite;
          animation: drop-50 4.501713437s 0.7719201822s infinite;
}

@-webkit-keyframes drop-50 {
  100% {
    top: 110%;
    left: 91%;
  }
}

@keyframes drop-50 {
  100% {
    top: 110%;
    left: 91%;
  }
}
.confetti-51 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 78%;
  opacity: 1.1273397553;
  transform: rotate(328.9821478444deg);
  -webkit-animation: drop-51 4.4251842674s 0.694246555s infinite;
          animation: drop-51 4.4251842674s 0.694246555s infinite;
}

@-webkit-keyframes drop-51 {
  100% {
    top: 110%;
    left: 84%;
  }
}

@keyframes drop-51 {
  100% {
    top: 110%;
    left: 84%;
  }
}
.confetti-52 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 98%;
  opacity: 1.2810699412;
  transform: rotate(202.5537170392deg);
  -webkit-animation: drop-52 4.1107665653s 0.1954340629s infinite;
          animation: drop-52 4.1107665653s 0.1954340629s infinite;
}

@-webkit-keyframes drop-52 {
  100% {
    top: 110%;
    left: 111%;
  }
}

@keyframes drop-52 {
  100% {
    top: 110%;
    left: 111%;
  }
}
.confetti-53 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 98%;
  opacity: 0.7576241905;
  transform: rotate(121.0235644749deg);
  -webkit-animation: drop-53 4.1182115123s 0.8731692544s infinite;
          animation: drop-53 4.1182115123s 0.8731692544s infinite;
}

@-webkit-keyframes drop-53 {
  100% {
    top: 110%;
    left: 108%;
  }
}

@keyframes drop-53 {
  100% {
    top: 110%;
    left: 108%;
  }
}
.confetti-54 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 55%;
  opacity: 0.5241338361;
  transform: rotate(66.3879438247deg);
  -webkit-animation: drop-54 4.4395987213s 0.0844085562s infinite;
          animation: drop-54 4.4395987213s 0.0844085562s infinite;
}

@-webkit-keyframes drop-54 {
  100% {
    top: 110%;
    left: 59%;
  }
}

@keyframes drop-54 {
  100% {
    top: 110%;
    left: 59%;
  }
}
.confetti-55 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 76%;
  opacity: 0.9318716365;
  transform: rotate(143.7004234912deg);
  -webkit-animation: drop-55 4.3264164157s 0.517030918s infinite;
          animation: drop-55 4.3264164157s 0.517030918s infinite;
}

@-webkit-keyframes drop-55 {
  100% {
    top: 110%;
    left: 91%;
  }
}

@keyframes drop-55 {
  100% {
    top: 110%;
    left: 91%;
  }
}
.confetti-56 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 3%;
  opacity: 0.8969780581;
  transform: rotate(31.865483645deg);
  -webkit-animation: drop-56 4.2415870795s 0.0904803557s infinite;
          animation: drop-56 4.2415870795s 0.0904803557s infinite;
}

@-webkit-keyframes drop-56 {
  100% {
    top: 110%;
    left: 5%;
  }
}

@keyframes drop-56 {
  100% {
    top: 110%;
    left: 5%;
  }
}
.confetti-57 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 40%;
  opacity: 1.3046195548;
  transform: rotate(90.7004013101deg);
  -webkit-animation: drop-57 4.9862795407s 0.7200953981s infinite;
          animation: drop-57 4.9862795407s 0.7200953981s infinite;
}

@-webkit-keyframes drop-57 {
  100% {
    top: 110%;
    left: 47%;
  }
}

@keyframes drop-57 {
  100% {
    top: 110%;
    left: 47%;
  }
}
.confetti-58 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 11%;
  opacity: 1.4340488297;
  transform: rotate(76.9507992411deg);
  -webkit-animation: drop-58 4.8289109532s 0.8790576474s infinite;
          animation: drop-58 4.8289109532s 0.8790576474s infinite;
}

@-webkit-keyframes drop-58 {
  100% {
    top: 110%;
    left: 14%;
  }
}

@keyframes drop-58 {
  100% {
    top: 110%;
    left: 14%;
  }
}
.confetti-59 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 26%;
  opacity: 1.114683845;
  transform: rotate(342.2870212199deg);
  -webkit-animation: drop-59 4.7489069484s 0.8726887199s infinite;
          animation: drop-59 4.7489069484s 0.8726887199s infinite;
}

@-webkit-keyframes drop-59 {
  100% {
    top: 110%;
    left: 41%;
  }
}

@keyframes drop-59 {
  100% {
    top: 110%;
    left: 41%;
  }
}
.confetti-60 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 3%;
  opacity: 0.8812199421;
  transform: rotate(142.475102792deg);
  -webkit-animation: drop-60 4.9983838775s 0.504886044s infinite;
          animation: drop-60 4.9983838775s 0.504886044s infinite;
}

@-webkit-keyframes drop-60 {
  100% {
    top: 110%;
    left: 6%;
  }
}

@keyframes drop-60 {
  100% {
    top: 110%;
    left: 6%;
  }
}
.confetti-61 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 36%;
  opacity: 1.4206009237;
  transform: rotate(296.8945396774deg);
  -webkit-animation: drop-61 4.7722901093s 0.2486016942s infinite;
          animation: drop-61 4.7722901093s 0.2486016942s infinite;
}

@-webkit-keyframes drop-61 {
  100% {
    top: 110%;
    left: 43%;
  }
}

@keyframes drop-61 {
  100% {
    top: 110%;
    left: 43%;
  }
}
.confetti-62 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 66%;
  opacity: 1.3557255117;
  transform: rotate(318.9322644394deg);
  -webkit-animation: drop-62 4.0958286679s 0.6333785469s infinite;
          animation: drop-62 4.0958286679s 0.6333785469s infinite;
}

@-webkit-keyframes drop-62 {
  100% {
    top: 110%;
    left: 67%;
  }
}

@keyframes drop-62 {
  100% {
    top: 110%;
    left: 67%;
  }
}
.confetti-63 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 52%;
  opacity: 0.9658095019;
  transform: rotate(128.2443643575deg);
  -webkit-animation: drop-63 4.4034254902s 0.4387593624s infinite;
          animation: drop-63 4.4034254902s 0.4387593624s infinite;
}

@-webkit-keyframes drop-63 {
  100% {
    top: 110%;
    left: 57%;
  }
}

@keyframes drop-63 {
  100% {
    top: 110%;
    left: 57%;
  }
}
.confetti-64 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 82%;
  opacity: 1.0402495145;
  transform: rotate(182.697832014deg);
  -webkit-animation: drop-64 4.840576513s 0.1185447315s infinite;
          animation: drop-64 4.840576513s 0.1185447315s infinite;
}

@-webkit-keyframes drop-64 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-64 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-65 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 62%;
  opacity: 1.4067412401;
  transform: rotate(73.6936677927deg);
  -webkit-animation: drop-65 4.3804529594s 0.0026837986s infinite;
          animation: drop-65 4.3804529594s 0.0026837986s infinite;
}

@-webkit-keyframes drop-65 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-65 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-66 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 58%;
  opacity: 1.360712368;
  transform: rotate(272.4754856548deg);
  -webkit-animation: drop-66 4.8070034771s 0.6604270517s infinite;
          animation: drop-66 4.8070034771s 0.6604270517s infinite;
}

@-webkit-keyframes drop-66 {
  100% {
    top: 110%;
    left: 72%;
  }
}

@keyframes drop-66 {
  100% {
    top: 110%;
    left: 72%;
  }
}
.confetti-67 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 2%;
  opacity: 0.8418314657;
  transform: rotate(58.5056084143deg);
  -webkit-animation: drop-67 4.9390147396s 0.4819217486s infinite;
          animation: drop-67 4.9390147396s 0.4819217486s infinite;
}

@-webkit-keyframes drop-67 {
  100% {
    top: 110%;
    left: 13%;
  }
}

@keyframes drop-67 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.confetti-68 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 98%;
  opacity: 0.6382136167;
  transform: rotate(116.3427282953deg);
  -webkit-animation: drop-68 4.85655803s 0.6612922554s infinite;
          animation: drop-68 4.85655803s 0.6612922554s infinite;
}

@-webkit-keyframes drop-68 {
  100% {
    top: 110%;
    left: 101%;
  }
}

@keyframes drop-68 {
  100% {
    top: 110%;
    left: 101%;
  }
}
.confetti-69 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 59%;
  opacity: 1.240061256;
  transform: rotate(128.0401670586deg);
  -webkit-animation: drop-69 4.9548431719s 0.570394187s infinite;
          animation: drop-69 4.9548431719s 0.570394187s infinite;
}

@-webkit-keyframes drop-69 {
  100% {
    top: 110%;
    left: 73%;
  }
}

@keyframes drop-69 {
  100% {
    top: 110%;
    left: 73%;
  }
}
.confetti-70 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 86%;
  opacity: 0.9152967786;
  transform: rotate(252.0558943055deg);
  -webkit-animation: drop-70 4.9454436504s 0.5135204505s infinite;
          animation: drop-70 4.9454436504s 0.5135204505s infinite;
}

@-webkit-keyframes drop-70 {
  100% {
    top: 110%;
    left: 93%;
  }
}

@keyframes drop-70 {
  100% {
    top: 110%;
    left: 93%;
  }
}
.confetti-71 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 42%;
  opacity: 0.7030515199;
  transform: rotate(223.6791354783deg);
  -webkit-animation: drop-71 4.8989414325s 0.764235345s infinite;
          animation: drop-71 4.8989414325s 0.764235345s infinite;
}

@-webkit-keyframes drop-71 {
  100% {
    top: 110%;
    left: 50%;
  }
}

@keyframes drop-71 {
  100% {
    top: 110%;
    left: 50%;
  }
}
.confetti-72 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 76%;
  opacity: 0.5460400713;
  transform: rotate(127.0080141002deg);
  -webkit-animation: drop-72 4.6505823094s 0.4730399278s infinite;
          animation: drop-72 4.6505823094s 0.4730399278s infinite;
}

@-webkit-keyframes drop-72 {
  100% {
    top: 110%;
    left: 90%;
  }
}

@keyframes drop-72 {
  100% {
    top: 110%;
    left: 90%;
  }
}
.confetti-73 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 59%;
  opacity: 0.8016502065;
  transform: rotate(39.9715865485deg);
  -webkit-animation: drop-73 4.4392851745s 0.2414743089s infinite;
          animation: drop-73 4.4392851745s 0.2414743089s infinite;
}

@-webkit-keyframes drop-73 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-73 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-74 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 63%;
  opacity: 0.6171094575;
  transform: rotate(276.4050656151deg);
  -webkit-animation: drop-74 4.0489001078s 0.7930516019s infinite;
          animation: drop-74 4.0489001078s 0.7930516019s infinite;
}

@-webkit-keyframes drop-74 {
  100% {
    top: 110%;
    left: 77%;
  }
}

@keyframes drop-74 {
  100% {
    top: 110%;
    left: 77%;
  }
}
.confetti-75 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 6%;
  opacity: 0.9630035096;
  transform: rotate(58.9689176011deg);
  -webkit-animation: drop-75 4.9195160512s 0.8976514522s infinite;
          animation: drop-75 4.9195160512s 0.8976514522s infinite;
}

@-webkit-keyframes drop-75 {
  100% {
    top: 110%;
    left: 10%;
  }
}

@keyframes drop-75 {
  100% {
    top: 110%;
    left: 10%;
  }
}
.confetti-76 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 24%;
  opacity: 0.542604912;
  transform: rotate(180.0149304655deg);
  -webkit-animation: drop-76 4.8166515063s 0.0689785782s infinite;
          animation: drop-76 4.8166515063s 0.0689785782s infinite;
}

@-webkit-keyframes drop-76 {
  100% {
    top: 110%;
    left: 28%;
  }
}

@keyframes drop-76 {
  100% {
    top: 110%;
    left: 28%;
  }
}
.confetti-77 {
  width: 8px;
  height: 3.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 68%;
  opacity: 0.5116706665;
  transform: rotate(223.5372684068deg);
  -webkit-animation: drop-77 4.5350917675s 0.3253438795s infinite;
          animation: drop-77 4.5350917675s 0.3253438795s infinite;
}

@-webkit-keyframes drop-77 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-77 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-78 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 85%;
  opacity: 0.992990358;
  transform: rotate(247.1074700264deg);
  -webkit-animation: drop-78 4.0636671492s 0.7252025634s infinite;
          animation: drop-78 4.0636671492s 0.7252025634s infinite;
}

@-webkit-keyframes drop-78 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-78 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.confetti-79 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 85%;
  opacity: 0.6773927072;
  transform: rotate(220.9982887953deg);
  -webkit-animation: drop-79 4.3234258753s 0.1325864709s infinite;
          animation: drop-79 4.3234258753s 0.1325864709s infinite;
}

@-webkit-keyframes drop-79 {
  100% {
    top: 110%;
    left: 90%;
  }
}

@keyframes drop-79 {
  100% {
    top: 110%;
    left: 90%;
  }
}
.confetti-80 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 99%;
  opacity: 0.8895954143;
  transform: rotate(18.6431692958deg);
  -webkit-animation: drop-80 4.2774765687s 0.6999281614s infinite;
          animation: drop-80 4.2774765687s 0.6999281614s infinite;
}

@-webkit-keyframes drop-80 {
  100% {
    top: 110%;
    left: 112%;
  }
}

@keyframes drop-80 {
  100% {
    top: 110%;
    left: 112%;
  }
}
.confetti-81 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 38%;
  opacity: 0.9473743773;
  transform: rotate(15.3195712356deg);
  -webkit-animation: drop-81 4.1491787873s 0.9355133491s infinite;
          animation: drop-81 4.1491787873s 0.9355133491s infinite;
}

@-webkit-keyframes drop-81 {
  100% {
    top: 110%;
    left: 49%;
  }
}

@keyframes drop-81 {
  100% {
    top: 110%;
    left: 49%;
  }
}
.confetti-82 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 11%;
  opacity: 1.4731796727;
  transform: rotate(346.5877918314deg);
  -webkit-animation: drop-82 4.5352136522s 0.8956490281s infinite;
          animation: drop-82 4.5352136522s 0.8956490281s infinite;
}

@-webkit-keyframes drop-82 {
  100% {
    top: 110%;
    left: 21%;
  }
}

@keyframes drop-82 {
  100% {
    top: 110%;
    left: 21%;
  }
}
.confetti-83 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 90%;
  opacity: 0.9330144126;
  transform: rotate(296.0905772788deg);
  -webkit-animation: drop-83 4.784439022s 0.7708512391s infinite;
          animation: drop-83 4.784439022s 0.7708512391s infinite;
}

@-webkit-keyframes drop-83 {
  100% {
    top: 110%;
    left: 92%;
  }
}

@keyframes drop-83 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-84 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 89%;
  opacity: 1.3723582658;
  transform: rotate(204.4238279766deg);
  -webkit-animation: drop-84 4.4629558588s 0.2780315433s infinite;
          animation: drop-84 4.4629558588s 0.2780315433s infinite;
}

@-webkit-keyframes drop-84 {
  100% {
    top: 110%;
    left: 90%;
  }
}

@keyframes drop-84 {
  100% {
    top: 110%;
    left: 90%;
  }
}
.confetti-85 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 71%;
  opacity: 0.5077039706;
  transform: rotate(278.3610251561deg);
  -webkit-animation: drop-85 4.4941048298s 0.3022599178s infinite;
          animation: drop-85 4.4941048298s 0.3022599178s infinite;
}

@-webkit-keyframes drop-85 {
  100% {
    top: 110%;
    left: 83%;
  }
}

@keyframes drop-85 {
  100% {
    top: 110%;
    left: 83%;
  }
}
.confetti-86 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 47%;
  opacity: 0.7591551716;
  transform: rotate(226.6765202434deg);
  -webkit-animation: drop-86 4.5785564741s 0.6489465921s infinite;
          animation: drop-86 4.5785564741s 0.6489465921s infinite;
}

@-webkit-keyframes drop-86 {
  100% {
    top: 110%;
    left: 51%;
  }
}

@keyframes drop-86 {
  100% {
    top: 110%;
    left: 51%;
  }
}
.confetti-87 {
  width: 6px;
  height: 2.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 76%;
  opacity: 1.2054273937;
  transform: rotate(266.4069266433deg);
  -webkit-animation: drop-87 4.3152236162s 0.2473499563s infinite;
          animation: drop-87 4.3152236162s 0.2473499563s infinite;
}

@-webkit-keyframes drop-87 {
  100% {
    top: 110%;
    left: 88%;
  }
}

@keyframes drop-87 {
  100% {
    top: 110%;
    left: 88%;
  }
}
.confetti-88 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 44%;
  opacity: 1.2027451396;
  transform: rotate(240.9425333332deg);
  -webkit-animation: drop-88 4.8306972739s 0.4945813424s infinite;
          animation: drop-88 4.8306972739s 0.4945813424s infinite;
}

@-webkit-keyframes drop-88 {
  100% {
    top: 110%;
    left: 53%;
  }
}

@keyframes drop-88 {
  100% {
    top: 110%;
    left: 53%;
  }
}
.confetti-89 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 26%;
  opacity: 0.9540446892;
  transform: rotate(273.2226014561deg);
  -webkit-animation: drop-89 4.0160842773s 0.4910471122s infinite;
          animation: drop-89 4.0160842773s 0.4910471122s infinite;
}

@-webkit-keyframes drop-89 {
  100% {
    top: 110%;
    left: 32%;
  }
}

@keyframes drop-89 {
  100% {
    top: 110%;
    left: 32%;
  }
}
.confetti-90 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 48%;
  opacity: 1.3583082651;
  transform: rotate(354.5298830514deg);
  -webkit-animation: drop-90 4.7222349193s 0.8251338712s infinite;
          animation: drop-90 4.7222349193s 0.8251338712s infinite;
}

@-webkit-keyframes drop-90 {
  100% {
    top: 110%;
    left: 56%;
  }
}

@keyframes drop-90 {
  100% {
    top: 110%;
    left: 56%;
  }
}
.confetti-91 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 86%;
  opacity: 0.8202932371;
  transform: rotate(72.5664715086deg);
  -webkit-animation: drop-91 4.3843398094s 0.0228482877s infinite;
          animation: drop-91 4.3843398094s 0.0228482877s infinite;
}

@-webkit-keyframes drop-91 {
  100% {
    top: 110%;
    left: 99%;
  }
}

@keyframes drop-91 {
  100% {
    top: 110%;
    left: 99%;
  }
}
.confetti-92 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 12%;
  opacity: 0.8455045825;
  transform: rotate(241.5855582719deg);
  -webkit-animation: drop-92 4.635632195s 0.5978166538s infinite;
          animation: drop-92 4.635632195s 0.5978166538s infinite;
}

@-webkit-keyframes drop-92 {
  100% {
    top: 110%;
    left: 15%;
  }
}

@keyframes drop-92 {
  100% {
    top: 110%;
    left: 15%;
  }
}
.confetti-93 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 22%;
  opacity: 0.6260249406;
  transform: rotate(49.2474308933deg);
  -webkit-animation: drop-93 4.4823089206s 0.696198811s infinite;
          animation: drop-93 4.4823089206s 0.696198811s infinite;
}

@-webkit-keyframes drop-93 {
  100% {
    top: 110%;
    left: 32%;
  }
}

@keyframes drop-93 {
  100% {
    top: 110%;
    left: 32%;
  }
}
.confetti-94 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 69%;
  opacity: 0.8108941016;
  transform: rotate(319.5390580058deg);
  -webkit-animation: drop-94 4.1291940872s 0.327879616s infinite;
          animation: drop-94 4.1291940872s 0.327879616s infinite;
}

@-webkit-keyframes drop-94 {
  100% {
    top: 110%;
    left: 80%;
  }
}

@keyframes drop-94 {
  100% {
    top: 110%;
    left: 80%;
  }
}
.confetti-95 {
  width: 5px;
  height: 2px;
  background-color: #ffbf00;
  top: -10%;
  left: 16%;
  opacity: 0.6101579127;
  transform: rotate(200.8212803556deg);
  -webkit-animation: drop-95 4.5945515683s 0.1713259505s infinite;
          animation: drop-95 4.5945515683s 0.1713259505s infinite;
}

@-webkit-keyframes drop-95 {
  100% {
    top: 110%;
    left: 26%;
  }
}

@keyframes drop-95 {
  100% {
    top: 110%;
    left: 26%;
  }
}
.confetti-96 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 76%;
  opacity: 1.2382281134;
  transform: rotate(333.6552308821deg);
  -webkit-animation: drop-96 4.6902683829s 0.6178543288s infinite;
          animation: drop-96 4.6902683829s 0.6178543288s infinite;
}

@-webkit-keyframes drop-96 {
  100% {
    top: 110%;
    left: 87%;
  }
}

@keyframes drop-96 {
  100% {
    top: 110%;
    left: 87%;
  }
}
.confetti-97 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 45%;
  opacity: 0.7761989637;
  transform: rotate(115.3240528373deg);
  -webkit-animation: drop-97 4.8610816937s 0.0756281154s infinite;
          animation: drop-97 4.8610816937s 0.0756281154s infinite;
}

@-webkit-keyframes drop-97 {
  100% {
    top: 110%;
    left: 56%;
  }
}

@keyframes drop-97 {
  100% {
    top: 110%;
    left: 56%;
  }
}
.confetti-98 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 58%;
  opacity: 0.7388674839;
  transform: rotate(236.0351372778deg);
  -webkit-animation: drop-98 4.8961961863s 0.8454923158s infinite;
          animation: drop-98 4.8961961863s 0.8454923158s infinite;
}

@-webkit-keyframes drop-98 {
  100% {
    top: 110%;
    left: 62%;
  }
}

@keyframes drop-98 {
  100% {
    top: 110%;
    left: 62%;
  }
}
.confetti-99 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 75%;
  opacity: 0.5454448877;
  transform: rotate(37.744223878deg);
  -webkit-animation: drop-99 4.9126996961s 0.8089936061s infinite;
          animation: drop-99 4.9126996961s 0.8089936061s infinite;
}

@-webkit-keyframes drop-99 {
  100% {
    top: 110%;
    left: 83%;
  }
}

@keyframes drop-99 {
  100% {
    top: 110%;
    left: 83%;
  }
}
.confetti-100 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 89%;
  opacity: 0.8418491375;
  transform: rotate(95.3774685746deg);
  -webkit-animation: drop-100 4.0678555105s 0.3114888024s infinite;
          animation: drop-100 4.0678555105s 0.3114888024s infinite;
}

@-webkit-keyframes drop-100 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-100 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.confetti-101 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 93%;
  opacity: 1.1075031521;
  transform: rotate(281.9729758781deg);
  -webkit-animation: drop-101 4.2475071458s 0.0534548734s infinite;
          animation: drop-101 4.2475071458s 0.0534548734s infinite;
}

@-webkit-keyframes drop-101 {
  100% {
    top: 110%;
    left: 103%;
  }
}

@keyframes drop-101 {
  100% {
    top: 110%;
    left: 103%;
  }
}
.confetti-102 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 79%;
  opacity: 0.5849256094;
  transform: rotate(1.1840082263deg);
  -webkit-animation: drop-102 4.4440863602s 0.1411246459s infinite;
          animation: drop-102 4.4440863602s 0.1411246459s infinite;
}

@-webkit-keyframes drop-102 {
  100% {
    top: 110%;
    left: 89%;
  }
}

@keyframes drop-102 {
  100% {
    top: 110%;
    left: 89%;
  }
}
.confetti-103 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 33%;
  opacity: 0.5328117175;
  transform: rotate(311.5560457981deg);
  -webkit-animation: drop-103 4.9700046991s 0.1367825302s infinite;
          animation: drop-103 4.9700046991s 0.1367825302s infinite;
}

@-webkit-keyframes drop-103 {
  100% {
    top: 110%;
    left: 45%;
  }
}

@keyframes drop-103 {
  100% {
    top: 110%;
    left: 45%;
  }
}
.confetti-104 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 53%;
  opacity: 1.0476722121;
  transform: rotate(193.2022429143deg);
  -webkit-animation: drop-104 4.9529976801s 0.746008726s infinite;
          animation: drop-104 4.9529976801s 0.746008726s infinite;
}

@-webkit-keyframes drop-104 {
  100% {
    top: 110%;
    left: 56%;
  }
}

@keyframes drop-104 {
  100% {
    top: 110%;
    left: 56%;
  }
}
.confetti-105 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 20%;
  opacity: 1.0603059393;
  transform: rotate(171.0326070644deg);
  -webkit-animation: drop-105 4.2395028825s 0.9142088512s infinite;
          animation: drop-105 4.2395028825s 0.9142088512s infinite;
}

@-webkit-keyframes drop-105 {
  100% {
    top: 110%;
    left: 25%;
  }
}

@keyframes drop-105 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.confetti-106 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 55%;
  opacity: 0.6470964309;
  transform: rotate(344.4367476993deg);
  -webkit-animation: drop-106 4.9993362644s 0.0101249263s infinite;
          animation: drop-106 4.9993362644s 0.0101249263s infinite;
}

@-webkit-keyframes drop-106 {
  100% {
    top: 110%;
    left: 63%;
  }
}

@keyframes drop-106 {
  100% {
    top: 110%;
    left: 63%;
  }
}
.confetti-107 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 24%;
  opacity: 0.8072234465;
  transform: rotate(240.0478696067deg);
  -webkit-animation: drop-107 4.9254565281s 0.9029447592s infinite;
          animation: drop-107 4.9254565281s 0.9029447592s infinite;
}

@-webkit-keyframes drop-107 {
  100% {
    top: 110%;
    left: 36%;
  }
}

@keyframes drop-107 {
  100% {
    top: 110%;
    left: 36%;
  }
}
.confetti-108 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 57%;
  opacity: 0.7097664331;
  transform: rotate(181.4539975486deg);
  -webkit-animation: drop-108 4.6384719721s 0.1144815978s infinite;
          animation: drop-108 4.6384719721s 0.1144815978s infinite;
}

@-webkit-keyframes drop-108 {
  100% {
    top: 110%;
    left: 61%;
  }
}

@keyframes drop-108 {
  100% {
    top: 110%;
    left: 61%;
  }
}
.confetti-109 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 62%;
  opacity: 0.939022719;
  transform: rotate(39.8904921237deg);
  -webkit-animation: drop-109 4.6789653012s 0.5025449028s infinite;
          animation: drop-109 4.6789653012s 0.5025449028s infinite;
}

@-webkit-keyframes drop-109 {
  100% {
    top: 110%;
    left: 69%;
  }
}

@keyframes drop-109 {
  100% {
    top: 110%;
    left: 69%;
  }
}
.confetti-110 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 41%;
  opacity: 1.3627928825;
  transform: rotate(42.241223043deg);
  -webkit-animation: drop-110 4.2189066386s 0.0766597044s infinite;
          animation: drop-110 4.2189066386s 0.0766597044s infinite;
}

@-webkit-keyframes drop-110 {
  100% {
    top: 110%;
    left: 54%;
  }
}

@keyframes drop-110 {
  100% {
    top: 110%;
    left: 54%;
  }
}
.confetti-111 {
  width: 4px;
  height: 1.6px;
  background-color: #d13447;
  top: -10%;
  left: 55%;
  opacity: 0.6214107414;
  transform: rotate(354.1373734935deg);
  -webkit-animation: drop-111 4.5348481444s 0.1559006371s infinite;
          animation: drop-111 4.5348481444s 0.1559006371s infinite;
}

@-webkit-keyframes drop-111 {
  100% {
    top: 110%;
    left: 62%;
  }
}

@keyframes drop-111 {
  100% {
    top: 110%;
    left: 62%;
  }
}
.confetti-112 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 97%;
  opacity: 0.6186377207;
  transform: rotate(338.5578503201deg);
  -webkit-animation: drop-112 4.1282081373s 0.7552415213s infinite;
          animation: drop-112 4.1282081373s 0.7552415213s infinite;
}

@-webkit-keyframes drop-112 {
  100% {
    top: 110%;
    left: 98%;
  }
}

@keyframes drop-112 {
  100% {
    top: 110%;
    left: 98%;
  }
}
.confetti-113 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 73%;
  opacity: 1.3723562713;
  transform: rotate(149.6763433502deg);
  -webkit-animation: drop-113 4.9332694243s 0.4821708202s infinite;
          animation: drop-113 4.9332694243s 0.4821708202s infinite;
}

@-webkit-keyframes drop-113 {
  100% {
    top: 110%;
    left: 76%;
  }
}

@keyframes drop-113 {
  100% {
    top: 110%;
    left: 76%;
  }
}
.confetti-114 {
  width: 8px;
  height: 3.2px;
  background-color: #d13447;
  top: -10%;
  left: 21%;
  opacity: 0.7210165711;
  transform: rotate(68.9284449081deg);
  -webkit-animation: drop-114 4.338962957s 0.7067365438s infinite;
          animation: drop-114 4.338962957s 0.7067365438s infinite;
}

@-webkit-keyframes drop-114 {
  100% {
    top: 110%;
    left: 25%;
  }
}

@keyframes drop-114 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.confetti-115 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 30%;
  opacity: 0.5131376066;
  transform: rotate(287.3413253292deg);
  -webkit-animation: drop-115 4.5156588904s 0.8195685863s infinite;
          animation: drop-115 4.5156588904s 0.8195685863s infinite;
}

@-webkit-keyframes drop-115 {
  100% {
    top: 110%;
    left: 39%;
  }
}

@keyframes drop-115 {
  100% {
    top: 110%;
    left: 39%;
  }
}
.confetti-116 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 64%;
  opacity: 0.8472772709;
  transform: rotate(259.8707201847deg);
  -webkit-animation: drop-116 4.1277271214s 0.649148374s infinite;
          animation: drop-116 4.1277271214s 0.649148374s infinite;
}

@-webkit-keyframes drop-116 {
  100% {
    top: 110%;
    left: 68%;
  }
}

@keyframes drop-116 {
  100% {
    top: 110%;
    left: 68%;
  }
}
.confetti-117 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 11%;
  opacity: 0.8274872631;
  transform: rotate(9.9311087234deg);
  -webkit-animation: drop-117 4.0202974463s 0.0551390704s infinite;
          animation: drop-117 4.0202974463s 0.0551390704s infinite;
}

@-webkit-keyframes drop-117 {
  100% {
    top: 110%;
    left: 13%;
  }
}

@keyframes drop-117 {
  100% {
    top: 110%;
    left: 13%;
  }
}
.confetti-118 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 65%;
  opacity: 0.8281776911;
  transform: rotate(285.560388488deg);
  -webkit-animation: drop-118 4.3056946775s 0.7027151839s infinite;
          animation: drop-118 4.3056946775s 0.7027151839s infinite;
}

@-webkit-keyframes drop-118 {
  100% {
    top: 110%;
    left: 73%;
  }
}

@keyframes drop-118 {
  100% {
    top: 110%;
    left: 73%;
  }
}
.confetti-119 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 21%;
  opacity: 1.4054787542;
  transform: rotate(345.2316601978deg);
  -webkit-animation: drop-119 4.5652765641s 0.3380794764s infinite;
          animation: drop-119 4.5652765641s 0.3380794764s infinite;
}

@-webkit-keyframes drop-119 {
  100% {
    top: 110%;
    left: 34%;
  }
}

@keyframes drop-119 {
  100% {
    top: 110%;
    left: 34%;
  }
}
.confetti-120 {
  width: 1px;
  height: 0.4px;
  background-color: #ffbf00;
  top: -10%;
  left: 67%;
  opacity: 0.9600973241;
  transform: rotate(202.099189894deg);
  -webkit-animation: drop-120 4.5273011311s 0.9232302779s infinite;
          animation: drop-120 4.5273011311s 0.9232302779s infinite;
}

@-webkit-keyframes drop-120 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-120 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-121 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 80%;
  opacity: 0.9291732647;
  transform: rotate(358.69531102deg);
  -webkit-animation: drop-121 4.2042289556s 0.0164090169s infinite;
          animation: drop-121 4.2042289556s 0.0164090169s infinite;
}

@-webkit-keyframes drop-121 {
  100% {
    top: 110%;
    left: 85%;
  }
}

@keyframes drop-121 {
  100% {
    top: 110%;
    left: 85%;
  }
}
.confetti-122 {
  width: 2px;
  height: 0.8px;
  background-color: #d13447;
  top: -10%;
  left: 99%;
  opacity: 1.2617127486;
  transform: rotate(209.964354682deg);
  -webkit-animation: drop-122 4.4013526668s 0.1018673079s infinite;
          animation: drop-122 4.4013526668s 0.1018673079s infinite;
}

@-webkit-keyframes drop-122 {
  100% {
    top: 110%;
    left: 106%;
  }
}

@keyframes drop-122 {
  100% {
    top: 110%;
    left: 106%;
  }
}
.confetti-123 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 30%;
  opacity: 1.3070245993;
  transform: rotate(128.1009517712deg);
  -webkit-animation: drop-123 4.9655343754s 0.0824323563s infinite;
          animation: drop-123 4.9655343754s 0.0824323563s infinite;
}

@-webkit-keyframes drop-123 {
  100% {
    top: 110%;
    left: 43%;
  }
}

@keyframes drop-123 {
  100% {
    top: 110%;
    left: 43%;
  }
}
.confetti-124 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 24%;
  opacity: 1.0302086461;
  transform: rotate(51.8962492143deg);
  -webkit-animation: drop-124 4.6882172914s 0.4374201275s infinite;
          animation: drop-124 4.6882172914s 0.4374201275s infinite;
}

@-webkit-keyframes drop-124 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-124 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-125 {
  width: 7px;
  height: 2.8px;
  background-color: #263672;
  top: -10%;
  left: 86%;
  opacity: 1.1273060145;
  transform: rotate(290.6207169899deg);
  -webkit-animation: drop-125 4.5807249521s 0.3147021581s infinite;
          animation: drop-125 4.5807249521s 0.3147021581s infinite;
}

@-webkit-keyframes drop-125 {
  100% {
    top: 110%;
    left: 94%;
  }
}

@keyframes drop-125 {
  100% {
    top: 110%;
    left: 94%;
  }
}
.confetti-126 {
  width: 6px;
  height: 2.4px;
  background-color: #d13447;
  top: -10%;
  left: 22%;
  opacity: 0.6678284761;
  transform: rotate(164.9022577597deg);
  -webkit-animation: drop-126 4.8730867506s 0.4486717992s infinite;
          animation: drop-126 4.8730867506s 0.4486717992s infinite;
}

@-webkit-keyframes drop-126 {
  100% {
    top: 110%;
    left: 30%;
  }
}

@keyframes drop-126 {
  100% {
    top: 110%;
    left: 30%;
  }
}
.confetti-127 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 68%;
  opacity: 0.6777124064;
  transform: rotate(278.4039748965deg);
  -webkit-animation: drop-127 4.055021751s 0.0891451674s infinite;
          animation: drop-127 4.055021751s 0.0891451674s infinite;
}

@-webkit-keyframes drop-127 {
  100% {
    top: 110%;
    left: 82%;
  }
}

@keyframes drop-127 {
  100% {
    top: 110%;
    left: 82%;
  }
}
.confetti-128 {
  width: 5px;
  height: 2px;
  background-color: #d13447;
  top: -10%;
  left: 54%;
  opacity: 1.3094824506;
  transform: rotate(186.4862427954deg);
  -webkit-animation: drop-128 4.1543744114s 0.8405559604s infinite;
          animation: drop-128 4.1543744114s 0.8405559604s infinite;
}

@-webkit-keyframes drop-128 {
  100% {
    top: 110%;
    left: 65%;
  }
}

@keyframes drop-128 {
  100% {
    top: 110%;
    left: 65%;
  }
}
.confetti-129 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 13%;
  opacity: 0.8946173372;
  transform: rotate(142.3835373204deg);
  -webkit-animation: drop-129 4.079403529s 0.0699187969s infinite;
          animation: drop-129 4.079403529s 0.0699187969s infinite;
}

@-webkit-keyframes drop-129 {
  100% {
    top: 110%;
    left: 25%;
  }
}

@keyframes drop-129 {
  100% {
    top: 110%;
    left: 25%;
  }
}
.confetti-130 {
  width: 6px;
  height: 2.4px;
  background-color: #263672;
  top: -10%;
  left: 64%;
  opacity: 1.4156803419;
  transform: rotate(347.5300844986deg);
  -webkit-animation: drop-130 4.0863422208s 0.884254934s infinite;
          animation: drop-130 4.0863422208s 0.884254934s infinite;
}

@-webkit-keyframes drop-130 {
  100% {
    top: 110%;
    left: 70%;
  }
}

@keyframes drop-130 {
  100% {
    top: 110%;
    left: 70%;
  }
}
.confetti-131 {
  width: 7px;
  height: 2.8px;
  background-color: #d13447;
  top: -10%;
  left: 72%;
  opacity: 0.5370651801;
  transform: rotate(234.4223001703deg);
  -webkit-animation: drop-131 4.5805517711s 0.1307348103s infinite;
          animation: drop-131 4.5805517711s 0.1307348103s infinite;
}

@-webkit-keyframes drop-131 {
  100% {
    top: 110%;
    left: 75%;
  }
}

@keyframes drop-131 {
  100% {
    top: 110%;
    left: 75%;
  }
}
.confetti-132 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 49%;
  opacity: 0.7886789967;
  transform: rotate(313.4973689689deg);
  -webkit-animation: drop-132 4.4987007173s 0.2979690741s infinite;
          animation: drop-132 4.4987007173s 0.2979690741s infinite;
}

@-webkit-keyframes drop-132 {
  100% {
    top: 110%;
    left: 58%;
  }
}

@keyframes drop-132 {
  100% {
    top: 110%;
    left: 58%;
  }
}
.confetti-133 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 91%;
  opacity: 0.565417424;
  transform: rotate(59.7047230275deg);
  -webkit-animation: drop-133 4.033393168s 0.0563626497s infinite;
          animation: drop-133 4.033393168s 0.0563626497s infinite;
}

@-webkit-keyframes drop-133 {
  100% {
    top: 110%;
    left: 106%;
  }
}

@keyframes drop-133 {
  100% {
    top: 110%;
    left: 106%;
  }
}
.confetti-134 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 2%;
  opacity: 1.0712394131;
  transform: rotate(331.4676366765deg);
  -webkit-animation: drop-134 4.7929529787s 0.2756374366s infinite;
          animation: drop-134 4.7929529787s 0.2756374366s infinite;
}

@-webkit-keyframes drop-134 {
  100% {
    top: 110%;
    left: 12%;
  }
}

@keyframes drop-134 {
  100% {
    top: 110%;
    left: 12%;
  }
}
.confetti-135 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 71%;
  opacity: 1.3474691547;
  transform: rotate(246.6559985602deg);
  -webkit-animation: drop-135 4.7019512375s 0.9565222152s infinite;
          animation: drop-135 4.7019512375s 0.9565222152s infinite;
}

@-webkit-keyframes drop-135 {
  100% {
    top: 110%;
    left: 77%;
  }
}

@keyframes drop-135 {
  100% {
    top: 110%;
    left: 77%;
  }
}
.confetti-136 {
  width: 7px;
  height: 2.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 67%;
  opacity: 0.6025404846;
  transform: rotate(191.9094319133deg);
  -webkit-animation: drop-136 4.8077519373s 0.5769988098s infinite;
          animation: drop-136 4.8077519373s 0.5769988098s infinite;
}

@-webkit-keyframes drop-136 {
  100% {
    top: 110%;
    left: 77%;
  }
}

@keyframes drop-136 {
  100% {
    top: 110%;
    left: 77%;
  }
}
.confetti-137 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 18%;
  opacity: 0.7953631719;
  transform: rotate(253.290231535deg);
  -webkit-animation: drop-137 4.4752934604s 0.164698351s infinite;
          animation: drop-137 4.4752934604s 0.164698351s infinite;
}

@-webkit-keyframes drop-137 {
  100% {
    top: 110%;
    left: 29%;
  }
}

@keyframes drop-137 {
  100% {
    top: 110%;
    left: 29%;
  }
}
.confetti-138 {
  width: 4px;
  height: 1.6px;
  background-color: #263672;
  top: -10%;
  left: 88%;
  opacity: 0.5012936754;
  transform: rotate(218.3237616982deg);
  -webkit-animation: drop-138 4.0365822996s 0.1721162489s infinite;
          animation: drop-138 4.0365822996s 0.1721162489s infinite;
}

@-webkit-keyframes drop-138 {
  100% {
    top: 110%;
    left: 92%;
  }
}

@keyframes drop-138 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-139 {
  width: 5px;
  height: 2px;
  background-color: #263672;
  top: -10%;
  left: 20%;
  opacity: 0.9107196148;
  transform: rotate(220.3706911408deg);
  -webkit-animation: drop-139 4.4421823642s 0.6031646095s infinite;
          animation: drop-139 4.4421823642s 0.6031646095s infinite;
}

@-webkit-keyframes drop-139 {
  100% {
    top: 110%;
    left: 22%;
  }
}

@keyframes drop-139 {
  100% {
    top: 110%;
    left: 22%;
  }
}
.confetti-140 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 5%;
  opacity: 0.7459528979;
  transform: rotate(156.4026269403deg);
  -webkit-animation: drop-140 4.1586176722s 0.9584896206s infinite;
          animation: drop-140 4.1586176722s 0.9584896206s infinite;
}

@-webkit-keyframes drop-140 {
  100% {
    top: 110%;
    left: 7%;
  }
}

@keyframes drop-140 {
  100% {
    top: 110%;
    left: 7%;
  }
}
.confetti-141 {
  width: 3px;
  height: 1.2px;
  background-color: #263672;
  top: -10%;
  left: 5%;
  opacity: 0.6240734701;
  transform: rotate(117.7004610757deg);
  -webkit-animation: drop-141 4.4595217974s 0.2477589964s infinite;
          animation: drop-141 4.4595217974s 0.2477589964s infinite;
}

@-webkit-keyframes drop-141 {
  100% {
    top: 110%;
    left: 20%;
  }
}

@keyframes drop-141 {
  100% {
    top: 110%;
    left: 20%;
  }
}
.confetti-142 {
  width: 1px;
  height: 0.4px;
  background-color: #d13447;
  top: -10%;
  left: 33%;
  opacity: 1.2938177153;
  transform: rotate(33.1738975029deg);
  -webkit-animation: drop-142 4.2823899486s 0.4181261309s infinite;
          animation: drop-142 4.2823899486s 0.4181261309s infinite;
}

@-webkit-keyframes drop-142 {
  100% {
    top: 110%;
    left: 37%;
  }
}

@keyframes drop-142 {
  100% {
    top: 110%;
    left: 37%;
  }
}
.confetti-143 {
  width: 3px;
  height: 1.2px;
  background-color: #ffbf00;
  top: -10%;
  left: 80%;
  opacity: 1.2633695501;
  transform: rotate(90.6128699585deg);
  -webkit-animation: drop-143 4.6499815161s 0.7654519258s infinite;
          animation: drop-143 4.6499815161s 0.7654519258s infinite;
}

@-webkit-keyframes drop-143 {
  100% {
    top: 110%;
    left: 83%;
  }
}

@keyframes drop-143 {
  100% {
    top: 110%;
    left: 83%;
  }
}
.confetti-144 {
  width: 2px;
  height: 0.8px;
  background-color: #ffbf00;
  top: -10%;
  left: 4%;
  opacity: 0.5896357253;
  transform: rotate(252.1139138209deg);
  -webkit-animation: drop-144 4.2500094053s 0.8507812221s infinite;
          animation: drop-144 4.2500094053s 0.8507812221s infinite;
}

@-webkit-keyframes drop-144 {
  100% {
    top: 110%;
    left: 9%;
  }
}

@keyframes drop-144 {
  100% {
    top: 110%;
    left: 9%;
  }
}
.confetti-145 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 63%;
  opacity: 1.0429260829;
  transform: rotate(114.9316248856deg);
  -webkit-animation: drop-145 4.5824292071s 0.6763378873s infinite;
          animation: drop-145 4.5824292071s 0.6763378873s infinite;
}

@-webkit-keyframes drop-145 {
  100% {
    top: 110%;
    left: 68%;
  }
}

@keyframes drop-145 {
  100% {
    top: 110%;
    left: 68%;
  }
}
.confetti-146 {
  width: 2px;
  height: 0.8px;
  background-color: #263672;
  top: -10%;
  left: 77%;
  opacity: 1.0419074701;
  transform: rotate(110.3601164852deg);
  -webkit-animation: drop-146 4.9849814442s 0.3174171313s infinite;
          animation: drop-146 4.9849814442s 0.3174171313s infinite;
}

@-webkit-keyframes drop-146 {
  100% {
    top: 110%;
    left: 92%;
  }
}

@keyframes drop-146 {
  100% {
    top: 110%;
    left: 92%;
  }
}
.confetti-147 {
  width: 8px;
  height: 3.2px;
  background-color: #263672;
  top: -10%;
  left: 64%;
  opacity: 0.7362685804;
  transform: rotate(235.3345892462deg);
  -webkit-animation: drop-147 4.3963649265s 0.8296113118s infinite;
          animation: drop-147 4.3963649265s 0.8296113118s infinite;
}

@-webkit-keyframes drop-147 {
  100% {
    top: 110%;
    left: 72%;
  }
}

@keyframes drop-147 {
  100% {
    top: 110%;
    left: 72%;
  }
}
.confetti-148 {
  width: 1px;
  height: 0.4px;
  background-color: #263672;
  top: -10%;
  left: 29%;
  opacity: 1.0391478387;
  transform: rotate(271.6027462715deg);
  -webkit-animation: drop-148 4.2123681891s 0.0517992946s infinite;
          animation: drop-148 4.2123681891s 0.0517992946s infinite;
}

@-webkit-keyframes drop-148 {
  100% {
    top: 110%;
    left: 35%;
  }
}

@keyframes drop-148 {
  100% {
    top: 110%;
    left: 35%;
  }
}
.confetti-149 {
  width: 3px;
  height: 1.2px;
  background-color: #d13447;
  top: -10%;
  left: 70%;
  opacity: 1.0147668993;
  transform: rotate(85.5504310593deg);
  -webkit-animation: drop-149 4.5248858003s 0.4514192087s infinite;
          animation: drop-149 4.5248858003s 0.4514192087s infinite;
}

@-webkit-keyframes drop-149 {
  100% {
    top: 110%;
    left: 74%;
  }
}

@keyframes drop-149 {
  100% {
    top: 110%;
    left: 74%;
  }
}
.confetti-150 {width: 4px;height: 1.6px;background-color: #ffbf00;top: -10%;left: 66%;opacity: 0.5833368764;transform: rotate(212.4139198962deg);-webkit-animation: drop-150 4.2501847457s 0.6906351334s infinite;      animation: drop-150 4.2501847457s 0.6906351334s infinite;}

@-webkit-keyframes drop-150 {
  100% {
    top: 110%;
    left: 76%;
  }
}

@keyframes drop-150 {
  100% {
    top: 110%;
    left: 76%;
  }
}
.showblctxt{ cursor: pointer; color: #9144B5; margin-bottom: 15px;}
.dsadsmalltxt{ font-size: 24px;  color: #9144B5;}
.rainairdrops{display: inline-block; font-size: 20px; padding: 0px 5px;}

.airdroptitle {max-width: 300px;margin: 0 auto 15px !important;white-space: normal !important;line-height: normal !important;overflow: visible !important;max-height: inherit;}
.walltcheckcont input[type="checkbox"] + ::before, .walltcheckcont input[type="radio"] + ::before{background: rgba(0, 0, 0, 0.56) !important; cursor: pointer; z-index: 9;color: #fff;}
.selected-custodial::after {content: ''; background: rgba(158, 102, 191, 0.39); width: 100%;position: absolute;left: 0px;top: 0px;height: 100%; transition: all ease 0.75s; border: 6px solid #e6bcff;   border-radius: 16px;}
.walltcheckcont {position: absolute;width: 100%;height: 100%;left: 0px;top: 0px;}
.walltcheckcont .walltcheck{cursor: pointer; width: 100%; height: 100%; padding: 15px;  margin-bottom: 0px; z-index: 9;display: block;}

/* .walltcheckcont input[type="checkbox"]:checked + ::before, input[type="radio"]:checked + ::before{ color: #fff; border-color: #ffffff40;} */
.da-transsticky{transition: all ease 0.75s;}
.da-transsticky.top {position: fixed; z-index: 9; top: 0px;left: 0px;text-align: center;width: 100%;background: #141414;padding: 15px;}

.dacustomdeta {margin-top: 15px;}
.dacustomdeta .btn{ margin: 0px !important;}

.infinity-loader {position: relative; left: 50%; transform: translateX(-50%); width: 130px; height: 70px; margin: 30px 0px;}
.infinity-loader .bg div,.infinity-loader > .fg > div > div {width: 70px;height: 70px; border: 10px solid #1B1B1B; box-sizing: border-box; border-radius: 50%;position: absolute; display: flex; align-items: center; justify-content: center;}
.infinity-loader .right-bg {transform: translate(100%, 0);left: -10px;}
.infinity-loader > .fg > div > div {border-color: #9144B557 #9144B557 transparent transparent;transform: rotate(135deg); animation: spin 1s linear infinite; position: static;}
.infinity-loader > .fg > div { clip: rect(0, 70px, 35px, 0); position: absolute; }
.infinity-loader > .fg > .bottom-right-rect { left: -10px; transform: translateX(100%) scale(1, -1);}
.infinity-loader > .fg > .bottom-right-rect > div {animation-delay: 0.25s; }
.infinity-loader > .fg > .top-right-rect {left: -10px; transform: translateX(100%) scale(-1, 1);}
.infinity-loader > .fg > .top-right-rect > div {animation-delay: 0.5s; }
.infinity-loader > .fg > .bottom-left-rect {transform: scale(-1);}
.infinity-loader > .fg > .bottom-left-rect > div {  animation-delay: 0.75s; }
.infinity-loader > .fg {  filter: drop-shadow(0 0 6px #9144B5);}
@keyframes spin {
  50%,100% {transform: rotate(495deg);} 
}

.infinity-loader .bg div img {width: 32px;}
.nftstashimg{ display: flex; align-items: center; justify-content: center; border-radius: 10px; padding: 15px; margin-top: 30px; width: 100%; background: #010101;}
.nftstashimg img{ width: 24px; margin: 0px 10px;}
.nftstashimg h4{ margin: 0px; font-size: 20px;}

.da-celdeimg .wrappercele{ height: 85%;}
.snotid {display: flex;flex-direction: column;padding: 10px;border-radius: 10px;border: 1px solid #242424;background: #1B1B1B;box-shadow: 0px 0px 10px rgba(0,0,0,0.2);}
.snotid:hover {background: rgba(158, 102, 191, 0.07); border-color: #9144B5;}
.maxscroll {height: 32rem;overflow: hidden;overflow-y: auto;padding: 0px 15px;}
.tokenidimg{ display: inline-flex; align-items: center;}
.tokenidimg img{ max-height: 30px; margin-right: 10px;}

.dandetails{ padding: 50px 0px;}
.dandpc{display: flex;}
.dandpic{ display: flex; width: 50%;max-width: 500px; min-width: 500px; flex-direction: column;}
.dandcont{ display: flex; flex: 1; margin-left: 50px; flex-direction: column; position: relative;width: 50%;}
.dandpicimg{ width: 100%; margin-bottom: 25px;}
.dandpicimg img{ max-width: 100%;}
.dandpiccont .purchasebox{ background: #1B1B1B; padding: 15px;}

.dandcont .nav-tabs{  border-image: linear-gradient(to right, #9144B5, #21CFF1) 1;border-bottom-width: 1px;border-bottom-style: solid; overflow: hidden; overflow-x: auto; white-space: nowrap; flex-wrap: nowrap;}

.dandcont .nav-tabs .nav-link, .tab-change li{border: none; color: #21CFF1;font-weight: 600;font-size: 20px; padding: 0px 0px 15px 0px; position: relative; background: transparent !important;}
.dandcont .nav-tabs .nav-link::after, .tab-change li::after{ content: ''; position: absolute; left: 0px; width: 0; transition: all ease 0.75s; bottom: 1px; height: 3px; background: linear-gradient(to right, #9144B5, #21CFF1) ;}
.dandcont .nav-tabs .nav-item:not(:first-child) .nav-link, .tab-change li:not(:first-child){ margin-left: 25px;}
.dandcont .nav-tabs .nav-item.show .nav-link, .dandcont .nav-tabs .nav-link.active, .react-tabs__tab--selected{color: #fff; background: transparent;}

.dandcont .nav-tabs .nav-link.active::after, .react-tabs__tab--selected::after{ width: 100% !important;}
.react-tabs__tab--selected{ margin-bottom: 0px;}
.tab-change li::after{ bottom: 0px;}

.dandconticons{ position: absolute; right: 0px; top: 0px; display: flex; align-items: center;}
.dandconticons a{ transition:all ease 0.75s;  width: 35px; height: 35px; border-radius: 10px; background:#1B1B1B; box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.15); color: #21CFF1 !important; display: flex; align-items: center; justify-content: center; cursor: pointer;}
.dandconticons a:not(:first-child){ margin-left:15px;}
.dandconticons a:hover, .dandconticons a.active {background: #21CFF1; color:#000 !important;}
.dandcont .tab-content{ margin-top: 25px;}
.dandcont .tab-content h1{ color: #fff; font-size: 50px; font-weight: 700; line-height: 60px; margin-bottom: 10px;}
.dandcont .propicdetail{ top: 0px; padding: 5px 15px; margin-bottom: 25px;}
.dandcont .propicdetail .picpro {width: 50px;height: 50px;}
.dandcont .tab-content p{ font-size: 24px;font-weight: 400;line-height: 34px; margin-bottom: 25px;}
.dandconticons .dropdown a{ margin-left: 15px;}
.dandconticons .dropdown-toggle::after, .prosoclinks .psocial::after{ display: none;}
.dropdown .dropdown-menu {left: auto !important;right: 0px;font-size: 12px;background: #1b1b1b; padding: 0px; margin: 0px; transform: none !important; top: 110% !important; border-radius: 15px; overflow: hidden; } 
.dropdown .dropdown-menu .dropdown-item{cursor: pointer; padding: 15px 20px; margin: 0px; display: flex; align-items: center; color: #21cff1; font-size: 16px; transition: all ease 0.75s; align-items: flex-start; }
.dropdown .dropdown-menu .dropdown-item i{ margin-right: 10px;}
.dropdown .dropdown-menu .dropdown-item:focus, .dropdown .dropdown-menu .dropdown-item:hover{background: #000;}
.prosoclinks .psocial + .dropdown-menu{ background: #000;}
.prosoclinks .psocial + .dropdown-menu .dropdown-item:focus, .prosoclinks .psocial + .dropdown-menu .dropdown-item:hover{background: #1a1a1a;}

.activity_box h4{ margin-bottom: 15px;}
.purchased_category_nft{ margin-bottom: 25px;}
.langbtnlinks a, .btn-outline-dark{ margin-bottom: 15px; border-radius: 10px !important;position: relative;border: 2px solid transparent !important;  background: #000;background-clip: padding-box;padding: 5px 15px; font-size: 20px; font-weight: 600; color: #21CFF1 !important;  text-transform: capitalize;}
.langbtnlinks a::after, .btn-outline-dark::after{ position: absolute;top: -2px; bottom: -2px;left: -2px; right: -2px;background: linear-gradient(to right, #9144B5, #21CFF1);content: '';z-index: -1;border-radius: 10px; }
.langbtnlinks a i, .btn-outline-dark i{font-size: 14px;}
.btn-outline-dark{ margin-bottom: 0px;}

.Utility-Badges {display: flex;width: 100%;overflow: hidden;overflow-x: auto;white-space: nowrap;}
.ubbox{ cursor: pointer; flex-direction: column; filter: grayscale(1); display: flex; align-items: center;  transition: all ease 0.75s;}
.ubbox:hover, .ubbox.active { filter: none;} 
.ubbox span{ position: absolute; left: 0px; top: 0px; align-items: center; justify-content: center; display: flex; width: 100%; height: 100%;}
.ubbox span img {max-width: 44px;}
.Utility-Badges .ubbox:not(:first-child){ margin-left: 50px;}

/* div#Badges .Utility-Badges {
  max-width: 757px;
} */
.ubbox {
  max-width: 110px;
}
.Utility-Badges .ubbox p {
  white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    max-width: 100%;
}

.propagecont.ccdetailbox .dandcont.cc-tabs {
  width: 100%;
} 

.Utility-Badges {
  /* max-width: 538px; */
  touch-action: auto;
  padding-bottom: 25px;
}

.Utility-Badges::-webkit-scrollbar {
  height: 5px;
}
.Utility-Badges::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  background-color: #fff;
  height: 1px;
}
.Utility-Badges::-webkit-scrollbar-thumb {
height: 5px;
background-color: #9144b5;
}

.Utility-Badges::-webkit-scrollbar-thumb:hover {
background-color: #716ac5;
}

.Utility-Badges::-webkit-scrollbar:vertical {
display: none;
}
.ubboximg{position: relative; width: 73px; height: 80px; position: relative; display: flex; align-items: center; justify-content: center; margin-bottom: 15px;}
.color-in, .gray-out{ position: absolute; transition: all ease 0.75s; max-width: 100%;}
.color-in{ opacity: 0;}
.ubbox.active .color-in{ opacity: 1;}
.ubbox.active .gray-out{ opacity: 0;}
.ubbox p{ text-align: center; margin-bottom: 0px !important;}
.ubboxcont h5{ font-weight: 600; margin: 25px 0px; font-size: 20px;}
.ubboxwon{ display: flex; align-items: center; margin-top: 15px;}
.ubboxwon .ubbox{ margin-right: 15px; width: 36px; height: 40px;}
.ubboxwon .ubbox .ubboximg{width: 36px; height: 40px;}
.ubboxwon h5{ margin: 0px; font-size: 24px; font-weight: 400;}
.ubboxcont p{ font-size: 14px !important; line-height: 21px !important; color: #9F9F9F; margin-bottom: 0px !important;}
.ubboxwon .ubboximg{ margin-bottom: 0px;}
.ubboxwon  .ubbox span img{ max-width: 20px;}

.activity_box h6 {font-size: 14px; display: flex; align-items: center;}

.btn-primary-gradient{border: 2px solid transparent !important; background: #000;   background-clip: border-box; background-clip: padding-box; position: relative; color: #21CFF1 !important; border-radius: 50px !important;}
.btn-primary-gradient::after, .btn-primary-gradient:hover::after { position: absolute;top: -2px;bottom: -2px;left: -2px;right: -2px;background: linear-gradient(to right, #9144B5, #21CFF1);content: '';z-index: -1;border-radius: 50px;}
.btn-primary-gradient:hover{background: #9144B5; background-clip: border-box; background-clip: padding-box; color: #000 !important;}

.cc-tabs{ margin: 50px 0px 0px 0px;} 

:root{
  --venusRisingRg : 'Venus Rising Rg'  
}
.VRRff{font-family: var(--venusRisingRg);}
.shlanding{ padding: 50px 0px;}
.shlanding h3{font-family: var(--venusRisingRg); font-size: 48px; line-height: 58px; margin-bottom: 25px; text-align: center;}
.mwconbox{ border: 3px solid #9F9F9F; padding: 36px 30px; border-radius: 25px; text-align: center;  position: relative;}
.mwconboxbg{position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; overflow: hidden; border-radius: 25px;}
.mwconboxcont{ position: relative; z-index: 1;}
.mwconboxbg img{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); min-height: 400px;  max-width: 100%;}
.mwconbox p{font-family: var(--venusRisingRg); font-size: 16px; line-height: 20px; margin: 25px 0px;}
.twologos span{font-family: var(--venusRisingRg); margin: 0px 15px;}
.mwconbox h4{font-family: var(--venusRisingRg);  font-size: 36px; line-height: 43px; margin: 25px auto 0px; color: #21CFF1; width: 530px;}
.sfld h4{ width: 100%; font-size: 46px; line-height: 58px; margin: 0px 0px 25px;}
.sfld {border: 2px solid transparent !important; background: #000;     background-clip: padding-box; min-height: 350px; display: flex; flex-direction: column; align-items: center; justify-content: center;}
.sfld::after {position: absolute;top: -2px;bottom: -2px;left: -2px;right: -2px; background: linear-gradient(to right, #9144B5, #21CFF1);content: '';z-index: -1;border-radius: 25px;}
.msh-content{ padding: 25px 0px;}
.msh-content p{ font-size: 24px; line-height: 34px;}

.msh-upcoming h3{ text-align: left;}
.msh-upcoming .purchasebox{ padding-top:25px; padding-bottom: 25px;}
.msh-upcoming h4{ font-size: 50px; line-height: 60px; margin-bottom: 15px; font-weight: bold; text-transform: capitalize; transition: all ease 0.75s;}
.msh-upcoming a:hover h4{color: #21cff1;}
.msh-upcoming h5{ font-size: 32px; line-height: 42px; margin-bottom: 15px; color: #9144B5;}
.msh-upcoming p{font-size: 24px; line-height: 34px; }
.msh-upcoming img{ border-radius: 15px;}

.msh-upcoming .dandcont {
    width: 100%;
}
.newlandart{ min-height: 350px; border: none; margin-bottom: 15px;}
.newlandart img {max-width: 100%;width: 100%;}
.newlandart .mwconboxbg { background: #000;}

.newlandartcont .usernameblue {margin-bottom: 5px;display: block;}

.nclOD{ padding-top: 50px;}
.nclOD .cardlist .cont{ padding: 0px;}
.nclOD .articonstatic{ margin-bottom: 25px;}
.nclOD .cardlist .cont h4 {  font-size: 24px; line-height: 30px; margin-bottom: 5px;}
.nclOD .cardlist .cont p{ color: #fff;}
.nclOD .cardlist .cont p span{ margin-left: 10px;}

.nclcountdown{ margin-bottom: 25px;}
.nclcountdown ul{ display: flex; justify-content: space-between;}
.nclcountdown ul li:not(:first-child){ margin-left:25px;}
.nclcountdown ul li{ display: flex; flex-direction: column; align-items: center; justify-content: center;font-size: 24px;font-weight: 400;line-height: 30px; text-transform: uppercase;}
.nclcountdown ul li .nclcdno{font-family: var(--venusRisingRg); font-size: 48px;font-weight: 400;line-height: 58px; color: #9144B5;}

.css-mf175v-control, .css-b16iv1-control{border: 2px solid transparent !important; background: #000 !important; background-clip: padding-box !important;  position: relative;  border-radius: 50px !important; font-weight: 500 !important;}
.css-mf175v-control::after, .css-b16iv1-control::after {position: absolute;top: -2px;bottom: -2px;left: -2px;right: -2px;background: linear-gradient(to right, #9144B5, #21CFF1);content: '';z-index: -1;
border-radius: 50px;}
.dandpiccont .list_price h5, .dandpiccont .daplogo { margin-bottom: 10px;}

.nbaab{ display: flex; flex-direction: column; min-height: 200px; align-items: center; justify-content: center;}
.nadedbtns{ margin-bottom: 25px;}
.nadedbtns .btn{ width: 40px;}
.nadedbtns .btn:not(:first-child){ margin-left: 15px;}

.ubboxwon1 {margin-bottom: 25px;}

.profofown {position: absolute;right: 15px;top: 0px;}

.adshowless{ margin-top: 0px;}
.adshowless .showless .content .showlesscontent::after{background: linear-gradient(to bottom, rgba(241,241,241,0) 0%,#000 100%);}
.adshowless .showless .content .showlesscontent, .adshowless .showless #showless2:checked ~ .content #showless1{max-height: 200px;}
.ddpnbox{ padding:15px; background: #000; display: flex;}
.nav-item.propic .ddpnbox h6 {font-weight: 700;}
.dbsline {display: inline-flex;align-items: center;}

.navbar-brand + .desknone {margin-left: auto;}
.mobnavnew {display: flex !important;flex-direction: row;align-items: center;justify-content: center; margin-right: 30px;}
.navbar-nav.mobnavnew a.nav-link { margin-bottom: 0px !important; font-size: 20px; text-transform: capitalize;}
.navbar-brand .logoaw2{ display: none;}

.adscanpop .minw-35rem{ min-width: 500px; max-width: 500px; padding: 50px;}
.adscanpop h4 {width: 301px;margin: 25px auto ; font-weight: normal;}
.adscanpop a{  margin-top: 10px; display: inline-block;}
.adscanpop button{ margin-top: 25px;}
.adscanpop .radar{width: 35vmin;height: 35vmin; margin: 0px auto;}

.bg-141414{ background: #141414;}

.creatorrequ .custom-ui.claimpop, .creatorrequ .custom-ui.successpopup{ padding: 50px !important;}
.successpopup{ width: 500px; text-align: center !important;}
.claimpop input{ border-color: #9144B5; background: #000;}
.reportpop h2 {font-weight: normal;font-size: 32px;margin: 25px 0px;}
.reportpop h2 + img{ margin-bottom: 25px;}

.brdgradeint {background: linear-gradient(to right, #9144B5, #21CFF1); width: 300px; height: 300px; margin: 0px auto;border-radius: 50%;padding: 2px;display: flex;align-items: center;justify-content: center; transition: all ease 0.75s;}

.successpopup .artimgpop {height: 250px;margin-bottom: 25px;width: 100%;}
.successpopup .artimgpop img {max-height: 100%; max-width: 100%;}

.airdrop_title{ font-family: var(--venusRisingRg);}

.radarimg{ width: 350px; position: relative; display: block; margin: 0px auto;}
.radarimg .btn{ position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); white-space: nowrap;}
.dsairartconte .btn {padding: 0px 15px !important;line-height: normal !important;}
.claimedchek{ width: 115px; height: 115px; margin: 25px auto;}
.creatorrequ .custom-ui.successpopup.sspppopup{ width: 350px; padding: 50px 20px 20px !important;}
.sspppopup h3{ font-size: 18px;}
.w-250{ width: 250px; height: 250px; margin: 25px auto;}
.text-transform-none{text-transform: none !important;}

.ReactModal__Body--open {overflow: hidden;}
.ReactModal__Overlay{ overflow-y: scroll;}


@media screen and (max-height: 767px) {
  .brdgradeint{width: 200px; height: 200px;}
  .brdgradeint + .w-100.my-5 {margin: 2rem 0px !important;}
  .creatorrequ .custom-ui.claimpop, .creatorrequ .custom-ui.successpopup{padding: 50px 30px 30px !important;}
}

/*new changes*/
@media(min-width:768px){
  .cookie-banner {padding-bottom: 9px;padding-top: 9px;}
}

@media(min-width:1600px){
  .container{max-width:1340px;}
  .dv2 .container{max-width:1140px;}
   /* .auto-suggest #globalSearchInput { width: 350px;} */
 
}
@media(max-width:1680px){
  .rainbow{ font-size: 22px;}
}
@media(max-width:1600px){
  .rainbow{ font-size: 20px; width: 260px;}
  .Utility-Badges .ubbox:not(:first-child) {margin-left: 25px;}
}
@media(max-width:1536px){
.bidown::after{left: 48%;}
.btncws .purchasebtn {width: 100%;}
.counttimebox .counttime{ width:auto; margin-right:10px; font-size: 22px;} 
.counttimebox h2 .counttime:nth-child(4){ margin-right:0px} 
/* .auto-suggest #globalSearchInput {width: 300px;} */
.dropseclist .badgeview h2.usernameClick{ max-width: 85px;}
.nav-item.propic h6{width: 100px;}
.disnowmenu .navbar-nav .nav-link{ padding: 10px 15px !important;}
.cobmain{margin: 30px 0px;}
}
@media(max-width:1440px){
  /* .auto-suggest #globalSearchInput {width: 250px;} */
  /* .globalsearchbox{ margin-left: 15px !important;} */
  .socialtopicons{ margin-left: 0px !important;}
  .site-footer .col-md-6 {flex: 0 0 40%;  max-width: 40%;}
  .site-footer .col-md-2 { flex: 0 0 20%;  max-width: 20%;}
  .rainbow{ font-size: 17px;}
  /* .auto-suggest{margin-left: 275px;} */
}

@media(max-width:1366px){
  .fundiframe{ min-height:calc(100vh - 50px);}
  /* .navbar-brand img {height: 38px;} */
  /* .auto-suggest #globalSearchInput {width: 200px;} */
  .rainbow{ font-size: 15px;}
  /* .auto-suggest #globalSearchInput {width: 230px;} */
  .featuredsec.featuredcreator .faimg, .featuredsec.featureart .faimg {height: 350px !important; position: relative;}
  /* .auto-suggest{margin-left: 200px;} */
  /* div#Badges .Utility-Badges {max-width: 580px;} */
  .creatorrequ .custom-ui.claimpop, .creatorrequ .custom-ui.successpopup { padding: 40px 30px !important; overflow: scroll;}
  .brdgradeint{width: 200px; height: 200px;}
  .successpopup .artimgpop{ height: 175px;}
  .custom-ui.successpopup .btn, .custom-ui.claimpop .btn {margin-top: 30px !important;}
}
  
@media(max-width:1280px){
  /* .auto-suggest #globalSearchInput { width: 185px;} */
  .rainbow{width: 200px;}
  /* .auto-suggest{margin-left: 150px;} */
  .auto-suggest #globalSearchInput{ width: 400px;}
  .Utility-Badges .ubbox:not(:first-child) {margin-left: 25px;}
  .ubbox p { font-size: 14px !important; }
}

@media(max-width:1199px){
 .art .col-md-4{width: 50%;}
 .container, .container-lg, .container-md, .container-sm{max-width: 100%;}
 .bigart .col-md-5 {padding-left: 30px;} 
 /* .auto-suggest #globalSearchInput { width: 150px;} */
 .featuredsec .alice-carousel__prev-btn{left: 0;}
 .featuredsec .alice-carousel__next-btn{ right: 0;}
 .disnowmenu .navbar-nav .nav-link {padding: 10px !important;}
 /* .navbar-brand img { height: 30px;} */
 .spotlight .falist-con h3, .featureart .falist-con h3 {right: -45.5%;}
 .watdcon3box .dscpbbtn .btn-primary-fill {font-size: 16px;letter-spacing: 0px;}
 .auto-suggest{margin-left: 0px;}
 }

@media(max-width:1024px){  
  .alert-custom { height: auto;}  
  .marketplacetxt {font-size: 14px;}
  .disnowmenu .navbar-nav .nav-link{font-size: 12px; padding: 10px 15px !important; color: #fff !important;}
    /* .cardlist.dropseclist .cont .upic, .cardlist.artimglist .cont .upic{ margin-bottom: 15px !important;} */
  .bigart .col-md-5 {padding-left: 15px;}
  .bigarttit{font-size: 50px;}
  .bidown h3 {font-size: 30px;}
  .bidown{ margin-bottom: 30px !important;}
  .bidown h6 {font-size: 16px;}  
  .mediumart h3.usernamelilac { font-size: 25px;}
  .smallarts .cardlist { padding: 10px;}
    .auto-suggest #globalSearchInput {width: 335px;}  
  .lftsdbar, .ccdetailbox .propicdetail{ width: 175px;}
  .ccdetailbox .propicdetail .picpro{ width: 155px; height: 155px;}
  .activitybox .col-md-6 { flex: 0 0 100%;  max-width: 100%;  text-align: left !important; margin-bottom: 0px !important;}
  .activitybox .col-md-6 br {display: none;}
  .activitybox .col-md-6 i{ margin-bottom: 5px !important;}
  .activitybox .row.mb-3 {margin-bottom: 30px !important;}
  .fund-wallet-container .fwcon .onflowicon{width: 50px;}
  .toggleicon, .nav-item.propic .dropdown .upic{width: 46px; height: 46px;}
  .toggleicon .bars { width: 25px; height: 2px;}
  .disnowmenu .socialtopicons.navbar-nav .nav-link{ padding: 10px 5px !important;}
  .dropseclist .cont .col-sm-7, .dropseclist .cont .col-sm-5, .cardlist.artimglist .cont .col-sm-7, .cardlist.artimglist .cont .col-sm-5 {max-width: 100%; flex: 0 0 100%; text-align: left !important;}
.cardlist.dropseclist .cont .col-sm-7 .upic, .cardlist.artimglist .cont .col-sm-7 .upic {margin-bottom: 0px !important;}
.artgalemp {height: 30px;}
/* .cardlist.dropseclist .cont .col-sm-5 .update, .cardlist.artimglist .cont .col-sm-5 .update{ padding-left: 46px;} */
.cardlist.artimglist .cont .col-sm-7 .userwithpic {width: 100%;}
.socialtopicons {flex-wrap: wrap; width: 65px; justify-content: center;}
.rainbow {padding: 0; width: 115px; font-size: 12px;}
.topcolldeta .propicdetail{ width: auto;}
.topcolldeta .propicdetail .picpro{ width: 100px; height: 100px;}
.cob-venue h6 {font-size: 35px;}
.cob-venue h3 {font-size: 55px;}
.cobmain h2{ font-size: 28px;}
.nyc-logos img {max-width: 200px !important;}
.nyc-address{ font-size: 25px;}
.droplisttit{ flex-direction: column;}
.droplisttit h1, .droplisttit .probtns{ margin-bottom: 15px !important;}
.iconmenu .card span.nav-link {font-size: 11px !important;}
.react-tabs__tab-panel .btn{font-size: 12px;}
.droplisttit .activebtnscus:last-child .col-md-7, .droplisttit .activebtnscus:last-child .col-md-5 {max-width: 100%; flex: 0 0 100%; text-align: center;}
.droplisttit .activebtnscus .col-md-5 ul.text-right, .activebtnscus ul.text-right{ text-align: center !important;} 
.droplisttit .activebtnscus .col-md-5{ margin-top: 15px;}
.reglogfrm {width: 100%;}
.spotlight .falist-con h3, .featureart .falist-con h3 {right: -44.5%;}
.featureart .falist-con h3.splfatxt, .spotlight .falist-con h3.splfatxt {right: -46.5%;}
.featuredcreator .falistdesc h3 span { max-width: 300px;}
.featuredsec.featuredcreator .faimg, .featuredsec.featureart .faimg {height: 350px !important;}
.navbar-brand img {height: 35px;}
.dandpic{ width: 450px; min-width: 450px;}
.Utility-Badges {width: 495px;}
}



@media(max-width:991px){  
.container, .container-md, .container-sm{ width: 100%; max-width: 100%;}
.authbg h1{padding: 30px 0px;}
.navbar{ padding-left: 0px; padding-right: 0px;}
.navbar .container-fluid { padding: 0px;}
.navbar-brand img {height: 40px;}
.navbar-light .navbar-toggler{ color: #fff; border-color: #fff; right: 15px; position: relative; padding: 0;}
.navbar-light .navbar-brand{ margin: 10px 15px; top: 0px; position: relative;}
.disnowmenu{padding: 0px}
/* .disnowmenu .collapse:not(.show) { display: flex; flex-direction: column; margin-top: -50px; margin-right: 10px;} */
.tatcon.navbar-collapse { display: block !important; position: fixed;top: 0px;left: -290px; overflow-y: auto; height: 100vh !important;background:#000; width: 250px;z-index: 1; transition: all ease 0.75s; box-shadow: 0px 0px 10px rgba(0,0,0,0.5); }
.tatcon.navbar-collapse.show{ left:0px;}
.menulink {background: #000; display: flex; flex-direction: column; height: auto; align-items: center; width: 100%; margin: 0px !important; border-radius: 0px;}
.menulink li{flex: 1; text-align: center; width: 100%; position: relative; z-index: 2;}
.menulink li a.nav-link{ margin: 0px !important;  width: 100%;}
.disnowmenu .navbar-nav .nav-link {font-size: 14px;padding: 10px 15px !important;color: #fff !important; height: auto !important; text-transform: uppercase; margin-bottom: 10px !important;}
.iconmenu{ display: flex; flex-direction: row; width: 100%; order: 1; margin: 0px; align-content: center; justify-content: flex-end;}
.iconmenu li {flex: 1; text-align: center; }
.iconmenu li.nav-item.propic{ max-width: 60px; margin-left: 0px;}
.toggleicon, .nav-item.propic .dropdown .upic{ margin-left: 0px;}
.iconmenu li:first-child{order: 1;  margin-left: 0px; margin-right: 0px; max-width: 175px;}
/* .iconmenu li:nth-child(2){order: 2;} */
.iconmenu li:last-child{order: 2;}
.disnowmenu .navbar-nav .nav-link i { font-size: 35px;}
.propic img {width: 48px;}
.artupload .col-md-6 {max-width: 100%;flex: 0 0 100%;  margin: 0px;}
.dropseclist .artcovimglink {float: none; display: block; width: 100%;  height: auto; margin: 0px 0px 15px 0px;}
.sidebar{ left: -200px; transition: all ease-in-out 0.75s; top: 70px;}
.content-shift, .content-not-shift{ margin-left: 0px;}
.mobtoggle{ display: inline-block; margin-top: 13px; margin-right: 15px;}
.sidebar .mobview{ display: block;}
.mobhide{display: none;}
.sidebar.showsidebar {left: 0px;}
.mobmenuhide{ display: none !important;}
.show-content .purchasebox{margin: 0px; width: 100%;}
.show-content-D {margin: 0px;width: 100% ;}
.nav-item.propic h6 {width: auto; font-size: 12px; margin-top: 15px !important;}
.dropseclist .flex-row{flex-direction: column !important;}
.dropdown-content{ position: fixed; top: 0px !important; min-width: 210px; left: -230px; right: auto; height: 100%; transition: all linear 0.5s; z-index: 9999; display: block; box-shadow: 0px 0px 10px rgba(0,0,0,0.9);}
.dropdown-content.open{ left: 0px;}
.nav-item.propic .dropdown-content a.navbar-brand{ display: block; margin:0; padding: 5px 15px; top: 0;}
.nav-item.propic .dropdown-content .navbar-brand img {width: auto;border-radius: 0px; border: none; max-width: 100%;}
.toggleicon .bars, .toggleicon.close .bars{ transition: all linear 0.5s; position: relative;}
.toggleicon.close .bars:first-child {transform: rotate(-45deg); top: 10px;}
.toggleicon.close .bars:nth-child(2) {width: 0px;}
.toggleicon.close .bars:last-child {transform: rotate(45deg); bottom: 10px;}
/* .flowboxdes{ display: none;} */
.flowboxmob {display: block; padding:0px;}
.dropdown-content.open .flowboxmob h6{ display: none !important;}
.mobilesearch {position: absolute; right: 125px; top: 5px; display: inline-flex;  z-index: 2; justify-content: center; align-items: center;}
.auto-suggest{ margin: 0px;}
.auto-suggest #globalSearchInput {width: 100%;  height: 45px;}
.mobilesearch .auto-suggest, .mobilesearch .form-inline .auto-suggest .form-control, .mobilesearch form, .mobilesearch .form-group {width: 100%;}
.nav-item.propic h6.text-center.mb-1{margin-top: 25px;}
.disnowmenu.navbar-light .navbar-nav .nav-link::before{bottom: 1px;}
.form-inline .auto-suggest .form-control {width: 180px;}
.disnowmenu .navbar-nav .nav-link.btn-primary{ border-radius: 0px !important;}
.container.discovernav .navbar-nav {display: flex; flex-direction: row;  justify-content: center;  align-content: center;  margin-top: 55px;}
.container.discovernav .navbar-nav li a { padding: 5px 15px !important;}
.discovernav .disnowmenu .navbar-nav .nav-link { padding: 10px 15px !important;}
.detailcontpa .col-md-6, .detailcontpa .col-md-6 + .col-sm-6 {flex: 0 0 100%; max-width: 100%; margin-bottom: 15px;}
.detailcontpa .dettittxt h2, .detailcontpa .dettittxt h4, .detailcontpa .dettittxt h5{ text-align: center;}
.detailcontpa .col-md-6 .row{ justify-content: center;}
.nav-link img{top: -3px; float: none;}
.disnowmenu .navbar-nav .nav-link.btn-primary{height: 40px !important; width: 90%; margin: 0px auto !important;}
.nav-item.propic .dropdown-content .socialtopicons{ display: inline-flex; flex-direction: row; width: 100%;}  
.dropdown-content .navbar-brand img {height: 80px;}
.dropseclist .badgeview h2.usernameClick {max-width: inherit;}
#dropdownMenuLink + .dropdown-menu{margin: 0px; transition: all ease 0.75s; margin: 0px; position: fixed;}
#dropdownMenuLink[aria-expanded="false"] + .dropdown-menu {left: -230px; }
#dropdownMenuLink[aria-expanded="true"] + .dropdown-menu {left: 0px;top:0px}
.alert-custom {top: 0px;}
.profilesec .alert-custom{ margin-bottom: 15px;}
.profilesec { margin-top: 0px;}
.airdroplist { min-height: calc(100vh - 600px);}
.airdroplist .col-sm-6.col-md-4.d-flex{ flex: 0 0 50%; max-width: 50%;}
.fund-wallet-container .fwcon{min-height: 320px;}
.rainbow {width: 200px;padding: 0 15px; font-size: 16px;}
.dmwbtns .col-md-6{flex: 0 0 100%; max-width: 100%; margin-bottom: 15px;}
.featuredcreator .falistdesc h3 span {max-width: 230px;}
.takeatour{right: 2%; top: 123px; display: none;}
.watdcon3box .row {flex-wrap: nowrap;overflow: hidden;overflow-x: auto;}
.watdcon3box .row .col-md-4{ flex: 0 0 45%; max-width: 45%;} 
.accustombox {display: flex;flex-direction: row;flex-wrap: nowrap;overflow: hidden;overflow-x: auto;}
.accustombox .falist {max-width: 45%; flex: 0 0 45%; display: inline-flex; flex-direction: column;}
.mobilesearch{ position: static; padding: 15px; flex-direction: column;  width: 100%;}
.mobsidepro {  background: #0b0b0b !important;  padding: 15px !important;  margin:0 0 5px;  border-radius: 10px;}
.mobsidepro .upic {width: 65px !important;display: block;height: 65px !important;margin: 0 auto !important;}
.mobsidepro h6 {margin: 0px !important;}
.mobsidepro, .dropdown-content .drops, .desknone{ display: block;}
.nav-item.propic .dropdown-content {overflow-y: auto;}
.navbarbrandmob{ height: 100px; display: block; width: 100%; padding: 15px;}
.navbarbrandmob img{ max-width: 100%; max-height: 100%;}
.nav-item.desknone .flowboxmob h6 {display: block !important; margin-top: 10px !important;}
.navbar-toggler .toggleicon {border: none;}
.navbar-toggler .toggleicon .bars{ width: 30px;}
.navbar-light .navbar-toggler[aria-expanded="true"] .toggleicon .bars:nth-child(2){ width: 0px;}
.navbar-light .navbar-toggler[aria-expanded="true"] .toggleicon .bars:nth-child(1){ transform: rotate(45deg); top: 9px;}
.navbar-light .navbar-toggler[aria-expanded="true"] .toggleicon .bars:nth-child(3){ transform: rotate(-45deg); top: -7px;}
.flowboxmob .card.flowtop .nav-link {text-transform: none;}
.dsairartcol, .dsairartconte{flex-direction: column;}
.dsairartcol .artcovimglink{width: 100%;}
.airdroptitle {max-width: 100%;}
.dsairartcol .cont{ margin-bottom: 30px; margin-left: 0px;}
.dandpc{ flex-direction: column;}
.dandpic {width: 100%;min-width: 100%;}
/* .dandpicimg img {width: 100%;} */
.dandcont{ margin-left: 0px;  margin-top: 25px;}
.dandconticons{ position: static; margin-top: 25px;}
.Utility-Badges{ width: 100%;}
.ccdetailbox .cctitpro {flex-direction: column;align-items: center !important;}
.ccdetailbox .badgeview.propicdetail {margin-top: -135px;}
.ccdetailbox .biodeta {margin-top: 25px !important; padding-left: 0px;}
.dandcont {width: 100%;}
}
@media(max-width:910px){ 
  .spotlight .faimg{ height: 380px;}
  .featuredsec.featuredcreator .faimg, .featuredsec.featureart .faimg {height: 375px !important;}
}

@media (max-width: 850px) {
  .dlilabg .form-group input[type="checkbox"] + * {
    display: block;
    line-height: 20px;
    padding-left: 25px;
  }
  .dlilabg .form-group label::before {
    position: absolute;
    left: 0;
    top: 3px;
  }
}

@media(max-width:830px){
  .walltcon .col-md-8, .walletlist .col-md-4 { flex: 0 0 100%;  max-width: 100%; text-align: left !important;}
  .walltcon .col-md-4 .btn{ width: auto !important; padding: 0px 30px;}
  .walletlist .col-md-4.offset-md-4{ text-align: center !important; flex: 0 0 33.333333%; max-width: 33.333333%;}
    .alert-custom{ height: 55px;}  
  .content-not-shift .purchasebox .usernamelilac {font-size: 18px;}
  .bigart .col-md-7, .bigart .col-md-5{ max-width: 100%; flex: 0 0 100%;}
  .bigart .col-md-5{ padding: 15px;}
  .mediumart h3, .smallarts h3 {font-size: 25px;}
  .mediumart h3.usernamelilac { font-size: 20px; margin-bottom: 5px;}
  .mediumcon h6 {font-size: 14px; margin-bottom: 5px;}
  .mediumcon .badgeview .usernameClick, .ownedby .badgeview .usernameClick, .smallarts .badgeview .usernameClick, .smallarts h4{font-size: 16px;}
  .site-footer .col-md-6 {flex: 0 0 100%;  max-width: 100%;}
  .site-footer .col-md-2 { flex: 0 0 33.3%;  max-width: 33.3%;}
  .btncws .purchasebtn{ font-size: 12px;}
  .ccdetailbox .col-md-6 { flex: 0 0 100%;  max-width: 100%;}
  .biodeta { margin-top: 25px !important;}
  .biodeta h3{ text-align: left;}
    .biodeta .joindedate{ text-align: left;}
  .ccdetailbox .probtns.activebtnscus ul{ text-align: center !important; margin-top: 30px;}
  .fund-wallet-container .fwcon .onflowicon { width: 35px;  margin: 0px 5px;}
  .cob-venue h6 {font-size: 25px;}
  .cob-venue h3 {font-size: 40px;}
  .cobmain .col-md-8.offset-md-2{flex: 0 0 100%;  max-width: 100%; margin-left: 0;}  
  .logsign h1 {font-size: 22px;}
  .spotlight .faimg{height: 350px;}
  .featureart .falist-con h3, .spotlight .falist-con h3{top: 45%; font-size: 18px;}  
  .featureart .falist-con h3 {right: -43.5%;}  
  .featuredcreator .falistdesc h3{font-size: 20px;}
  .featuredsec.featuredcreator .faimg, .featuredsec.featureart .faimg {height: 350px !important;}
  .site-footer h6{ margin-top: 30px;}
  .copyright-text {text-align: center;}
  .copyright{ margin-top: 30px;}
  .bigartdetails {padding-left: 0;}
  .bigart .col-md-6 {flex: 0 0 100%; max-width: 100%;}
  .dsv34item .falist{ width: 50%;}
}

@media(max-width:768px){
  .featureart .falist-con h3 {right: -46%;}
  .spotlight .faimg {height: 350px;}
  .featuredsec.featureart .faimg{height: 350px !important;}
  .featureart .faimg .articonstatic{ height: 100% !important;}
}

@media(max-width:767px){
    .art .col-md-4{width: 100%;}
    .coverimg{height: 200px;}
    /* .disnowmenu .navbar-nav .nav-link{padding: 5px 0.5rem !important;}   */
    .artfrmtop, .artfrmtop .col-md-6 {margin-top: 0;  text-align: center !important;} 
    .detailimg{ margin-bottom: 30px;}
    .progallists .card-body {padding-left: 15px; padding-right: 15px;}
    .coverimg img{ height: 200px;}    
    .cookie-banner{ flex-direction: column;}
    .cookie-content{ margin-bottom: 16px;}
    .cookie-btn{width: 100%;}
    .cookie-btn .gotit{ margin: 0px;}
    .show-content{ padding: 60px;}
    .show-content-D{ padding: 60px;}  
    .detailcontpa .row {justify-content: center;}  
    .detailcontpa .purchasebox .row{justify-content: flex-start;}
    .detailcontpa .purchasebox { text-align: center;}
    .detailcontpa .purchasebox .btn-primary{ margin-bottom: 30px;}
    .counttimebox .counttime{ float: none; display: inline-block; width: 60px;}
    .dettittxt + .row {text-align: center; justify-content: center;}
    .custom-ui .form-check-label{ display: -webkit-box ;}
    .dettittxt h2, .dettittxt h4{ justify-content: center; text-align: center;}
    .smallilac a{ padding-top: 10px;  padding-bottom: 10px;  color: #fff;}
    .marketplacetxt {line-height: normal; font-size: 12px;}
    .alert-custom{ height: 48px;}
    .mediumart .col-md-6.text-right {text-align: left !important;padding-top: 15px;}
    .smallarts .row, .mediumart .row{flex-direction: row;  overflow: hidden;   overflow-x: hidden; overflow-x: auto; flex-wrap: inherit;}
    .mediumart .mediumcon .row {flex-wrap: wrap; flex-direction: column;}
    .mediumart .artcovimg { margin: 10px;}
    .mediumcon { padding: 0px 10px 10px;}
    .ownedby{ padding: 10px;}
    .bigart .badgeview .usernameClick{ font-size: 16px;}
    .propagecont.ccdetailbox .col-md-8, .propagecont.ccdetailbox .col-md-4 .activebtnscus ul { text-align: center !important;}
    .followboxcon{ margin-bottom: 10px;}
    .choosefile{ margin-bottom: 15px;}
    .mandatory-req{ margin-bottom: 30px;}
    .dmwsec .card-body1 .col-md-6 .row{justify-content: center;}
    .detailcontpa .purchasebox .col-sm-6, .detailcontpa .col-md-6{    max-width: 100%;    flex: 0 0 100%;   margin-bottom: 10px;}
    .timerline{ margin-bottom: 30px !important;}
    .timerline::after{display: none;}
        .detailcontpa .input-group.dollorsec {width: 250px; margin: 0px auto; }
    #copies + .mandatory-req { margin-bottom: 0px;}
    .activitybox h4{text-align: left;}
    .withdrawnedition .form-control {display: inline-block;}
    .fund-wallet-container .fwcon{ min-height: auto;}
    .fund-wallet-container .cbbg{ min-height: 250px;}
    .cusbtns{ min-height: 125px;}
    .disnowmenu.navbar-light .navbar-nav .nav-link::before {bottom: -4px;}
    .nftupfrm .mandatory-req{ margin-bottom: 0px;}
    .artupload .mandatory-req{margin-bottom: 0px;}
    .spotlight .falist{ margin-bottom: 30px;}
    .danlogo{ height: 200px;}
    .makenfts .ds-button {margin-bottom: 100px;}
    .watdcon {padding: 30px 0px 0px 0px;}
    .makenfts .watdcon {padding: 0;}
    .dollorsec .form-control.mw-95, .detailcontpa .input-group.dollorsec + .mw-95{ max-width: 185px;}
    .detailcontpa .input-group.dollorsec + .mw-95{ margin: 0px auto 30px; position: relative; left: -35px;}      
    .custable{ text-align: left;}
    .walletlist .col-md-4.offset-md-4 {flex: 0 0 100%;  max-width: 100%;  }
    .cob-venue h6 {text-align: center;}
    .cob-venue .col-md-3:nth-child(3){ border: none; border-top: 1px solid #fff;  border-bottom: 1px solid #fff; padding-top: 15px; padding-bottom: 15px; margin: 15px 0px;}
    .cobmain h2 {font-size: 30px;}
    .nyc-city{margin-top: -75px;}
    .mobilesearch{right: 115px; top: 8px;}
    .form-inline .auto-suggest .form-control{ width: 150px;}
    .rainbow{ width: 100%; margin-bottom: 15px;  font-size: 25px;}
    .mobilesearch .form-inline{ width: 100%;}
    .mobilesearch .form-group{ margin: 0px !important; width: 100%;}
    .mobilesearch .auto-suggest, .mobilesearch .form-inline .auto-suggest .form-control{ width: 100%;}
    .auto-suggest #globalSearchInput { width: 100%; }
    .prpabout .item--about-image{float: none;   display: block;   margin: 15px auto;}
    .prpabout .item--about-text { text-align: justify; line-height: 25px; display: block;}
    .item--social-links{justify-content: center;}
    /* .dlilabg { display: none;} */
    .reglogfrm{ width: 400px;}
    .spotlight .falist-con h3, .featureart .falist-con h3 {right: -46%;}
    .dscpb{ align-items: center; margin-top: 10px;}
    .falistdesc h4{ text-align: center;}
    .featureart .falist-con h3.splfatxt, .spotlight .falist-con h3.splfatxt { right: -47.8%;}
    .fixedrigt{top: 30% !important;}
    .logsign .row {flex-direction: column-reverse;}
    .watdcon3box .row .col-md-4{ flex: 0 0 65%; max-width: 65%;} 
    .dark-purple .dscpb .dscpbtxt { width: 100%; text-align: center; }
    .featureart .falistdesc h4 {           white-space: nowrap;      text-overflow: ellipsis;      overflow: hidden;    }
    .walletlist .col-md-3 { width: 50%; }
    .dacustomdeta{ flex-direction: column;}
    .tab-change li h5{ white-space: nowrap;}
    ul.tab-change{ overflow-x: auto;}
    .tab-change li h5 .btn{ margin-left: 10px;}

    .dandetails .for_sale_price h5, .dandetails .for_sale_price h3, .dandetails .edition_box_editions h5, .dandetails .edition_box_editions h3{display: inline-block; margin-right: 15px;}
    .dandetails .edition_creators{margin: 25px 0px;}
    .dandetails .purchasebtn{width: 100%;}
    .dandetails .list_price + .purchase_nft { text-align: right !important;}
    .Utility-Badges .ubbox:not(:first-child) { margin-left: 25px; }
    .ubboxwon .ubboxtxt h5{ font-size: 18px; margin: 0px;}
    .dandcont .tab-content p {font-size: 18px; line-height: 26px;} 
    .ubboxcont h5{margin: 15px 0px;}   
    .dandcont .tab-content h1{font-size: 32px; line-height: 42px;}

    .shlanding .col-md-8{ margin-bottom: 0%;}
    .mwconboxbg img{ width: 100%;}
    .shlanding h3{ font-size: 32px;}
    .mwconbox h4{ font-size: 24px; width: 100%;}
    .sfld{ min-height: auto;}
    .sfld h4{ font-size: 30px !important;  line-height: normal !important;}
    .msh-upcoming img{ margin-bottom: 15px;}
    .mwconboxcont img{ max-width: 100%; height: auto;} 
    .nclcountdown ul li{ font-size: 16px;}
    .nclcountdown ul li:not(:first-child) {margin-left: 0; }
    .nclcountdown ul li .nclcdno{ font-size: 35px;}
    .newlandartcont{ margin-bottom: 25px;}
    .newlandartcont .col-md-4.text-right{ text-align: left !important;}
    .msh-upcoming h4 {font-size: 35px; word-break: break-word;}
    .msh-upcoming h5{ font-size: 24px;}
    .dandpiccont .purchasebox .purchase_nft  + .list_price{  margin-bottom: 15px;}
    .prosoclinks .psocial + .dropdown-menu{right: auto; left: 75% !important; transform: translateX(-71%) !important;  word-break: break-all !important; word-wrap: normal !important;}
    .showless .content .showlesscontent{ font-size: 14px;}
    .showless #showless1:checked ~ .content #showless1{ text-align: left;}
    .cardlist .cont h3,  .morefromarts h4{ font-size: 18px;}
    .biodeta .prosoclinks, .followboxcon{justify-content: flex-start; margin-bottom: 10px;}
    
    }

@media(max-width:680px){
 
  .adminimgprev{ width: 100%}
  .purchasebox.activitybox .col-6 { max-width: 100%;flex: 0 0 100%; text-align: left !important; }
  .topcolldetabox h5 span {margin-right: 15px;margin-bottom: 15px;  }
  .adtable{border: none;}
  .adtable .overtxt{ display: inline-block;    width: 115px;    position: relative;    margin-right: 15px;    font-weight: bold;}
  .adtable .overtxt::after{content: ':'; position: absolute; right:0; height: 100%; width: 1px; top: 0px; font-weight: bold;}
  .adtable tr th {display: none;}
  .adtable td {border: none; background: transparent; padding: 5px  15px; line-height: 25px; display: flex;  flex-direction: row; align-items: center;}
  .adtable tr{ border: 1px solid #2d2d2d;}
  .adtable tr:nth-child(even){ background: #1c1c1c;}
  .adtable td .flowtop {display: flex !important; flex-direction: row; align-items: center;}
  .cobmain h1 {font-size: 75px;}
  .cobmain h1 span {font-size: 55px;}
  .pagetitabtn h1{font-size: 25px;}
  .featureart .falist-con h3{top: auto;    font-size: 18px;    bottom: 0px;    transform: none;    left: 0px;    right: auto;  }
  .minw-35rem{ min-width: auto; max-width: 35rem;}
  /*new changes*/
  .minw-28rem {max-width: 28rem;}
  /*new changes*/
  .purchasebox.activitybox .abtit .col-6{ max-width: 50%;    flex: 0 0 50%; margin-bottom: 15px;}
  .purchasebox.activitybox .abtit .col-6:last-child{ text-align: right !important;}
}

@media(max-width:575px){
  .navbar-brand .logoaw1 {display: none;}
  .navbar-brand .logoaw2 {display: inline-block;}
  .copyright .col-sm-8 {order: 2; text-align: center; margin-top: 15px;}
  .copyright .col-sm-4 { text-align: center !important;}  
  .pnfimg {width: 75%;}
  .pagetitabtn h1 { display: block;  width: 100% !important;  text-align: center; margin: 0px;}
  .pagetitabtn   .droplisttit h1{ text-align: left;}
  .pagetitabtn .probtns{ width: auto; text-align: center;}
   .pagetitabtn  .droplisttit .probtns{ width: auto; text-align: left};
  .pagetitabtn .probtns .btn{ padding: 5px 10px; font-size: 12px;}  
  .propagecont .row .col-sm-6{ justify-content: center; display: flex;}
  .propagecont .purchasebox .col-sm-6{ margin-bottom: 15px; display: block;}
  .followboxcon{ width: 100%; max-width: 100%; margin-bottom: 10px;}
  /* .propagecont .row .col-md-12 .text-justify{ text-align: center !important;} */
  .likebtn{ margin-left: 5px; margin-right: 0px;}
  .sharedrop {     width: 50px;    overflow: hidden;    padding: 0px;    height: 50px;    display: inline-flex;    justify-content: center;    align-items: center;}
  .sharedrop i{ margin-right: 30px; margin-left: 68px; }
  .sdmorelinks{ overflow: visible; z-index: 1;}
  .sdmorelinks i{ margin: 0px;}
  .sdmorelinks .dropdown-item i{ margin-right: 10px;}
  .badgeview.sharedrop + .badgeview.hideshowart {margin: 0px 5px 0px 0px !important;}
  .show-content { padding: 30px;}
  .show-content-D { padding: 30px;}
  .purchasebtn{ width: 100%;}
  .propicdetail {  margin-top: 0px; margin-bottom: 0px; top: -30px;}
  .propicdetail .picpro { width: 32px; height: 32px;  float: left;  margin: 0px;}
  .creatorrequ .custom-ui { padding: 45px 10px 0px 10px !important}
  .propagecont .row .col-md-12 .text-justify.prodescontent{ text-align: justify !important; }
  .timerline{ padding-bottom: 30px; margin-bottom: 30px !important;}
  .timerline::after{ width: 100%; height: 1px; bottom: 0px; top: auto; left: 0px; right: auto;}  
  .ReactModal__Overlay{ padding: 10px;}
  .admintitle, .admintitle .text-right{ text-align: center !important;}
  .admintitle .text-right{ margin-top: 15px;}  
  .badgeview .usernameClick{ font-size: 16px;}  
  .useredit{ flex-wrap: wrap;}
  .useredit h2 {width: 100%; margin: 0px 0px 15px 0px !important;  justify-content: flex-start;}
  .bigart {margin-bottom: 30px;}
  .bigarttit{ margin-top: 15px;}
   .site-footer .col-md-2 { flex: 0 0 100%;  max-width: 100%;}
  .detailcontpa .dropseclist .cardlist .col-sm-4, .detailcontpa .dropseclist .cardlist .col-sm-5{ text-align: center !important; margin: 7.5px 0px;}
  .lftsdbar, .ccdetailbox .propicdetail { width: 135px;}
  .ccdetailbox .propicdetail .picpro { width: 115px;    height: 115px;}
  .cctitpro {flex-direction: column; width: 100%;}
  .lftsdbar, .weblinks a {width: 100%;}
  .ccdetailbox .propicdetail { margin: 0px auto; top: -50px;}
  .prosoclinks{margin: 15px 0px 0px;}  
  .ccdetailbox .badgeview.propicdetail {margin-top:-75px; margin-bottom: 15px; top: 0;  }
  .ccdetailbox .badgeview.propicdetail + .connect_wallet { display: inline-block;  margin: 0px auto;}
  .artpage .probtns { padding: 15px 0px 0px !important;}
  /* .biodetadesk{display: none !important;} */
/* .biodetamob{ display: inline-flex !important;} */
.activitysec .activitybox .col-md-6{max-width: 100%; flex: 0 0 100%;}
.activitysec .activitybox .col-md-6.text-right{text-align: left !important; margin-top: 10px;}
.brdnft{ border:none; padding: 0px;}
.mediumart .articonstatic {max-height: 350px; min-height: 350px;}
.dark-purple h2 {font-size: 2rem;}
.makenfts h3 {font-size: 35px;}
.ds-heading { font-size: 45px;}
.btn-outline-light .form-control{ height: 28px;}
.cardlist.dropseclist .cont .col-sm-6, .cardlist.artimglist .cont .col-sm-6{max-width: 100%;  flex: 0 0 100%;}
  .cardlist.dropseclist .cont .col-sm-6.text-right, .cardlist.artimglist .cont .col-sm-6.text-right{ max-width: 100%;  flex: 0 0 100%; text-align: left !important;}
  .topcolldeta .propicdetail{ margin: 0px auto 15px; width: auto; display: flex; justify-content: center;} 
  .topcolldeta{flex-direction: column;  justify-content: center;  align-items: center;}
  .topcolldetabox h5 {flex-direction: column; justify-content: center; align-items: center; text-align: center;}
  .topcolldetabox h5 span{ margin-right: 0px; margin-top: 15px;}
  .tcdeta {text-align: center; }
  .airdroplist .col-sm-6.col-md-4.d-flex {    flex: 0 0 100%;    max-width: 100%;  }
  .cobmain h1 { font-size: 65px; }
  .cobmain h1 span {font-size: 47px;}
  .cobmain h4 {flex-direction: column;}
  .cobmain h4 a{ margin-bottom: 15px;}
  .nyc-logos img {margin: 15px 30px;}
  .rainbow {font-size: 20px;}
  .iminbtn { padding-left: 0px;}
  .ds-con{max-width: 100%; text-align: center;}
  .ds-inner-con{width: 100%;justify-content: center;}
  .d-slider .carousel-item { max-height: 500px; min-height: 500px;}
  .gototop{right: 15px; bottom: 15px;}
  .featureart .falist-con h3.splfatxt, .spotlight .falist-con h3.splfatxt {right: -47%;}
  .watdcon3box .row .col-md-4{ flex: 0 0 85%; max-width: 85%;} 
  .dark-purple h3 { font-size: 22px;}
  .accustombox .falist {max-width: 65%; flex: 0 0 65%;}  
  .site-footer h6, .footer-links{ text-align: center;}
  .footer-links li { display: inline-block;line-height: 30px; margin: 0px 15px; text-align: left;}
  .footer-links.builtwallet {display: flex;  flex-direction: row;  flex-wrap: nowrap;  overflow: hidden; overflow-x: auto;}
  .footer-links.builtwallet li {  white-space: nowrap;  margin: 0px 0px 15px;  flex: 0 0 185px;  max-width: 185px;}
  .mobrevdiv{ flex-direction: row-reverse;}
  /*new changes*/
.minw-28rem {max-width: 25rem;}
.creatorrequ .custom-ui {padding: 20px 10px 0px 10px !important;}
.radar { width: 80vmin;height: 80vmin;}
  /*new changes*/
  /* .tab-change li h5{ font-size: 14px;}
  .tab-change li{padding: 5px 10px;} */
  .shlanding h3 {font-size: 25px; line-height: 38px; margin-bottom: 15px;}
  .mwconbox p{ font-size: 14px;}
  .mwconbox h4 {font-size: 20px;line-height: 28px;}
  .nclcountdown{ margin-bottom: 15px;}
  .shlanding{ padding: 25px 0px;}
  .dandcont .topcolldeta { margin-top: 0;}
  .profofown {position: static;}
  .adscanpop .minw-35rem{min-width: 20rem !important; max-width: 20rem !important; padding: 50px 15px !important;}
  .adscanpop h4 {width: 100%;}  
  .adscanpop .radar {width: 220px; height: 220px;}
  .creatorrequ .custom-ui.claimpop {padding: 50px 25px 25px !important;}
  .brdgradeint{width: 200px; height: 200px;}
  .creatorrequ .custom-ui.successpopup, .creatorrequ .custom-ui.successpopup.sspppopup{ width: 300px; padding: 50px 15px 25px !important;}
}

@media(max-width:530px){
  .coverimg, .coverimg img{ height: 150px;}
  .picpro{width: 100px;  height: 100px; margin-top: -50px;}
  /* .navbar-light .navbar-brand{top: 0px; margin: 5px auto;} */
  .navbar-brand img{height: 45px;}
  .iconmenu li:first-child{     position: relative;  left: 50%;  margin-left: -87.5px;}
  .disnowmenu .collapse:not(.show){ margin-top: 0px;}
  .menulink, .iconmenu{ height: auto; padding: 0px;}
  .iconmenu{justify-content: space-between; position: relative;}
  .iconmenu li.nav-item.propic{    position: absolute;  right: 5px;  top: -45px; justify-content: flex-end;}
  .nav-item.propic .dropdown .upic, .toggleicon{ width: 42px; height: 42px;}
  .toggleicon .bars{ width: 26px; height: 2px;}
  .sizecls span{ display: block;}
  .sizecls {margin-top: -10px; margin-bottom: 0px;}
  .purchasebtn{ width: 100%;}
  .purchasebox .col-md-6{ margin-bottom: 15px;}
  .reglogfrm{ width: 100%;}
  .fund-wallet-container{width: 100%;}
  .reglogfrm .form-check-label {padding-left: 25px; position: relative; display: inherit;}
  .reglogfrm .btn-primary-fill.btn-icon{ font-size: 14px;}
  .reglogfrm a{ font-size: 12px;}
  .form-check-label::before{position: absolute; left: 0px}
  .creatorrequ .custom-ui input[type="checkbox"] + *{ padding: 0px 25px; font-size: 14px;}
  .becomeacreator label {font-size: 14px;}
  .alert-custom {height: 48px; line-height: normal; display: flex; justify-content: center; align-items: center;}
  .flowtop h6 { display: block;}
    .nav-item h6.text-center.mb-1 {display: block;}
  .dropdown-content.open .flowboxmob h6{ display: block !important; width: 100%; margin-bottom: 10px !important; color: #9144B5; text-transform: none;}
  .bigarttit {font-size: 40px;}
  .bidown h3 {font-size: 25px;}
  .mediumart h2, .smallarts h2 {font-size: 30px;}
  .mediumart h2 a, .smallarts h2 a {font-size: 16px;}  
  .container.discovernav .navbar-nav{ margin-top: 0px;}
  .pagetitabtn{ padding-top: 0px;}
  .disnowmenu.navbar-light .navbar-nav .nav-link::before { bottom: -1px;}
  .nav-item.propic h6.text-center.mb-1 { margin-top: 0;}
  .iconmenu .socialtopicons li{left: 0; margin-left: 0;  order: inherit;  max-width: inherit;}
  .threeimgs .bimg, .threeimgs .timg{height: 190px;}
  input[type="radio"] + *{padding-left: 25px;}
  /* .disnowmenu .navbar-nav .nav-link.btn-primary {padding: 6px 5px !important; margin-left: 0px !important; height: auto !important;}   */
  .nyc-city{ margin-top: -30px;}
  .nyc-present h2 span{display: block;}
  .nyc-address {font-size: 20px;}
  .badgeview.sharedrop + .badgeview.hideshowart {margin: 15px 5px 0px 0px !important;}
  .mtpd{ align-items: flex-start; flex-direction: row; margin-top: -15px; justify-content: flex-start; margin-bottom: 30px;}
  .mtpdetail{ padding: 0px 0px 0px 15px; flex: 1;}
  .mtplaybtn{width: 50px; height: 50px; font-size: 20px;}
  .detasingimg{ padding-top: 15px;}
  .mtpdetail h5{ font-size: 20px; align-items: flex-start;}
  .mtpdetail .ownerDec{ margin-bottom: 0px; padding-left: 24px;}
  .mtpdetail .spancursor {font-size: 12px; margin-right: 10px !important;  margin-top: 5px;}
  .dsv34item .falist {width: 100%;}
  .form-check-label{padding-left: 25px;}

}

@media(max-width:475px){
  .smallarts .cardlist {padding: 5px; text-align: center;}
  .smallarts .cardlist h4{ margin: 10px 0px !important;}
  .smallarts .cardlist .badgeview{ margin-bottom: 10px;}
  .threeimgs .bimg, .threeimgs .timg {height: 165px;}
  .danlogo {height: 100px;}
  /* .disnowmenu .navbar-nav .nav-link {padding: 5px !important;font-size: 12px;} */
  /* .disnowmenu .navbar-nav .nav-link i.fa-fire {font-size: 11px;} */
  .cobmain h1 {font-size: 40px;}
  .cobmain h1 span {font-size: 30px;}
  .cobmain .btn-primary{font-size: 25px;}
  .cob-venue{ padding-left: 0; padding-right: 0;}
  .nyc-present h4{font-size: 20px;}
  .nyc-present h2 {font-size: 25px;}
  .nyc-present .cob-venue {padding-left: 15px; padding-right: 15px;}
  .rainbow {font-size: 15px;}
  .circart{ width: 100%;}
  .iminbtn { padding-left: 0px; text-align: center;}
  .featureart .falist-con h3.splfatxt, .spotlight .falist-con h3.splfatxt {right: -46.5%;}
  .walletlist .col-md-3 { width: 100% !important; }
  /* .morefromarts .row {    flex-direction: row;    overflow: hidden;     overflow-x: hidden;    overflow-x: hidden;    overflow-x: auto;    flex-wrap: inherit;}
  .morefromarts .row .col-6 {    max-width: 100%;    flex: 0 0 100%;} */
  .morefromarts .articonstatic{max-height: 150px; min-height: 150px;}
  .msh-upcoming h4{font-size: 25px; line-height: normal;}
}

@media(max-width:414px){
.w350 {width: 100%;}
.authdnlogo img {width: auto;  max-width: 75%;}
.card.py-5 {padding-top: 1.5rem !important; padding-bottom: 1.5rem !important;}
/* .disnowmenu .navbar-nav .nav-link{font-size: 10px;} */
.coverimg, .coverimg img {height: 125px;}
.media-date{ position: static; margin-bottom: 5px;}
.dollorsec input.form-control {width: 100px !important;}
.sharebox{ width: 90%;}
/* .tab-change li{ padding: 5px 10px; background: #1c1c1c; border-radius: 10px 10px 0 0; margin: 0px 1px; flex: 0 auto;  min-width: 185px !important;}
.tab-change li h5 {font-size: 12px;} */
.alert-custom {height: 60px;}
.bigarttit {font-size: 35px;}
.bidown h3 {font-size: 22px;}
.bidown h6 {font-size: 14px;}
.bidown::after {left: 42%;}
.mediumart h2, .smallarts h2 {font-size: 25px;}
/* .psocial {  width: 42px;  height: 42px;  margin: 0px 5px 10px 5px;  top: 0px;  float: none;} */
/* .propagecont .row .col-6 { max-width: 100%; flex: 0 0 100%; text-align: center;} */
.bigartimg .artcovimg {height: 250px;}
.dicoverarts { padding-top: 30px;}
.threeimgs .bimg, .threeimgs .timg {height: 150px;}
.ds-heading {font-size: 30px;}
.featureart .falist-con h3, .spotlight .falist-con h3{ font-size: 15px;}
.accustombox .falist {max-width: 85%; flex: 0 0 85%;}
.logacordbtn{ margin-bottom: 25px;}
.logacordbtn h2 {font-size: 26px;}
.biodeta .protittxt .btn{ font-size: 12px;}
.navbar-nav.mobnavnew a.nav-link {font-size: 16px;padding: 15px 10px !important;}
.radarimg{ width: 280px;}
}
@media(max-width:380px){  
  .navbar-nav.iconmenu{height: auto; margin-bottom: 0px !important; padding: 0px;}
  .nav-item .btn.designLink { margin-bottom: 15px;}
  .iconmenu .card.flowtop{ margin-bottom: 5px;}
  .nav-item .dropdown-content .btn.designLink{ margin-bottom: 0px;}
  .menulink{ padding-left: 0px; padding-right: 0px;}
  /* .disnowmenu .navbar-nav .nav-link { padding: 5px 0.1rem !important;} */
  .smallarts .col-6{ max-width: 85%; flex: 0 0 85%;}
  .bidown .col-6 { flex: 0 0 100%;  max-width: 100%;}
  .bidown .col-6:first-child{ margin-bottom: 15px;}
  .bidown::after{display: none;}
  /* .disnowmenu .navbar-nav .nav-link.btn-primary{ padding: 5px 0px !important;}    */
  .threeimgs .bimg, .threeimgs .timg { height: 125px;}    
  .nyc-present h1 {font-size: 35px;}
  .nyc-present h1 span {font-size: 25px;}  
  .spotlight .faimg {height: 300px;}
  .hwdabox{min-height:350px;}
  .hwdaboxcon{height: 325px}  
  .iconmenu li.nav-item.propic{right: 0px; top: -48px;}
}
@media(max-width:375px){
  .counttimebox .counttime{width: 57px;} 
  .pagination li a { width: 20px;  height: 20px;  font-size: 10px;}
  .threeimgs .bimg, .threeimgs .timg {height: 115px;}
}

@media(max-width:320px){
  .navbar-light .navbar-brand {margin: 5px 0px 0 15px;}
  .navbar-brand img {height: 40px;}
  .dollorsec input.form-control {width: 75px !important;}
  .detailWallet .col-md-9 .btn { margin: 0px auto 15px auto; display: inline-block;}  
  .adtable td .flowtop{flex-direction: column; align-items: flex-start;}
  .adtable .overtxt::after{ display: none;}
  .adtable .overtxt{ width: 100%;}
  .adtable td{flex-direction: column;align-items: flex-start;}
  .airmailtxt{ width: 200px;}
  .spotlight .faimg {height: 275px;}
}
